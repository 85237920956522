// https://gist.github.com/akaramires/7577298

/* Аргументы:
 * name
 * название cookie
 * value
 * значение cookie (строка)
 * props
 * Объект с дополнительными свойствами для установки cookie:
 * expires
 * Время истечения cookie. Интерпретируется по-разному, в зависимости от типа:
 * Если число - количество секунд до истечения.
 * Если объект типа Date - точная дата истечения.
 * Если expires в прошлом, то cookie будет удалено.
 * Если expires отсутствует или равно 0, то cookie будет установлено как сессионное и исчезнет при закрытии браузера.
 * path
 * Путь для cookie.
 * domain
 * Домен для cookie.
 * secure
 * Пересылать cookie только по защищенному соединению.
 * */

export class Cookie {

    // возвращает cookie если есть или undefined
    get (name) {
        const matches = document.cookie.match(new RegExp(
          '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
        ))
        return matches ? decodeURIComponent(matches[1]) : undefined
    }

    bool (name) {
        const value = this.get(name)
        const pattern = new RegExp('true|false|1|0', 'i')
        return pattern.test(value ?? '') ? (value === 'true' || value === '1') : Boolean(value)
    }

    // уcтанавливает cookie
    set (name, value, props) {

        props = props || {}

        let exp = props.expires

        if (typeof exp === 'number' && exp) {
            const d = new Date()

            d.setTime(d.getTime() + exp * 1000)

            exp = props.expires = d

        }

        if (exp && exp.toUTCString) {
            props.expires = exp.toUTCString()
        }

        value = encodeURIComponent(value)

        let updatedCookie = name + '=' + value

        for (const propName in props) {
            updatedCookie += '; ' + propName

            const propValue = props[propName]

            if (propValue !== true) {
                updatedCookie += '=' + propValue
            }
        }

        document.cookie = updatedCookie
    }

    // удаляет cookie
    delete (name) {
        this.set(name, null, { expires: -1 })
    }

}
