<template>
    <modal
        class="modal"
        name="page_in_develop"
        height="auto"
        width="35%"
    >
        <div class="modal__content">
            <svg
                class="modal__icon"
                width="80"
                height="80"
                aria-hidden="true"
                focusable="false"
            >
                <use href="@/assets/images/sprites.svg#trash-modal"></use>
            </svg>
            <h2 class="modal__title title">
                Раздел в разработке
            </h2>
            <p class="modal__description">
                Мы пока работаем над наполнением раздела. Скоро он будет доступен
            </p>
            <button
                class="modal__button button button_size_long"
                type="button"
                @click="$modal.hide('page_in_develop')"
            >
                Закрыть
            </button>
        </div>
    </modal>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/components/modal-remove"
</style>