import Vue from 'vue'
import { hyphenateSync } from 'hyphen/ru'
import utils from '@/utils'

const filters = {

    cutHtml (string) {
        return string?.toString().replace(/<[^>]*>?/gm, '')
    },

    cutString (string, limit) {
        const words = string?.toString().trim() || ''
        return (limit && words.length > limit) ? words.substr(0, limit) + '...' : words
    },

    removeMultiSpaces (string) {
        return string?.toString().replace(/ +(?= )/g, '')
    },

    truncate (string, limit = 20) {
        const words = string?.toString().trim().split(' ') || []
        return words.length - 1 > limit ? words.slice(0, limit).join(' ') + '...' : string
    },

    hyphen (text) {
        return text.split(' ').map(word => hyphenateSync(word)).join(' ')
    },

    deep (obj, path, def) {
        return utils.findDeep(obj, path, def === undefined ? '' : def)
    },

    joinObjects (obj, func = r => r.name) {
        return utils.joinObjects(obj, func)
    },

    capitalize (text) {
        return text ? text.charAt(0).toUpperCase() + text.slice(1) : text
    },

    prettyDate (string, showTime) {
        if (!string) return ''
        const date = new Date(string)
        const monthNum = date.getMonth() + 1
        const month = monthNum < 10 ? `0${monthNum}` : monthNum
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
        const output = `${day}.${month}.${date.getFullYear()}`

        if (showTime) {
            const hour = date.getHours()
            const min = date.getMinutes()
            return `${output} ${hour < 10 ? `0${hour}` : hour}:${min < 10 ? `0${min}` : min}`
        }

        return output
    },

    formatDate (datetime, format) {
        return utils.formatDate(datetime, format)
    },

    firstWords (text = '', count = 1) {
        if (!text) return ''

        const words = text.split(' ')

        return words.length > count
            ? words.slice(0, count).join(' ') + '...'
            : text
    },
}

Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))

export default filters
