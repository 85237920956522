import Axios from 'axios'
import Https from 'https'
import { RequestClient } from '@argab/request-service'

const COMMON_HEADERS = {
    'Accept-Language': 'es-ES,es;q=0.8',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization',
    'Zone-Offset': -(new Date().getTimezoneOffset()),
}

const CLIENT = {
    timeout: 60000,
    withCredentials: true,
    headers: {
        ...COMMON_HEADERS,
        'Content-Type': 'application/json',
    },
}

const _axios = (config) => Axios.create(config)

Axios.defaults.baseURL = '/api'
Axios.defaults.httpsAgent = new Https.Agent({
    rejectUnauthorized: false,
})

export default class extends RequestClient {

    config = {}

    constructor (data) {
        super()

        this.params = data.params
        const headers = { ...CLIENT.headers, ...data.headers }
        const config = { ...CLIENT, ...{ headers } }

        data.timeout && (config.timeout = data.timeout)

        if (headers['Content-Type'] === 'application/octet-stream') {
            config.timeout = data.timeout || 80000
        }

        Object.assign(data.headers, config.headers)
        Object.assign(this.config, config)
    }

    get ({ uri }) {
        return _axios(this.config).get(uri)
    }

    post ({ uri }) {
        return _axios(this.config).post(uri, this.params)
    }

    patch ({ uri }) {
        return _axios(this.config).patch(uri, this.params)
    }

    put ({ uri }) {
        return _axios(this.config).put(uri, this.params)
    }

    delete ({ uri }) {
        return _axios(this.config).delete(uri, this.params)
    }

    head ({ uri }) {
        return _axios(this.config).head(uri, this.params)
    }

}
