<template>
    <div
        class="ui-text-field"
        :class="error ? 'validation-error' : validation && (showErrorsTouched
            ? touched && validationError && 'validation-error'
            : (validationError && 'validation-error'))"
    >
        <div class="title-wrapper">
            <div v-show="(value || list) && title" class="ui-text-field__title">{{ title }}</div>
        </div>
        <div
            v-show="isActiveHighLightPanel"
            class="ui-text-field__textarea validate-input highlight-wrapper"
            :class="{
                'filled-textarea': value,
                'disabled-textarea': disabled,
                'input-unactive': disabled
            }"
            :style="{ minHeight, maxHeight }"
            v-html="value"
            @click="hideHighLightPanel"
        >
        </div>

        <template v-if="list && list.length">
            <div
                class="ui-text-field__textarea validate-input"
                :class="{
                    'filled-textarea': !!list.length,
                    'disabled-textarea': disabled,
                    'input-unactive': disabled
                }"
            >
                <div class="ui-text-field-list">
                    <div
                        v-for="(item, i) in list"
                        class="ui-text-field-list-item"
                        :key="i"
                        :class="{deleted: item.deleted}"
                    >
                        <span>{{item[listLabel]}}</span>
                        <span @click.stop="$emit('remove', item, i)" class="--rem">&times;</span>
                    </div>
                </div>
            </div>
        </template>

        <textarea-autosize
            v-else
            ref="input"
            class="ui-text-field__textarea validate-input"
            :class="{
                'filled-textarea': value,
                'disabled-textarea': disabled,
                'input-unactive': disabled
            }"
            :value="cleanValue"
            :max-height="maxHeight"
            :min-height="minHeight"
            :placeholder="placeholder"
            :disabled="disabled"
            :rows="rows"
            @input="onInput"
            @scroll.native="$emit('scroll', $event)"
        />

        <div ref="controls" class="ui-text-field-controls">
            <div
                v-for="(c, k) in getControls()"
                :key="k"
                class="--control-item"
            >
                <component
                    :is="c.tag"
                    v-on="getControlHandlers(c.on)"
                    v-bind="c.bind"
                />
            </div>
        </div>
        <div v-if="error" class="validation-text">{{ error }}</div>
        <div v-else-if="required && !value" class="validation-text">Обязательно для заполнения</div>
        <div v-if="number && !isNumber" class="validation-text">Значение должно быть числом</div>
    </div>
</template>

<script>
import Inputmask from 'inputmask'

const controlProps = {
    showOnValueNotEmpty: ['controlRemove', 'controlEdit', 'controlAdd'],
}

const DELAY_ON_BLUR = 300

export default {
    props: {
        index: Number,
        title: String,
        placeholder: String,
        value: [String, Number],
        list: Array,
        listLabel: {
            type: String,
            default: 'name',
        },
        dropdown: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        validation: {
            type: Boolean,
            default: false,
        },
        focused: {
            type: Boolean,
            default: false,
        },
        highlight: {
            type: Boolean,
            default: false,
        },
        wrapOnEnter: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: true,
        },
        number: {
            type: Boolean,
            default: false,
        },
        controls: {
            type: [Array, Function],
            default: () => [{
                tag: null,
                on: {},
                bind: {},
            }],
        },
        showControls: {
            type: Object,
            default: () => ({
                remove: true,
                select: false,
                edit: false,
                add: false,
            }),
        },
        rows: {
            type: Number,
            default: 1,
        },
        maxHeight: {
            type: Number,
            default: 200,
        },
        minHeight: Number,
        mask: [String, Object],
        showErrorsTouched: {
            type: Boolean,
            default: false,
        },
        error: String,
        emptyAsNull: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            inFocus: false,
            touched: undefined,
            initial: this.value,
            shiftMode: false,
            activeHighLightPanel: true,
        }
    },
    watch: {
        value (val) {
            val || this.$emit('empty')
            this.$emit('changed',
                val,
                !this.initial && !val
                    ? false
                    : val?.toString() !== this.initial?.toString()
            )
        },
        validationError (hasError) {
            this.$emit('hasError', Boolean(hasError))
        },
    },
    computed: {
        inputEl () {
            if (!this.$refs.input) return null

            return this.$refs.input.$el
        },
        cleanValue () {
            return this.$filters.cutHtml(this.value)
        },
        stubControls () {
            return {
                controlEdit: {
                    tag: 'img',
                    on: {
                        click: () => {
                            this.onInput(this.value)
                        },
                    },
                    bind: {
                        src: require('@/assets/images/edit-unactive24.svg'),
                        class: '--edit-icon',
                    },
                },
                controlSelect: {
                    tag: 'img',
                    bind: {
                        src: require('@/assets/images/arrow.svg'),
                        class: '--select-icon',
                    },
                },
                controlAdd: {
                    tag: 'img',
                    on: {
                        click: () => {
                            this.$emit('add', '')
                        },
                    },
                    bind: {
                        src: require('@/assets/images/plus-black.svg'),
                        class: '--add-icon',
                    },
                },
                controlRemove: {
                    tag: 'img',
                    on: {
                        click: () => {
                            this.onInput('')
                            this.$emit('del')
                        },
                    },
                    bind: {
                        src: require('@/assets/images/close.svg'),
                        class: '--remove-icon',
                    },
                },
            }
        },
        isEmpty () {
            return !this.value
        },
        isNumber () {
            return parseInt(this.value).toString() === this.value?.toString()
        },
        isActiveHighLightPanel () {
            return this.highlight && this.value && this.activeHighLightPanel
        },
        validationError () {
            return (this.required && this.isEmpty) || (this.number && !this.isNumber)
        },
    },
    mounted () {
        this.$nextTick(() => {
            if (this.inputEl) {
                this.inputEl.onkeydown = (e) => {
                    switch (e.keyCode) {
                        case 13:
                            if (!this.wrapOnEnter && !this.shiftMode) e.preventDefault()
                            this.shiftMode || this.onEnter(e)
                            break
                        case 16:
                            this.shiftMode = true
                            break
                        case 27:
                            e.preventDefault()
                            this.$emit('escape', e, this.index)
                            break
                    }
                }
                this.inputEl.onkeyup = (e) => {
                    switch (e.keyCode) {
                        case 16:
                            this.shiftMode = false
                            break
                    }
                }
                this.inputEl.onclick = (e) => this.$emit('click-input', e, this.index)
                this.inputEl.addEventListener('focusout', this.onBlur)
                this.inputEl.addEventListener('focus', this.onFocus)

                if (this.mask) {
                    Inputmask(this.mask).mask(this.inputEl, { optionalmarker: { start: '[', end: ']' } })
                }
            }
            if (this.focused) {
                this.setFocus()
            }
        })
    },
    methods: {
        onInput (val) {
            this.$emit('input', val.trim() ? val : (this.emptyAsNull ? null : ''))
        },
        onBlur (e) {
            this.activeHighLightPanel = true
            this.inFocus = false
            setTimeout(() => {
                this.$emit('blur', e, this.index, this.initial)
            }, DELAY_ON_BLUR)

            if (this.showErrorsTouched) {
                this.touched = true
            }
        },
        onEnter (e) {
            this.$emit('enter', e, this.index, this.initial)
        },
        onFocus (e) {
            this.$emit('focus', e, this.index)
            this.inFocus = true
            this.hideHighLightPanel()
        },
        setFocus () {
            this.inputEl.focus()
            this.$emit('focus', this)
        },
        setBlur () {
            this.inputEl.blur()
        },
        getControls () {
            const embed = []
            let output = []
            let fetch = typeof this.controls === 'function' ? this.controls(this.index) : this.controls
            fetch instanceof Array || (fetch = [])
            fetch.forEach(c => !(c instanceof Object) || !c.tag || output.push({
                tag: c.tag,
                on: c.on || {},
                bind: c.bind || {},
            }))
            Object.keys(this.stubControls).forEach(name => {
                const control = this.fetchControl(name, fetch)
                control && embed.push(control)
            })
            output = [...output, ...embed]
            output.length > 1 && this.$nextTick(() => {
                this.inputEl?.style.setProperty('padding-right', this.$refs.controls.offsetWidth + 5 + 'px', 'important')
            })
            return output
        },
        fetchControl (name, controls) {

            const showControls = this.showControls
            let control = null

            this.dropdown && Object.assign(showControls, {
                select: true,
                remove: false,
            })

            control = controls.find(c => name in c)

            if (control) {
                if (control[name] === false)
                    return null
                if (control[name] === true)
                    return this.stubControls[name]
                control = { ...this.stubControls[name], ...control[name] }
                control.bind.src || (control.bind.src = this.stubControls[name].bind.src)
                control.bind.class || (control.bind.class = this.stubControls[name].bind.class)
            } else {
                if (!showControls[name.toLowerCase().replace(/^control/, '')])
                    return null
                if (this.isEmpty && controlProps.showOnValueNotEmpty.includes(name))
                    return null
                control = this.stubControls[name]
            }
            return control
        },
        getControlHandlers (handlers) {
            const h = { ...handlers }, hc = { ...handlers }
            Object.keys(hc).forEach(k => hc[k] = (e) => h[k](e, this.index))
            return hc
        },
        hideHighLightPanel () {
            this.activeHighLightPanel = false
            this.setFocus()
        },
    },
}
</script>

<style lang="sass" scoped>
.ui-text-field
    position: relative

    .title-wrapper
        position: relative

    &__title
        padding-bottom: 6px
        color: var(--gray-85-color)
        font-weight: 600
        font-size: 13px
        line-height: 18px
        margin: 0
        position: absolute
        top: -25px
        left: 16px

    &__textarea
        border-radius: 4px
        padding: 10px 2px 10px 16px
        min-height: 41px
        font-family: inherit
        line-height: 20px
        box-shadow: 0 1px 4px rgba(65, 103, 160, 0.32)
        box-sizing: border-box
        width: 100%
        border-style: none

        &::placeholder
            opacity: 1
            color: var(--gray-85-color)

    &__input
        border-radius: 4px
        padding: 10px 2px 10px 16px
        line-height: 20px
        box-shadow: 0 1px 4px rgba(65, 103, 160, 0.32)
        box-sizing: border-box
        width: 100%
        border-style: none

        &::placeholder
            opacity: 1
            color: var(--gray-85-color)

    &.deleted
        textarea
            color: var(--red-color) !important

    &-list
        display: flex
        flex-wrap: wrap
        gap: 10px
        row-gap: 5px

        &-item
            display: flex
            align-items: center
            background: var(--blue-color)
            color: var(--white-color)
            border-radius: 5px
            padding: 2px 7px 1px 10px
            font-size: 14px

            .--rem
                padding-left: 10px
                font-weight: bolder
                cursor: pointer
                z-index: 30

.placeholder
    padding: 10px 2px 10px 16px
    width: 100%
    box-shadow: 0px 1px 4px rgba(65, 103, 160, 0.32)
    box-sizing: border-box
    line-height: 20px
    border-radius: 4px
    border-style: none
    color: var(--gray-85-color)
    overflow: hidden
    cursor: text

.filled-textarea
    background: var(--gray-96-color) !important
    color: var(--dark-gray-color) !important
    box-shadow: none !important
    padding-right: 35px !important
    font-family: inherit
    font-size: inherit

.disabled-textarea
    background: white
    box-shadow: none
    border: solid 1px #F2F2F2

.input-unactive
    box-shadow: none
    border: 1px solid #F2F2F2

    &::placeholder
        color: rgba(60, 60, 67, 0.18)

.ui-text-field-controls
    position: absolute
    right: 10px
    top: 0
    display: flex
    align-items: flex-start
    justify-content: center
    height: 90%
    z-index: 1

    .--control-item
        padding-right: 5px

        > *
            cursor: pointer
            margin-top: 4px

        .--remove-icon, .--select-icon, .--edit-icon, .--add-icon
            margin-top: 15px

.highlight-wrapper
    position: absolute
</style>
