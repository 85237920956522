import { set } from 'vuex-intern'

const initialState = () => ({
    deputy: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setDeputy: set('deputy'),
    pushToDeputy (state, data) {
        state.deputy.page = data.page
        data.list.map(e => state.deputy.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }, queryData) {
        return this.$repo('admin/deputy').get(0, queryData).then((r) => {
            commit('setDeputy', r.data)
        })
    },
    fetchAndPush ({ commit, getters }, queryData) {
        return this.$repo('admin/deputy').get(getters.page + 1, queryData).then((r) => {
            commit('pushToDeputy', r.data)
        })
    },
    search ({ commit }, queryData) {
        const data = { s: '' }
        queryData instanceof Object ? Object.assign(data, queryData) : data.s = queryData

        commit('admin/setSearchStringGlobal', data.s, { root: true })

        return new Promise(resolve => {
            this.$repo('admin/deputy').search(0, data).then((r) => {
                commit('setDeputy', r.data)
                resolve(false)
            })
        })
    },
    searchAndPush ({ commit, getters }, queryData) {
        const data = { s: '' }
        queryData instanceof Object ? Object.assign(data, queryData) : data.s = queryData

        commit('admin/setSearchStringGlobal', data.s, { root: true })

        this.$repo('admin/deputy').search(getters.page + 1, data).then((r) => {
            commit('pushToDeputy', r.data)
        })
    },
    save (_, data) {
        return this.$rs.retryAction(false).repo('admin/deputy').save(data)
    },
    delete (_, id) {
        return this.$repo('admin/deputy').delete(id)
    },
}

const getters = {
    page: state => state.deputy.page,
}

export const STORE_NAME = 'deputy'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
