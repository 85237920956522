<template>
    <div
        class="ui-tabs-switcher"
        :class="unstyled && '--unstyled'"
        :style="{gap: gap || false}"
    >
        <template v-for="tab in tabs">
            <button
                type="button"
                class="ui-tabs-switcher__button"
                :class="{
                    '--unstyled': unstyled,
                    'ui-tabs-switcher__button_state_active': value === tab[itemValue]
                }"
                :key="tab[itemValue]"
                :data-notification-count="tab.count ? tab.count : false"
                :style="{ fontSize, '--color': color, '--active-color': activeColor }"
                @click="tab[itemValue] === value || $emit('switch', tab); $emit('input', tab[itemValue])"
            >
                {{ tab[itemText] }}
            </button>
        </template>
    </div>
</template>

<script>
export default {
    name: 'UiTabsSwitcher',
    props: {
        value: [Number, String],
        tabs: Array,
        unstyled: Boolean,
        fontSize: {
            type: String,
            default: '13px',
        },
        activeColor: {
            type: String,
            default: 'var(--dark-gray-color)',
        },
        color: {
            type: String,
            default: 'var(--gray-65-color)',
        },
        gap: String,
        itemValue: {
            type: String,
            default: 'id',
        },
        itemText: {
            type: String,
            default: 'name',
        },
    },
}
</script>

<style lang="sass" scoped>
.ui-tabs-switcher
    display: inline-flex
    padding: 8px
    background-color: var(--gray-96-color)
    border-radius: 4px

    &.--unstyled
        background-color: transparent

.ui-tabs-switcher__button
    --background-color: transparent
    padding: 10px 30px
    font-family: inherit
    background-color: var(--background-color)
    border-radius: inherit
    border: none
    color: var(--color)
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0)

    &:not(:last-child)
        margin-right: 15px

    &.--unstyled
        background-color: transparent
        padding: 10px 0
        box-shadow: none

.ui-tabs-switcher__button[data-notification-count]
    position: relative

    &::after
        content: attr(data-notification-count)
        position: absolute
        top: 3px
        right: 6px
        padding: 1px 5px
        font-size: 12px
        background-color: var(--red-color)
        border-radius: 16px
        color: white

.ui-tabs-switcher__button_state_active
    --background-color: var(--white-color)
    color: var(--active-color)
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05)
</style>
