import Repo from '@/api/service/repo'

export default class extends Repo {

    start (data) {
        return this.client.post(`bp/approval/start?tz=${this.tz}`, data)
    }

    withdraw (data) {
        return this.client.post(`bp/approval/withdraw?tz=${this.tz}`, data)
    }

    bpChanges (bpId, version) {
        return this.client.get(`bp/change/view?tz=${this.tz}&id=${bpId}&t=${version}`)
    }

}
