<template>
    <div class="wrapper">
        <div class="minus" @click="minus">-</div>
        <input
            :value="value"
            type="number"
            @change="input($event)"
            :placeholder="value || defaultValue"
        />
        <div class="plus" @click="plus">+</div>
    </div>
</template>

<script>
export default {
  name: 'UiNumberInput',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 100,
    },
    step: {
      type: [Number, String],
      default: 1,
    },
    defaultValue: {
      type: [Number, String],
      default: 0,
    },
  },
  methods: {
    minus () {
      const min = Number(this.min)
      const current = this.value ? Number(this.value) : this.defaultValue
      this.$emit('update', current > min ? current - Number(this.step) : current)
    },
    plus () {
      const max = Number(this.max)
      const current = this.value ? Number(this.value) : this.defaultValue
      this.$emit('update', current < max ? current + Number(this.step) : current)
    },
    input (event) {
      const inputVal = Number(event.target.value)
      const max = Number(this.max)
      const min = Number(this.min)
      this.$emit('update', inputVal >= min && inputVal <= max
          ? inputVal
          : inputVal > max ? max : min )
    },
  },
}
</script>

<style lang="sass" scoped>
.wrapper
  padding: 0 6px
  display: flex
  align-items: center
  height: 40px
  gap: 5px
  background: #EDEFF5
  border-radius: 4px

  .plus, .minus
    color: #207DD3
    border: 1px solid #207DD3
    border-radius: 4px
    width: 16px
    height: 16px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    font-size: 18px

  input
    background: transparent
    outline: none
    border: none
    width: 30px
    text-align: center
    margin: 0

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0


  /* Firefox */
  input[type=number]
    -moz-appearance: textfield


</style>
