import { set } from 'vuex-intern'

const initialState = () => ({
    contacts: {},
})

const state = initialState()

const mutations = {
    setContactsData: set('contacts'),
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }, type) {
        this.$repo('admin/contacts').get(type).then((r) => {
            commit('setContactsData', r.data)
        })
    },
    save (_, mass) {
        return this.$repo('admin/contacts').post(mass)
    },
}

const getters = {}

export const STORE_NAME = 'contacts'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
