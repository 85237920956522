import Repo from '@/api/service/repo'

export default class extends Repo {
    getRoles () {
        return this.client.get('/report/performerCooperationMatrix')
    }

    getReportHead (url) {
        return this.client.head(`/report/${url}`)
    }

    getReport (url) {
        return this.client.config({ timeout: 60000 }).get(`/report/${url}`)
    }

    getAccess () {
        return this.client.get('report/access')
    }

    tableauLinks () {
        return this.client.json().get(`tableau/link?tz=${this.tz}`)
    }

    createTableauLink (data) {
        return this.client.post(`tableau/link?tz=${this.tz}`, data)
    }

    deleteTableauLink (id) {
        return this.client.delete(`tableau/link/${id}?tz=${this.tz}`)
    }

    getAdminHead (url) {
        return this.client.head(`/admin/${url}`)
    }
}
