import Repo from '@/api/service/repo'

export default class extends Repo {
    getChats (taskId, filters = {}) {
        return this.client.post(
            `bp/approval/conversation/filter?t=${taskId}&tz=${this.tz}`,
            filters
        )
    }

    getFilterOptions (taskId) {
        return this.client.get(
            `bp/approval/conversation/filter/options?t=${taskId}&tz=${this.tz}`
        )
    }

    sort (taskId, sortedCommentIds) {
        return this.client.post(`bp/approval/conversation/sort?t=${taskId}&tz=${this.tz}`, {
            sortedComments: sortedCommentIds,
        })
    }

    resolutionIssue (taskId, issueId) {
        return this.client.put(
            `bp/approval/comment/${issueId}/resolution?t=${taskId}&tz=${this.tz}`
        )
    }

    fixIssue (taskId, issueId) {
        return this.client.put(
            `bp/approval/comment/issue/${issueId}/fix?t=${taskId}&tz=${this.tz}`
        )
    }

    reactivateIssue (taskId, issueId) {
        return this.client.put(
            `bp/approval/comment/issue/${issueId}/reactivation?t=${taskId}&tz=${this.tz}`
        )
    }

    edit (taskId, itemId, content) {
        return this.client.put(
            `bp/approval/comment/${itemId}/content?t=${taskId}&tz=${this.tz}`,
            content
        )
    }

    createIssue (taskId, issue) {
        return this.client.post(`bp/approval/comment/issue?tz=${this.tz}`, {
            taskId,
            section: issue.section,
            sectionItem: issue.sectionItem,
            content: issue.content,
        })
    }

    /**
     * Создать комментарий к замечанию, ЗЭМ или другому комментарию
     * @param {integer} taskId - ID задачи
     * @param {integer} originalCommentId - ID замечания, к которому относится комментарий
     * @param {string} content - контент
     * @param {string} section - раздел паспорта
     * @param {string} sectionItem - элемент раздела паспорта
     * @returns {*}
     */
    createReply (taskId, originalCommentId, content, section, sectionItem ) {
        return this.client.post(`bp/approval/comment/reply?tz=${this.tz}`, {
            taskId,
            originalCommentId,
            content,
            section,
            sectionItem,
        })
    }

    /**
     * Создать множественные комментарии к замечанию, ЗЭМ или другому комментарию
     * @param {Object[]} replies - список документов
     * @param {integer} replies[].taskId - ID задачи
     * @param {integer} replies[].originalCommentId - ID замечания, к которому относится комментарий
     * @param {string} replies[].content - контент
     * @param {string} replies[].section - раздел паспорта
     * @param {string} replies[].sectionItem - элемент раздела паспорта
     * @returns {*}
     */
    createReplyList (replies) {
        return this.client.post(`bp/approval/comment/reply/list?tz=${this.tz}`, replies)
    }

    remove (id) {
        return this.client.delete(`bp/approval/comment/${id}?tz=${this.tz}`)
    }

    read (taskId, commentId) {
        return this.client.put(`bp/approval/comment/${commentId}/view?t=${taskId}&tz=${this.tz}`)
    }

    drafts (taskId) {
        return this.client.get(`bp/approval/conversation/drafts?t=${taskId}&tz=${this.tz}`)
    }

    createDraft (taskId, refId, content) {
        return this.client.post(`bp/approval/comment/draft?t=${taskId}&tz=${this.tz}`, {
            taskId, refId, content,
        })
    }

    updateDraftContent (id, content) {
        return this.client.put(`bp/approval/comment/draft/${id}/content?tz=${this.tz}`, content)
    }

    removeDraft (id) {
        return this.client.delete(`bp/approval/comment/draft/${id}?tz=${this.tz}`)
    }

    bulkRemoveDrafts (ids) {
        return this.client.post(`bp/approval/comment/draft/bulk?tz=${this.tz}`, ids)
    }

    bulkUpdateDraftRefIDs (list) {
        return this.client.post(`bp/approval/comment/draft/refId?tz=${this.tz}`, list)
    }
}
