import { set } from 'vuex-intern'

const initialState = () => ({
    bpSections: {},
})

const state = initialState()

const mutations = {
    setBpSections: set('bpSections'),
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }, s) {
        return this.$repo('admin/field').get(s).then((r) => {
            commit('setBpSections', r.data)
        })
    },
    save (_, data) {
        return this.$repo('admin/field').post(data)
    },
}

const getters = {

}

export const STORE_NAME = 'bpSections'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
