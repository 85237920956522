import Repo from '@/api/service/repo'

export default class extends Repo {

    get (id, suggestionId) {
        return this.client.get(`bp/${id}/suggestion/${suggestionId}/chat?tz=${this.tz}`)
    }

    post (id, suggestionId, data) {
        return this.client.post(`bp/${id}/suggestion/${suggestionId}/chat?tz=${this.tz}`, data)
    }
}
