import dayjs from 'dayjs'

export const initialState = () => ({
    isCurrentUserExpert: false,

    taskId: null,
    taskType: null,
    taskCircleNum: null,
    taskPlannedDueDate: null,

    requests: [],

    myRequests: [],

    dates: {
        from: dayjs().startOf('d').toDate(),
        to: dayjs().endOf('d').toDate(),
    },
    activeCircles: [],

    solutions: [],

    enablePassportSections: {},

    openedSource: null,

    isLoadedDrafts: false,
    drafts: [],
})

const state = initialState()

export default state
