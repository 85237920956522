import Repo from '@/api/service/repo'

export default class extends Repo {
    getGroups (p, n = 20) {
        return this.client.get(`dictionary?type=BP_GROUP&p=${p}&n=${n}`)
    }
    getTypes (p, n = 20) {
        return this.client.get(`dictionary?type=BP_TYPE&p=${p}&n=${n}`)
    }
    getScope (p, n) {
        return this.client.get(`dictionary?type=SCOPE&p=${p}&n=${n}`)
    }
    searchGroups (p, s, n = 20) {
        return this.client.get(`dictionary?type=BP_GROUP&p=${p}&n=${n}&s=${s}`)
    }
    searchTypes (p, s, n = 20) {
        return this.client.get(`dictionary?type=BP_TYPE&p=${p}&n=${n}&s=${s}`)
    }
    searchScope (p, s, n = 20) {
        return this.client.get(`dictionary?type=SCOPE&p=${p}&n=${n}&s=${s}`)
    }
    saveGroups (mass) {
        return this.client.post('dictionary?type=BP_GROUP', mass)
    }
    saveTypes (mass) {
        return this.client.post('dictionary?type=BP_TYPE', mass)
    }
    saveScopes (data) {
        return this.client.post('dictionary?type=SCOPE', data)
    }
}
