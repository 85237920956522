<template>
    <div class="error">
        <img
            class="error__image"
            src="@/assets/images/Character.svg"
            width="748"
            height="558"
            alt="Сайт не отвечает (или есть проблемы с интернет-соединением)"
        >
        <section class="error__section">
            <h1 class="error__title">
                Упс!
            </h1>
            <p class="error__subtitle">
                Сайт не отвечает (или есть проблемы с интернет-соединением). <br>
                Проверьте подключение к сети интернет и повторите попытку.
            </p>
            <p class="error__subtitle">
                Или налейте себе чашечку чая и подождите, когда само наладится
            </p>
            <button
                class="error__button button"
                type="button"
                @click="$emit('retry')"
            >
                Попробовать снова
            </button>
            <p class="error__description">
                Для уточнения прав доступа обратитесь в службу технической поддержки по телефону
                <a
                    class="error__link"
                    :href="'tel:' + contactTechLine.phone"
                >
                    {{ contactTechLine.phone }}
                </a>
            </p>
            <p class="error__description">
                Или свяжитесь по электронной почте:
                <a
                    class="error__link"
                    :href='`mailto:${contactTechLine.email}`'
                >
                    {{ contactTechLine.email }}
                </a>
            </p>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['contactTechLine']),
    },
}
</script>

<style lang="sass">
@import "@/assets/sass/pages/error"
</style>
