import { render, staticRenderFns } from "./TheDisclaimer.vue?vue&type=template&id=093dbaa0"
import script from "./TheDisclaimer.vue?vue&type=script&lang=js"
export * from "./TheDisclaimer.vue?vue&type=script&lang=js"
import style0 from "./TheDisclaimer.vue?vue&type=style&index=0&id=093dbaa0&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports