import { set } from 'vuex-intern'

const initialState = () => ({
    notifications: {},
})

const state = initialState()

const mutations = {
    setNotifications: set('notifications'),
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('admin/notification').get().then((r) => {
            commit('setNotifications', r.data)
        })
    },
    saveEvents ({ getters }, event) {
        const events = getters.events.map((e) => e.code === event.code ? { ...e, ...event } : e)

        return this.$repo('admin/notification').post({ events })
    },
}

const getters = {
    bpRecipients: (state) => state.notifications?.bpRecipients || [],
    labelsBpRecipients: (state, getters) => Object.fromEntries(getters.bpRecipients.map(({ code, title }) => [code, title])),
    roles: (state) => state.notifications?.roles || [],
    events: (state) => state.notifications?.events || [],
    namesFromRoles: (state) => state.notifications?.roles?.map((role, id) => ({ ...role, id })) || [],
}

export const STORE_NAME = 'notifications'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
