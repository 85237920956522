import { RequestService } from '@argab/request-service'
import ApiClient from './client'
import ApiHandler from './handler'
import ApiLoader from './loader'
import ApiCustomHandler from '@/api/service/custom_handler'

const request = new RequestService({
    getRepo: (path) => {
        const Repo = require(`@/api/repos/${path}`).default
        return new Repo
    },
    getStub: (path) => {
        const Repo = require(`@/api/repos/${path}`).default
        const stub = new Repo
        const data = {}

        require.context('@/api/', true, /\.js$/).keys().forEach(i => {
            if (i.includes(`stubs/${path}`)) {
                const split = i.split('/')
                const repoMethod = split[split.length - 1].replace('.js', '')
                data[repoMethod] = require(`@/api/stubs/${i.replace('./stubs/', '')}`).default
            }
        })

        stub.setStubs(data)

        return stub
    },
    useStubs: process.env?.NODE_ENV === 'development',
    config: {
        client: ApiClient,
        handler: ApiHandler,
        loader: ApiLoader,
        useLoader: true,
    },
    extend: {
        middleware: {
            useHandler ({ repo, method, onCatch }) {
                ApiCustomHandler.push({ repo, method, onCatch })
            },
            retryAction: function (use = true) {
                this.config({ useRetryAction: Boolean(use) })
            },
        },
        request: {},
    },
})

export default request
