<template>
    <div class="ui-text-constructor">
        <ul class="ui-text-constructor__tags">
            <li
                v-for="(tag, index) in tags"
                :key="'tag' + index"
                class="ui-text-constructor__tags-item"
            >
                <span class="ui-text-constructor__tags-name" @click="addTag(tag.name)">
                    {{ tag.name }}
                </span>
                <span class="ui-text-constructor__tags-description">{{ tag.desription  }}</span>
            </li>
        </ul>
        <div
            ref="editorWrap"
            class="ui-text-constructor__wrapper"
            :class="{
                filled: value || isActive,
                empty: !value && !isActive,
            }"
            :style="{ minHeight: `${minHeight}px` }"
            @click="elEditor.focus()"
        >
            <div
                v-show="!value && !isActive"
                ref="placeholder"
                class="ui-text-constructor__placeholder"
                v-html="placeholder"
            />
            <div
                class="ui-text-constructor__text-editor"
                contenteditable="true"
                ref="editor"
                @focus="onFocus"
                @blur="onBlur"
            />
            <span
                v-if="value"
                class="ui-text-constructor__button-clear"
                @click="clearText"
            >
                <img src="~@/assets/images/close.svg" />
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiTextConstructor',
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        tags: {
            type: Array,
            required: false,
            default: () => [],
        },
        placeholder: {
            type: String,
            require: false,
            default: '',
        },
        minHeight: {
            type: Number,
            required: false,
        },
        replacer: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data () {
        return {
            delay: 1000,
            isActive: false,
            range: null,
            selection: null,
        }
    },
    computed: {
        elEditor () {
            if (!this.$refs.editor) return null
            return this.$refs.editor
        },
    },
    mounted () {
        setTimeout(() => {
            document.addEventListener('selectionchange', this.saveSelection)
            this.elEditor.addEventListener('input', this.changeText)
            this.elEditor.innerText = this.value
            this.init()
        }, 400)
    },
    methods: {
        onBlur () {
            this.isActive = false
        },

        onFocus () {
            this.isActive = true
        },

        getTagHTML (str) {
            return `<span contenteditable="false" class="tag">${str}</span>`
        },

        replaceTextToTag (textHTML, tag) {
            const rgx = new RegExp(`(?:(?!>))\\${tag.name}(?:(?!</span>))`, 'g')
            if (textHTML.search(rgx) !== -1) {
                textHTML = textHTML.replaceAll(
                    rgx,
                    this.getTagHTML(tag.name)
                )
            }

            return textHTML
        },

        saveSelection () {
            if (window.getSelection && this.isActive) {
                this.selection = window.getSelection()
                if (this.selection.getRangeAt && this.selection.rangeCount) {
                    this.range = this.selection.getRangeAt(0)
                }
            }
        },
        restoreSelection () {
            if(this.range && this.selection) {
                this.range.collapse(false)
                this.selection.removeAllRanges()
                this.selection.addRange(this.range)
            }
        },

        clearText () {
            this.elEditor.innerText = ''
            this.$emit('change', '')
        },

        changeText () {
            const text = this.elEditor.innerText
            this.$emit('change', text)
        },

        addTag (newTag) {
            const elTag = document.createElement('span')
            elTag.classList.add('tag')
            elTag.setAttribute('contenteditable', 'false')
            elTag.appendChild(document. createTextNode(newTag))
            this.range ? this.range.insertNode(elTag) : this.elEditor.appendChild(elTag)
            this.restoreSelection()
            this.changeText()
        },

        init () {
            let textHTML = this.elEditor.innerHTML
            this.tags?.forEach((tag) => {
                textHTML = this.replaceTextToTag(textHTML, tag)
            })

            this.elEditor.innerHTML = textHTML
        },
    },
}
</script>

<style lang="sass" >
.tag
    background-color: var(--blue-color)
    border-radius: 4px
    padding: 3px 8px
    color: white
    cursor: default
    margin: 0 1px
    display: inline-block
    line-height: 1.2
</style>

<style lang="sass" scoped>
.ui-text-constructor
    &__wrapper
        position: relative
        text-align: left
        border-radius: 4px
        padding: 10px 2px 10px 16px
        font-family: inherit
        box-shadow: 0 1px 4px rgba(65, 103, 160, 0.32)
        box-sizing: border-box
        width: 100%
        line-height: 28px
        display: inline-block
        border-style: none
        cursor: text

        &:focus
            outline: 0 solid transparent
            border: 1px solid var(--blue-color)

    &__text-editor
        min-width: 1px
        display: inline-block
        outline: none

    &__placeholder
        position: absolute
        top: 10px
        left: 16px

    &__tags
        display: flex
        flex-direction: column
        row-gap: 24px
        padding-left: 0
        margin-top: 0
        margin-bottom: 41px
        list-style-type: none

    &__tags-item
        display: grid
        grid-template-columns: 137px 1fr
        align-items: center
        justify-items: flex-start

    &__tags-name
        --space: 4px
        display: inline-block
        padding: var(--space) calc(var(--space) * 2)
        font-size: 13px
        color: #207dd3
        background-color: rgba(32, 125, 211, 0.1)
        border-radius: 8px
        cursor: pointer

    &__button-clear
        position: absolute
        right: 0
        top: 0
        margin: 10px 16px
        cursor: pointer

.filled
    background: var(--gray-96-color)
    color: var(--dark-gray-color)
    box-shadow: none
    padding-right: 26px
    font-family: inherit
    font-size: inherit

.empty
    color: var(--gray-85-color)
    line-height: 2
</style>
