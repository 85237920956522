import Repo from '@/api/service/repo'

export default class extends Repo {
    get ({ p, r, rp, s, d, a }) {
        const query = this.makeQuery({
            p, r, rp, s, d, a,
        }, { arrayFormat: 'none' })
        return this.client.get(`task?${query}`)
    }

    getTask (id) {
        return this.client.get(`task/${id}`)
    }

    action ({ id, action, data }) {
        return this.client.config({ timeout: 60000 }).post(`task/${id}/action?a=${action}`, data)
    }

    getButtons (id) {
        return this.client.get(`task/${id}/buttons`)
    }

    approver (id, data) {
        return this.client.post(`task/${id}/approver`, data)
    }

    count () {
        return this.client.get('task/count')
    }
}
