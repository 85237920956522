<template>
    <div
        class="ui-menu-container"
        v-click-outside="close"
        @click.stop="() => {}"
    >
        <a
            href="javascript:void(0)"
            :title="title"
            class="ui-menu-toggle"
            @click.stop="toggle"
        >
            <slot v-if="$slots.icon" name="icon" />
            <IconHorizontalDots v-else />
        </a>
        <transition name="fade">
            <div v-show="isOpen" class="ui-menu-list-wrap">
                <div class="ui-menu-list">
                    <slot name="beforeItems" />
                    <slot />
                    <slot name="afterItems" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import IconHorizontalDots from "@/ui/icons/IconHorizontalDots"

export default {
    name: 'UiMenu',
    components: { IconHorizontalDots },
    props: {
        title: String,
        actions: Array,
    },
    data: () => ({
       isOpen: false,
    }),
    methods: {
        toggle () {
            this.isOpen
                ? this.close()
                : this.open()
        },
        open () {
            this.isOpen = true
            this.$emit('open')
        },
        close () {
            this.isOpen = false
            this.$emit('close')
        },
    },
}
</script>

<style scoped lang="sass">
.ui-menu-container
    display: flex
    position: relative
    cursor: default

    .ui-menu-toggle
        color: var(--gray-65-color)
        font-size: 32px

        &:hover
            color: var(--blue-color)

    .ui-menu-list-wrap
        position: absolute
        top: 32px
        left: 0
        padding: 16px 8px
        min-width: 320px
        background: white
        box-shadow: 0 4px 12px 0 #0077C826
</style>
