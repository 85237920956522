<!-- eslint-disable -->
<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
    >
        <circle cx="10" cy="16" r="1.5" fill="currentColor" stroke="currentColor"/>
        <circle cx="16" cy="16" r="1.5" fill="currentColor" stroke="currentColor"/>
        <circle cx="22" cy="16" r="1.5" fill="currentColor" stroke="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: 'IconHorizontalDots',
}
</script>
<!-- eslint-enable -->
