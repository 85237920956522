var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-text-field",class:_vm.error ? 'validation-error' : _vm.validation && (_vm.showErrorsTouched
        ? _vm.touched && _vm.validationError && 'validation-error'
        : (_vm.validationError && 'validation-error'))},[_c('div',{staticClass:"title-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.value || _vm.list) && _vm.title),expression:"(value || list) && title"}],staticClass:"ui-text-field__title"},[_vm._v(_vm._s(_vm.title))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActiveHighLightPanel),expression:"isActiveHighLightPanel"}],staticClass:"ui-text-field__textarea validate-input highlight-wrapper",class:{
            'filled-textarea': _vm.value,
            'disabled-textarea': _vm.disabled,
            'input-unactive': _vm.disabled
        },style:({ minHeight: _vm.minHeight, maxHeight: _vm.maxHeight }),domProps:{"innerHTML":_vm._s(_vm.value)},on:{"click":_vm.hideHighLightPanel}}),(_vm.list && _vm.list.length)?[_c('div',{staticClass:"ui-text-field__textarea validate-input",class:{
                'filled-textarea': !!_vm.list.length,
                'disabled-textarea': _vm.disabled,
                'input-unactive': _vm.disabled
            }},[_c('div',{staticClass:"ui-text-field-list"},_vm._l((_vm.list),function(item,i){return _c('div',{key:i,staticClass:"ui-text-field-list-item",class:{deleted: item.deleted}},[_c('span',[_vm._v(_vm._s(item[_vm.listLabel]))]),_c('span',{staticClass:"--rem",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove', item, i)}}},[_vm._v("×")])])}),0)])]:_c('textarea-autosize',{ref:"input",staticClass:"ui-text-field__textarea validate-input",class:{
            'filled-textarea': _vm.value,
            'disabled-textarea': _vm.disabled,
            'input-unactive': _vm.disabled
        },attrs:{"value":_vm.cleanValue,"max-height":_vm.maxHeight,"min-height":_vm.minHeight,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"rows":_vm.rows},on:{"input":_vm.onInput},nativeOn:{"scroll":function($event){return _vm.$emit('scroll', $event)}}}),_c('div',{ref:"controls",staticClass:"ui-text-field-controls"},_vm._l((_vm.getControls()),function(c,k){return _c('div',{key:k,staticClass:"--control-item"},[_c(c.tag,_vm._g(_vm._b({tag:"component"},'component',c.bind,false),_vm.getControlHandlers(c.on)))],1)}),0),(_vm.error)?_c('div',{staticClass:"validation-text"},[_vm._v(_vm._s(_vm.error))]):(_vm.required && !_vm.value)?_c('div',{staticClass:"validation-text"},[_vm._v("Обязательно для заполнения")]):_vm._e(),(_vm.number && !_vm.isNumber)?_c('div',{staticClass:"validation-text"},[_vm._v("Значение должно быть числом")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }