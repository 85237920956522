import { stringify as queryStringify } from 'query-string'
import dayjs from 'dayjs'
import { Cookie } from '@/utils/cookie'
import dom from '@/utils/dom'
import RequestService from '../api/service/request'
import { uuid } from 'vue-uuid'

import getMimeTypes from '@/utils/dataMimeTypes'

const HEADER_OFFSET = 200 // px
const cookie = new Cookie()

export default {

    get cookie () {
        return cookie
    },

    get dom () {
        return dom
    },

    flatArray (array, predicate) {
        const result = []
        for (const o of array) {
            result.push(predicate(o))
        }
        return result
    },

    uniqBy (arr, key) {
        return [...new Map(arr.map(item => {
            let uid = ''
            if (Array.isArray(key)) {
                for (const id of key) uid += this.findDeep(item, id)
            } else {
                uid = this.findDeep(item, key)
            }

            return [uid, item]
        })).values()]
    },

    uniq (arr) {
        return [...new Set(arr)]
    },

    joinObjects (objList, keyFunc = r => r.name) {
        const values = []

        for (const o of objList) {
            const spanClass = o.deleted ? ' class="deleted"' : ''
            values.push(`<span${spanClass}>${keyFunc(o)}</span>`)
        }

        return values.length ? values.join(', ') : ''
    },

    findDeep (obj, path, def) {
        return path.split('.').reduce((acc, part) => (acc instanceof Object && acc[part] !== undefined)
            ? acc[part]
            : (def !== undefined ? def : null)
          , obj)
    },

    cloneDeep (obj) {
        return this.merge({}, obj)
    },

    iterateObject (obj, callback, path = []) {
        const IterateObject = require('iterate-object')

        IterateObject(obj, (value, label) => {
            callback(value, label, path)
            if (value instanceof Object && !Array.isArray(value)) {
                const keys = [...path, label]
                Object.keys(value).forEach(key => {
                    this.iterateObject({ [key]: value[key] }, callback, keys)
                })
                return
            }
            if (Array.isArray(value)) {
                value.forEach((item, index) => this.iterateObject(item, callback, [...path, label, index]))
            }
        })
    },

    merge (target, ...sources) {
        target instanceof Object || (target = {})
        const merge = require('deepmerge')

        for (const source of sources) {
            source && (target = merge(target, source))
        }

        return target
    },

    mergeDeep (target, source, onKeys = []) {
        const isObject = (obj) => obj && obj instanceof Object
        if (!isObject(target) || !isObject(source)) {
            return source
        }

        (onKeys && onKeys.length ? onKeys : Object.keys(source)).forEach(key => {
            const targetValue = target[key]
            const sourceValue = source[key]

            if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
                target[key] = targetValue.concat(sourceValue)
            } else if (isObject(targetValue) && isObject(sourceValue)) {
                target[key] = this.mergeDeep(Object.assign({}, targetValue), sourceValue)
            } else {
                target[key] = sourceValue
            }
        })

        return target
    },

    concatObjects (source, target) {
        const isObject = (obj) => obj && obj instanceof Object
        if (Array.isArray(source) && Array.isArray(target)) {
            return source.concat(target).reduce(
                (acc, part) => {
                    if (!acc.some(x => isObject(x) && isObject(part) && JSON.stringify(x) === JSON.stringify(part))) {
                        acc.push(part)
                    }
                    return acc
                }, []
            )
        }
        return source
    },

    createFilledArray (size, fillFunc) {
        const result = []
        for (let i = 0; i < size; i++) {
            result.push(fillFunc())
        }
        return result
    },

    openLink (url) {
        window.open(url, '_blank')
    },

    userPhoto (user) {
        if (!(user && user.photo)) {
            return require('@/assets/images/default-user.svg')
        }
        return user.photo
    },

    replacer (str) {
        return str.replace('<span class="hl">', '').replace('</span>', '')
    },

    deepCutHtml (obj) {
        return JSON.parse(JSON.stringify(obj).replace(/<[^>]*>?/gm, ''))
    },

    // Эдакий прокачанный Promise
    // работает так:
    // await promise(() => document.querySelector('#someSelector')).then(elementDomObject => elementDomObject))
    // Проверяет истинность результата "cond(номер попытки)"
    //      interval - интервал проверки (мс)
    //      delay - задержка перед первой проверкой (мс)
    //      maxCount - макс число попыток
    promise (cond, { maxCount: maxCount = 30, interval: interval = 1000, delay: delay = 0 }) {
        if (typeof cond !== 'function')
            return false
        let count = 0, i
        return new Promise((resolve, reject) => {
            const handle = () => {
                count += 1
                const check = cond(count)
                if (check || count >= maxCount) {
                    check ? resolve(check) : reject()
                    clearInterval(i)
                }
            }
            setTimeout(() => {
                handle()
                i = setInterval(() => handle(), interval)
            }, delay)
        })
    },

    md5 (value) {
        return require('md5')(value)
    },

    deepCopy (obj) {
        return JSON.parse(JSON.stringify(obj))
    },

    deepCopyUnset (obj) {
        return JSON.parse(this.jsonStringifyUnset(obj))
    },

    // https://bobbyhadz.com/blog/javascript-typeerror-converting-circular-structure-to-json
    jsonStringifyUnset (obj) {
        const getCircularReplacer = () => {
            const seen = new WeakSet()
            return (key, value) => {
                if (typeof value === 'object' && value !== null) {
                    if (seen.has(value)) {
                        return
                    }
                    seen.add(value)
                }
                return value
            }
        }
        return JSON.stringify(obj, getCircularReplacer())
    },

    isNil (value) {
        return value === null || value === undefined
    },

    isPlainObject (value) {
        return !this.isNil(value) && !Array.isArray(value) && typeof value !== 'function' && value instanceof Object
    },

    isEmpty (value) {
        if (this.isNil(value)) return true
        const type = Array.isArray(value) ? 'array' : this.isPlainObject(value) ? 'object' : typeof value

        switch (type) {
            case 'array': {
                return value.length === 0
            }
            case 'object': {
                return Object.keys(value).length === 0
            }
            case 'string': {
                return value.trim() === ''
            }
            case 'number': {
                return value === 0
            }
            case 'boolean': {
                return value !== true
            }
        }
        return !value
    },

    uriEncode (params, props) {
        return queryStringify(params, Object.assign({ arrayFormat: 'none' }, this.isPlainObject(props) ? props : {}))
    },

    formatDate (date, format) {
        if (!date) return ""

        date = dayjs(date)

        return date.isValid() ? date.format(format || 'YYYY-MM-DD') : ''
    },

    scrollTo (el) {
        el.scrollIntoView()
        window.scrollBy(0, -HEADER_OFFSET)
    },

    waitFor (condition, interval = 100) {
        const _waiter = (resolve) => {
            if (condition()) {
                resolve()
            } else {
                setTimeout(() => _waiter(resolve), interval)
            }
        }

        return new Promise(resolve => {
            _waiter(resolve)
        })
    },

    randomId () {
        return Math.floor(100000 + Math.random() * 900000)
    },

    randomUUID () {
        return uuid.v4()
    },

    sortObjects (list, attrName, asc = true) {
        list.sort((a, b) => {
            const aa = this.isPlainObject(a) ? this.findDeep(a, attrName) : a
            const bb = this.isPlainObject(b) ? this.findDeep(b, attrName) : b
            return asc ? (aa < bb ? -1 : 1) : (aa > bb ? -1 : 1)
        })
        return list
    },

    prettyUrlsInText (text) {
        let modifyText = text

        const regexTagA = new RegExp(/<a.*?>*?<\/a>/gm)
        const links = text.match(regexTagA) || []

        links.forEach((l, i) => {
            modifyText = modifyText.replace(l, `[${i}]`)
        })

        const regexUrl = new RegExp(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(\.[a-z]{2,6})?\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/gm)
        const urls = this.uniq(modifyText.match(regexUrl) || [])

        urls.forEach(url => {
            modifyText = modifyText.replaceAll(url, `<a href="${url}" target="_blank">${url}</a>`)
        })

        links.forEach((l, i) => {
            modifyText = modifyText.replace(`[${i}]`, l)
        })

        return modifyText
    },

    async getFileName (url) {
        if (!url) return ''

        return RequestService.repo('storage').getInfo(`..${url}&tz=${-(new Date().getTimezoneOffset())}`).then(headers => {
            let name = ''

            const disposition = headers['content-disposition']
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                const matches = filenameRegex.exec(disposition)
                if (matches !== null && matches[1]) {
                    name = matches[1].replace(/['"]/g, '').replace('UTF-8', '')
                }
            }

            return decodeURIComponent(name)
        })
    },

    async getFileInfo (url) {
        if (!url) return ''

        return RequestService.repo('storage').getInfo(`..${url}&tz=${-(new Date().getTimezoneOffset())}`).then(headers => {
            let name = ''

            const disposition = headers['content-disposition']

            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                const matches = filenameRegex.exec(disposition)

                if (matches !== null && matches[1]) {
                    name = matches[1].replace(/['"]/g, '').replace('UTF-8', '')
                }
            }

            return {
                name: decodeURIComponent(name),
                type: headers['content-type'] || '',
                size: +headers['content-length'] || 0,
                lastModified: headers.date ? new Date(headers.date).getTime() : null,
                lastModifiedDate: headers.date ? new Date(headers.date) : null,
            }
        })
    },

    fileToBase64 (file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
        })
    },

    fetchFile (url) {
        if (!url) {
            throw new Error('Не указан url файла для скачивания')
        }

        return new Promise((resolve) => {
            fetch(url)
                .then((response) => response.blob())
                .then(async (blob) => {
                    const fileName = await this.getFileName(url)
                    const extension = fileName.split('.').at(-1)
                    const mimetype = getMimeTypes()[extension]

                    const file = new File([blob], fileName, {
                        type: mimetype,
                    })

                    resolve([file, fileName, extension])
                })
        })
    },

    zoomScreen () {
        return screen && window
            ? Math.ceil((screen.width / window.innerWidth) * 100) / 100
            : 1
    },

    getScrollSize () {
        const div = document.createElement('div')

        div.style.overflowY = 'scroll'
        div.style.width = '50px'
        div.style.height = '50px'

        document.body.append(div)

        const scrollWidth = div.offsetWidth - div.clientWidth

        div.remove()

        return scrollWidth
    },

    debounce (originalFn, ms = 100) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => originalFn(...args), ms)
        }
    },

    shortName (fullName) {
        const fullNameAsShort = /^[А-ЯЁ][а-яё]+\s[А-ЯЁа-яё]\.[А-ЯЁа-яё]\.$/.test(fullName)

        if (fullNameAsShort) {
            return fullName
        }

        const [lastName, ...other] = fullName.split(' ')
        const initials = other.map(p => p[0] + '.').join('')

        return `${lastName} ${initials}`
    },

    compareArrays (arr1, arr2) {
        if (!arr1 || !arr2 || !Array.isArray(arr1) || !Array.isArray(arr2)) {
            return false
        }

        return arr1.length === arr2.length &&
            arr1.every((v, i) => v === arr2[i])
    },

    compareArraysByField (arr1, arr2, field) {
        if (!arr1 || !arr2 || !Array.isArray(arr1) || !Array.isArray(arr2)) {
            return false
        }

        const flatArr1 = arr1.map((el) => el[field])
        const flatArr2 = arr2.map((el) => el[field])

        return flatArr1.length === flatArr2.length &&
            flatArr1.every((v, i) => v === flatArr2[i])
    },
}
