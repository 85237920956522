import Repo from '@/api/service/repo'

export default class extends Repo {
    get () {
        return this.client.get('fav/employee')
    }

    add (id) {
        return this.client.post(`fav/employee/${id}`)
    }

    remove (id) {
        return this.client.delete(`fav/employee/${id}`)
    }
}
