export default [
    {
        path: '/account',
        redirect: { name: 'AccountOwnBP' },
        meta: { middleware: { section: 'personalAccount' } },
    },
    {
        path: '/account/own-bp', name: 'AccountOwnBP',
        component: () => import('../../views/account/PageOwnBP'),
        meta: { middleware: { section: 'personalAccount' } },
    },
    {
        path: '/account/metod-bp', name: 'AccountMetodBP',
        component: () => import('../../views/account/PageMetodBP'),
        meta: { middleware: { section: 'personalAccount' } },
    },
    {
        path: '/account/notifies', name: 'AccountNotifies',
        component: () => import('../../views/account/PageNotifies'),
        meta: { middleware: { section: 'personalAccount' } },
    },
]
