import Repo from '@/api/service/repo'

export default class extends Repo {

    /**
     * @param id ID БП для получения истории изменений
     * @param e Флаг редактирования (true при редактировании БП, false при просмотре БП)
     * @param r ID ревизии/версии БП
     * @param p Индекс страницы (нумерация с 0)
     * @param g Тип группировки изменений Available values : DATE, AUTHOR Default value : DATE
     * @param s Начальная дата периода
     * @param f Конечная дата периода
     * @returns {*}
     */
    get ({ id, e, p, g, s, f, r }) {
        const query = { id, e, p, g, s, f, r, tz: this.tz }
        return this.client.get(`bp/log/group?${this.makeQuery(query)}`)
    }

    getRecord (id) {
        return this.client.get(`bp/log/${id}`)
    }

    getDetails (ids) {
        return this.client.get(`bp/log/detail/?id=${ids}`)
    }
}
