const TASK_ID = 0
const REQUEST_ID = 1
const SECTION = 2
const SECTION_ITEM = 3
const IS_SELECTED = 4
const ORDER = 5
const COPIED_REPLY_ID = 6

export const SEPARATOR = '|'

class BuilderRefId {
    refId = ''

    taskId = 0
    requestId = 0
    section = ''
    item = ''
    isSelected = false
    order = 0
    copiedReplyId = 0

    constructor (refId) {
        if (!refId) {
            return this
        }

        return this.parse(refId)
    }

    setAllParams (params) {
        params.taskId && this.setTaskId(params.taskId)
        params.requestId && this.setRequestId(params.requestId)
        params.section && this.setSection(params.section)
        params.sectionItem && this.setSectionItem(params.sectionItem)
        params.isSelected && this.setSelected(params.isSelected)
        params.order && this.setOrder(params.order)
        params.copiedReplyId && this.setCopiedReplyId(params.copiedReplyId)

        return this
    }

    setTaskId (taskId) {
        this.taskId = +taskId
        return this
    }

    setRequestId (requestId) {
        this.requestId = +requestId
        return this
    }

    setSection (section) {
        this.section = section
        return this
    }

    setSectionItem (item = 'HEADER') {
        this.item = item
        return this
    }

    setSelected (isSelected) {
        this.isSelected = !!+isSelected
        return this
    }

    setOrder (order) {
        this.order = +order
        return this
    }

    setCopiedReplyId (copiedReplyId) {
        this.copiedReplyId = +copiedReplyId
        return this
    }

    parse (refId) {
        const params = refId.split(SEPARATOR)

        this.taskId = params[TASK_ID]
        this.requestId = params[REQUEST_ID]
        this.section = params[SECTION]
        this.item = params[SECTION_ITEM]
        this.isSelected = !!+params[IS_SELECTED]
        this.order = params[ORDER]
        this.copiedReplyId = params[COPIED_REPLY_ID]

        return this
    }

    getData () {
        return {
            taskId: +this.taskId,
            requestId: +this.requestId,
            section: this.section,
            sectionItem: this.item,
            isSelected: !!this.isSelected,
            order: +this.order,
            copiedReplyId: +this.copiedReplyId,
        }
    }

    generate () {
        return [
            +this.taskId,
            +this.requestId,
            this.section,
            this.item,
            +this.isSelected,
            +this.order,
            +this.copiedReplyId,
        ].join(SEPARATOR)
    }
}

export default BuilderRefId
