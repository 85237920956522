export default {
    fitToWindow ({ el, width, height, offsetLeft, offsetTop }) {
        const ww = window.innerWidth
        const wh = window.innerHeight
        const h = height || el.offsetHeight
        const w = width || el.offsetWidth

        let { left, top } = el.getBoundingClientRect()

        offsetLeft ||= 0
        offsetTop ||= 0
        left += offsetLeft
        top += offsetTop

        if (left + w > ww) el.style.marginLeft = -((w + left) - ww) + 'px'
        if (left < 0) el.style.marginLeft = left * -1 + 'px'
        if (top + h > wh) el.style.marginTop = -((h + top) - wh) + 'px'
        if (top < 0) el.style.marginTop = top * -1 + 'px'

        return el
    },
}
