import Repo from '@/api/service/repo'

export default class extends Repo {
    getCategories () {
        return this.client.get('kb/category')
    }

    get (id) {
        let query = 'kb'
        if (id) {
            query = `kb?id=${id}`
        }
        return this.client.get(query)
    }

    upsert (data) {
        return this.client.post('kb', data)
    }

    delete (id) {
        return this.client.delete(`kb?id=${id}`)
    }

    getLog (id) {
        return this.client.get(`kb/log?id=${id}`)
    }

    getLogRecord (id) {
        return this.client.get(`kb/log/${id}`)
    }
    saveBpMap (description) {
        return this.client.post(`kb/bpMap?tz=${this.tz}`, description)
    }
}
