import Repo from '@/api/service/repo'

export default class extends Repo {
    get ({ p, r, e, d }) {
        const query = this.makeQuery({
            p, r, e, d,
        }, { arrayFormat: 'none' })
        return this.client.get(`notification/?${query}`)
    }
    put (id, e, all = false) {
        const query = this.makeQuery({ id, e, all }, { arrayFormat: 'none' })

        return this.client.put(`notification?${query}`)
    }
    delete (id, e, all = false) {
        const query = this.makeQuery({ id, e, all }, { arrayFormat: 'none' })

        return this.client.delete(`notification?${query}`)
    }
    getEvents () {
        return this.client.get('notification/event')
    }
}
