import { set } from 'vuex-intern'
import utils from '@/utils'

const initialState = () => ({
    formLoaded: false,
    dataLoaded: false,
    data: [],
    formData: {},
    page: 0,
    selected: [],
})

export const BP_SEARCH_STORE_NAME = 'bp_search'

export const BP_SEARCH_STATE = {
    namespaced: true,
    state: initialState(),
    mutations: {
        setFormLoaded: set('formLoaded'),
        setDataLoaded: set('dataLoaded'),
        setData: set('data'),
        setFormData: set('formData'),
        toggleFilter (state, { filter, id, name, resetSimilarOther }) {
            if (resetSimilarOther) {
                state.selected = state.selected.filter(x => x.filter !== filter)
            }

            const predicate = x => (x.filter === filter && x.id === id)
            if (state.selected.find(predicate)) {
                state.selected = state.selected.filter(x => !(predicate(x)))
            } else {
                state.selected.push({ filter, id, name })
            }
        },
        toggleSelector (state, { filter, mass }) {
            const predicate = x => (x.filter !== filter)
            state.selected = state.selected.filter(x => predicate(x))
            mass.map(e => state.selected.push({ filter, 'id': e.id, 'name': e.name }))
        },
        resetState (state) {
            Object.assign(state, initialState())
        },
        incrementPage (state) {
            state.page += 1
        },
        pageToZero (state) {
            state.page = 0
        },
        pushToData (state, data) {
            state.page = data.page === undefined ? 0 : data.page
            data.list.map(e => state.data.list.push(e))
        },
    },
    actions: {
        search ({ commit, getters }, s) {
            commit('pageToZero')

            const query = { ...getters.query }

            if (s) {
                query.s = s
            }

            this.$repo('bp/search').search(query).then(r => {
                commit('setData', r.data)
                commit('setDataLoaded', true)
            })
        },
        fetchFiltersForm ({ commit }) {
            commit('setFormLoaded', false)
            this.$repo('bp/search_form').getForm().then(r => {
                commit('setFormData', r.data)
                commit('setFormLoaded', true)
            })
        },
        searchAndPush ({ commit, getters }, s) {
            commit('incrementPage')

            const query = { ...getters.query }

            if (s) {
                query.s = s
            }

            this.$repo('bp/search').search(query).then(r => {
                commit('pushToData', r.data)
            })
        },
    },
    getters: {
        query: (state) => {
            const extractFiltersId = (filter, pattern) => {
                const filters = state.selected.filter(x => x.filter === filter && (!pattern || pattern(x)))
                return utils.flatArray(filters, x => x.id)
            }

            const query = {
                fields: extractFiltersId('fields'),
                pub: extractFiltersId('pub'),
                types: extractFiltersId('types'),
                segmentations: extractFiltersId('segmentations'),
                scopes: extractFiltersId('scopes'),
                organizations: extractFiltersId('organizations'),
                p: state.page,
            }

            state.selected
                .filter((s) => (s.filter === 'statuses'))
                .forEach((s) => (query[s.id] = true))

            return query
        },
    },
}
