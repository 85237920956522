import { set } from 'vuex-intern'
import utils from '@/utils'

export const NOTIFICATION_STORE_NAME = 'notification'

export const NOTIFICATION_STATE = {
    namespaced: true,
    state: {
        isLoading: false,
        notificationFilter: {
            status: null,
            event: null,
            sortDirection: 'DESC',
        },
        notifications: [],
        events: [{ code: null, name: 'Все' }],

        taskCount: 0,

        allNotifyAndTasksCount: 0,
    },
    mutations: {
        setFilter (state, params) {
            for(const param in params) {
                state.notificationFilter[param] = params[param]
            }
        },
        setLoading: set('isLoading'),
        setEvents: set('events'),
        setNotifications: set('notifications'),
        setAllNotifyAndTasksCount: set('allNotifyAndTasksCount'),
        setTaskCount: set('taskCount'),
    },
    actions: {
        fetchEvents ({ state, commit }) {
            commit('setLoading', true)

            return this.$repo('notification').getEvents()
                .then(r => {
                     commit('setEvents', utils.uniqBy([...state.events, ...r.data], 'code'))
                })
                .finally(() => commit('setLoading', false))
        },
        fetchNotifications ({ state, commit, getters, dispatch }, p) {
            commit('setLoading', true)

            return new Promise(resolve => {
                this.$repo('notification').get({ p, ...getters.query })
                    .then((r) => {
                        commit('setNotifications', !p ? r.data.list : state.notifications.concat(r.data.list))
                        resolve({ list: r.data.list, totalPages: r.data.totalPages })
                        dispatch('getNotifyCount')
                    })
                    .finally(() => commit('setLoading', false))
            })
        },
        getNotifyCount ({ commit }) {
            this.$rs.bg().repo('notification/count').get().then((r) => {
                commit('setAllNotifyAndTasksCount', r.data.count)
            })
        },
        getTaskCount ({ commit }) {
            this.$rs.bg().repo('tasks').count().then((r) => {
                commit('setTaskCount', r.data.count)
            })
        },
    },
    getters: {
        notificationCount: state => (state.allNotifyAndTasksCount - state.taskCount) < 0 ? 0 : state.allNotifyAndTasksCount - state.taskCount,
        query: state => ({
            r: state.notificationFilter.status,
            e: state.notificationFilter.event,
            d: state.notificationFilter.sortDirection,
        }),
    },
}
