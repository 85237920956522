import Repo from '@/api/service/repo'

export default class extends Repo {
    get (p) {
        return this.client.get(`risk-degree?p=${p}`)
    }
    search (p, s) {
        return this.client.get(`risk-degree?p=${p}&s=${s}`)
    }
    save (data) {
        return this.client.post('risk-degree', data)
    }
}
