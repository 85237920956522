import Repo from '@/api/service/repo'

export default class extends Repo {
    get () {
        return this.client.get('/disclaimer')
    }

    approve () {
        return this.client.put('/disclaimer')
    }

    getSettings () {
        return this.client.get('/disclaimer/settings')
    }

    saveSettings (disclaimer) {
        return this.client.post('/disclaimer/settings', disclaimer)
    }
}
