import { set } from 'vuex-intern'

const initialState = () => ({
    positions: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setPositions: set('positions'),
    pushToPositions (state, data) {
        state.positions.page = data.page
        data.list.forEach(e => state.positions.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('references/position').get(0).then((r) => {
            commit('setPositions', r.data)
        })
    },
    search ({ commit }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        return this.$repo('references/position').search(0, searchString).then((r) => {
            commit('setPositions', r.data)
        })
    },
    async fetchAndPush ({ commit, getters, rootGetters }) {
        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/position').get(getters.page + 1).then((r) => {
            commit('pushToPositions', r.data)
        })
    },
    async searchAndPush ({ commit, getters, rootGetters }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/position').search(getters.page + 1, searchString).then((r) => {
            commit('pushToPositions', r.data)
        })
    },
}

const getters = {
    page: state => state.positions.page,
}

export const STORE_NAME = 'positions'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
