import Repo from '@/api/service/repo'

export default class extends Repo {
    search ({ levels: l, s, p, pub, prj, apr, cmp, can, papr, apreds, aprnmd, aprd, fields: f, segmentations: m, types: t, scopes: c, mid, sid, organizations: o }) {
        const query = this.makeQuery({
            mid, sid,
            s, p, l,
            pub, prj, apr, cmp, can, papr, apreds, aprnmd, aprd,
            f, m, t, c, o,
        }, { arrayFormat: 'none' })
        return this.client.get(`bp/search?${query}`)
    }
}
