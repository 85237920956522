<template>
    <div class="ui-chip-groups">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'UiChipGroup',
}
</script>

<style lang="sass" scoped>
.ui-chip-groups
    display: flex
    flex-wrap: wrap
    margin: -4px
</style>
