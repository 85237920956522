<template>
    <nav class="ui-breadcrumbs" aria-label="Хлебные крошки">
        <ul class="ui-breadcrumb__list">
            <li
                v-for="(item, i) in breadcrumbs"
                :key="i"
                class="ui-breadcrumb__item"
            >
                <router-link
                    v-if="item.route"
                    class="ui-breadcrumb__link"
                    :to="item.route"
                >
                    {{ item.name }}
                </router-link>
                <span v-else class="ui-breadcrumb__link">{{ item.name }}</span>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'UiBreadcrumbs',
    props: {
        breadcrumbs: Array,
    },
}
</script>

<style lang="sass">
.ui-breadcrumb__list
    display: flex
    flex-wrap: wrap
    margin-top: 0
    margin-bottom: 0
    padding-left: 0
    list-style-type: none

.ui-breadcrumb__item
    position: relative
    padding-right: 14px

    &::after
        content: "/"
        position: absolute
        top: 50%
        right: 5px
        color: var(--gray-71-color)
        transform: translateY(-50%)

    &:last-child
        padding-right: 0

        &::after
            content: none

.ui-breadcrumb__link
    font-size: 13px
    color: var(--gray-71-color)
    text-decoration: none
</style>