import { set } from 'vuex-intern'

const initialState = () => ({
    responsibility: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setResponsibility: set('responsibility'),
    pushToResponsibility (state, data) {
        state.responsibility.page = data.page
        data.list.map(e => state.responsibility.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }, type) {
        return this.$repo('admin/bpResponsibility').get(type, 0).then((r) => {
            commit('setResponsibility', r.data)
        })
    },
    search ({ commit }, { type, searchString }) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        this.$repo('admin/bpResponsibility').search(type, 0, searchString).then((r) => {
            commit('setResponsibility', r.data)
        })
    },
    async fetchAndPush ({ commit, getters, rootGetters }, type) {
        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('admin/bpResponsibility').get(type, getters.page + 1).then((r) => {
            commit('pushToResponsibility', r.data)
        })
    },
    async searchAndPush ({ commit, getters, rootGetters }, { type, searchString }) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('admin/bpResponsibility').search(type, getters.page + 1, searchString).then((r) => {
            commit('pushToResponsibility', r.data)
        })
    },
    save (_, { type, data }) {
        return this.$repo('admin/bpResponsibility').save(type, data)
    },
}

const getters = {
    page: state => state.responsibility.page,
}

export const STORE_NAME = 'responsibility'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
