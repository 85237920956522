import { set } from 'vuex-intern'

const initialState = () => ({
    scopes: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setScopes: set('scopes'),
    pushToScopes (state, data) {
        state.scopes.page = data.page
        data.list.map(e => state.scopes.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('references/dictionary').getScope(0, 30).then((r) => {
            commit('setScopes', r.data)
        })
    },
    async fetchAndPush ({ commit, getters, rootGetters }) {
        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/dictionary').getScope(getters.page + 1, 30).then((r) => {
            commit('pushToScopes', r.data)
        })
    },
    search ({ commit }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        return this.$repo('references/dictionary').searchScope(0, searchString, 30).then((r) => {
            commit('setScopes', r.data)
        })
    },
    async searchAndPush ({ commit, getters, rootGetters }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/dictionary').searchScope(getters.page + 1, searchString, 30).then((r) => {
            commit('pushToScopes', r.data)
        })
    },
    save (_, data) {
        return this.$repo('references/dictionary').saveScopes(data)
    },
}

const getters = {
    page: state => state.scopes.page,
}

export const STORE_NAME = 'scopes'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
