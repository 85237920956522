import { set, without } from 'vuex-intern'

const initialState = () => ({
    loaded: false,
    data: [],
    access: {},
})

export const KB_STORE_NAME = 'kb_overview'

export const KB_STATE = {
    namespaced: true,
    state: initialState(),
    mutations: {
        setLoaded: set('loaded'),
        addItem (state, item) {
            const index = state.data.findIndex(x => x.id === item.id)
            if (index !== -1) {
                state.data.splice(index, 1)
            }
            state.data.push(item)
        },
        removeItems: without('data', 'id'),
        resetState (state) {
            Object.assign(state, initialState())
        },
        setAccess: set('access'),
    },
    actions: {
        fetch ({ commit }) {
            this.$repo('kb').getCategories().then(r => {
                const categories = r.data.list
                commit('setAccess', r.data.access)
                for (const cat of categories) {
                    cat.type = 'CATEGORY'
                    commit('addItem', cat)
                    for (const item of cat.items) {
                        item.parent = cat.id
                        commit('addItem', item)
                    }
                }
                commit('setLoaded', true)
            })
        },
        fetchChildren ({ commit }, parentId) {
            this.$repo('kb').get(parentId).then(r => {
                for (const item of r.data) {
                    if (parentId) {
                        item.parent = parentId
                    }
                    commit('addItem', item)
                }
            })
        },
        // refresh ({ state, dispatch }) {
        //     let parents = []
        //     for (let item of state.data) {
        //         if (item.parent && !parents.includes(item.parent)) {
        //             parents.push(item.parent)
        //         }
        //     }
        //     dispatch('fetch')
        //     for (let parent of parents) {
        //         dispatch('fetchChildren', parent)
        //     }
        // }
    },
}
