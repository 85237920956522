<template>
    <span
        class="ui-chip"
        :class="{
            'ui-chip_state_active': isActive,
            'ui-chip_state_disabled': disabled,
            'ui-chip_state_without-remove': withoutRemove,
        }"
        @click="!disabled && $emit('toggle')"
    >
        <button class="ui-chip__text" type="button">
            <slot />
        </button>
        <button
            v-if="!withoutRemove"
            class="ui-chip__close"
            type="button"
            @click="!disabled && $emit('close')"
        >
            <svg
                width="10"
                height="10"
                aria-hidden="true"
                focusable="false"
            >
                <use href="@/assets/images/sprites.svg#white-close"></use>
            </svg>
            <span class="visually-hidden">Убрать</span>
        </button>
    </span>
</template>

<script>
export default {
    name: 'UiChip',
    props: {
        isActive: Boolean,
        disabled: Boolean,
        withoutRemove: Boolean,
    },
}
</script>

<style scoped lang="sass">
.ui-chip
    margin: 4px
    --background-color: var(--dark-gray-30-color)
    position: relative
    display: inline-flex
    column-gap: 9px
    align-items: center
    padding: 4px 24px 4px 12px
    background-color: var(--background-color)
    border-radius: 15px

    &.ui-chip_state_without-remove
        padding: 4px 12px

.ui-chip_state_active
    --background-color: var(--blue-color)

.ui-chip__text,
.ui-chip__close
    padding: 0
    background-color: transparent
    border: none

.ui-chip__text
    font-size: 13px
    color: var(--white-color)
    text-align: left
    text-overflow: ellipsis
    overflow: hidden
    display: inline-block
    white-space: nowrap
    max-width: 550px
    height: 16px
    box-sizing: border-box

.ui-chip__close
    position: absolute
    top: 0
    bottom: 0
    right: 0
    width: 24px
    height: 24px
    display: inline-flex
    align-items: center
    justify-content: center

.ui-chip_state_disabled,
.ui-chip_state_disabled *
    cursor: not-allowed
</style>
