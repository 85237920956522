import Repo from '@/api/service/repo'

export default class extends Repo {
    fetch () {
        return this.client.get('/admin/zem')
    }

    save (settings) {
        return this.client.post('/admin/zem', settings)
    }
}
