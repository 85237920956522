import { set } from 'vuex-intern'

const initialState = () => ({
    employees: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setEmployees: set('employees'),
    pushToEmployees (state, data) {
        state.employees.page = data.page
        data.list.map(e => state.employees.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }, withFired) {
        return this.$repo('references/employee').get({ page: 0, isFired: withFired }).then((r) => {
            commit('setEmployees', r.data)
        })
    },
    search ({ commit }, { searchString, withFired }) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        return this.$repo('references/employee').search({ page: 0, search: searchString, isFired: withFired }).then((r) => {
            commit('setEmployees', r.data)
        })
    },
    async fetchAndPush ({ commit, getters, rootGetters }, withFired) {
        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/employee').get({ page: getters.page + 1, isFired: withFired }).then((r) => {
            commit('pushToEmployees', r.data)
        })
    },
    async searchAndPush ({ commit, getters, rootGetters }, { searchString, withFired }) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/employee').search({
            page: getters.page + 1,
            search: searchString,
            isFired: withFired,
        }).then((r) => {
            commit('pushToEmployees', r.data)
        })
    },
}

const getters = {
    page: state => state.employees.page,
}

export const STORE_NAME = 'employees'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
