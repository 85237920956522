import { set } from 'vuex-intern'
import { initialState } from './zem.state'
import BuilderRefId from '../helpers/builderRefId'

export default {
    setOpenedSource (state, openedSource = {}) {
        state.openedSource = openedSource
    },
    setIsCurrentUserExpert: set('isCurrentUserExpert'),
    setRequests: set('requests'),
    setMyRequests: set('myRequests'),
    setSolutions: set('solutions'),
    updateSolutions (state, { id, issue }) {
        state.solutions = state.solutions.map((item) => (item.id === id ? issue : item))
    },
    setEnablePassportSections: set('enablePassportSections'),
    setTaskCircleNum: set('taskCircleNum'),
    setTaskId: set('taskId'),
    setTaskType: set('taskType'),
    setTaskPlannedDueDate (state, date) {
        state.taskPlannedDueDate = new Date(date)
    },
    setActiveCircle (state, circle) {
        state.activeCircles = circle
    },
    setDates (state, dates) {
        if (dates.from && dates.to) {
            state.dates = dates
            return
        }

        if (dates.from) {
            state.dates.from = dates.from
        }

        if (dates.to) {
            state.dates.to = dates.to
        }
    },
    setIsLoadedDrafts (state) {
        state.isLoadedDrafts = true
    },
    setDrafts (state, drafts) {
        state.drafts = drafts.map((draft) => {
            const params = new BuilderRefId(draft.refId).getData()

            return Object.assign(draft, params)
        })
    },
    addDraft (state, draft) {
        state.drafts.push(Object.assign(draft, new BuilderRefId(draft.refId).getData()))
    },
    updateDrafts (state, updatedDrafts) {
        const updatedDraftIds = updatedDrafts.map((d) => d.id)

        state.drafts = state.drafts.map((draft) => {
            if (updatedDraftIds.includes(draft.id)) {
                const updatedDraft = updatedDrafts.find((d) => d.id === draft.id)

                return Object.assign(
                    draft,
                    updatedDraft,
                    {
                        refId: new BuilderRefId()
                            .setAllParams(updatedDraft)
                            .setTaskId(state.taskId)
                            .generate(),
                    })
            }

            return draft
        })
    },
    removeDraft (state, id) {
        state.drafts = state.drafts.filter((draft) => draft.id !== id)
    },
    bulkRemoveDrafts (state, ids) {
        state.drafts = state.drafts.filter((draft) => !ids.includes(draft.id))
    },
    bulkUpdateDraftRefIDs (state, updatedDrafts) {
        state.drafts = state.drafts.map((draft) => {
            const index = updatedDrafts.findIndex(({ draftId }) => draftId === draft.id)

            if ( index === -1) {
                return draft
            }

            return Object.assign(draft,
            {
                ...new BuilderRefId(updatedDrafts[index].refId).getData(),
                refId: updatedDrafts[index]?.refId,
            })
        })
    },
    resetState (state) {
        Object.assign(state, initialState())
    },
}
