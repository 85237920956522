<template>
    <label class="ui-switch" :class="[{ 'ui-switch--disabled': disabled, 'ui-switch--always-blue': alwaysBlue }]">
        <div v-if="$slots.left" class="ui-switch__left">
            <slot name="left" />
        </div>
        <input
            class="ui-switch__input visually-hidden"
            type="checkbox"
            :disabled="disabled"
            :checked="checked"
            @change="change"
        >
        <span class="ui-switch__box">
            <span class="ui-switch__box-slider" />
        </span>
        <div v-if="!$slots.right & $slots.default" class="ui-switch__label">
            <slot />
        </div>
        <div v-if="!$slots.default && $slots.right" class="ui-switch__right">
            <slot name="right" />
        </div>
    </label>
</template>

<script>
export default {
    name: 'UiSwitch',
    model: {
        prop: 'checked',
        event: 'change',
    },
    props: {
        checked: Boolean,
        alwaysBlue: Boolean,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        change ($event) {
            if (this.disabled) {
                return
            }
            this.$emit('change', $event.target.checked)
            this.$emit(!$event.target.checked ? 'switch-off' : 'switch-on')
        },
    },
}
</script>

<style lang="sass" scoped>
.ui-switch
    --duration: 0.3s
    display: inline-flex
    align-items: center

    &--disabled
        cursor: not-allowed !important

    &--always-blue
        .ui-switch__box
            background-color: var(--blue-color)

    &__box
        width: 52px
        height: 32px
        --background-color: var(--gray-85-color)
        display: inline-flex
        align-items: center
        padding: 2px
        background-color: var(--background-color)
        border-radius: 21px
        box-sizing: border-box
        transition-property: background-color
        transition-duration: var(--duration)

    &:not(.ui-switch--disabled):hover
        cursor: pointer

    &__input:checked ~ .ui-switch__box
        --background-color: var(--blue-color)

    &__box-slider
        --size: 28px
        --background-color: var(--white-color)
        box-sizing: border-box
        width: var(--size)
        height: var(--size)
        border-radius: 50%
        border: 0.5px solid rgba(0, 0, 0, 0.04)
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 1px 1px rgba(0, 0, 0, 0.16), 0 3px 1px rgba(0, 0, 0, 0.1)
        background-color: var(--background-color)
        transition-property: transform
        transition-duration: var(--duration)

    &__input:checked ~ .ui-switch__box .ui-switch__box-slider
        transform: translateX(calc(100% - 8px))

    &__left
        margin-right: 10px

    &__label, &__right
        margin-left: 10px
</style>
