import { set } from 'vuex-intern'

const initialState = () => ({
    categories: [],
    lockActions: false,
    editedObject: null,
    parents: [],
})

export const UPSERT_MODAL_STORE_NAME = 'upsert_modal_state'

export const UPSERT_MODAL_STATE = {
    namespaced: true,
    state: initialState(),
    mutations: {
        setCategories: set('categories'),
        setParents: set('parents'),
        setEditedObject: set('editedObject'),
        lockActions (state) {
            state.lockActions = true
        },
        unlockActions (state) {
            state.lockActions = false
        },
        resetUpsertModalState (state) {
            Object.assign(state, initialState())
        },
    },
    actions: {
        getCategories ({ commit }) {
            this.$repo('kb').get().then(r => {
                commit('setCategories', r.data)
            })
        },
    },
}
