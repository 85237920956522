import Repo from '@/api/service/repo'

export default class extends Repo {
    get ({ page = 0, isFired, my, task, businessCurator, isFavorite = null, bp }) {
        const query = this.makeQuery({
            p: page,
            f: isFired,
            my,
            t: task,
            bc: businessCurator,
            bp,
            fav: isFavorite,
        }, { arrayFormat: 'none', skipNull: true, encode: false })

        return this.client.get(`employee?${query}`)
    }

    search ({ page = 0, search, isFired, my, task, businessCurator, isFavorite = null, bp }) {
        const query = this.makeQuery({
            p: page,
            s: search,
            f: isFired,
            my,
            t: task,
            bc: businessCurator,
            bp,
            fav: isFavorite,
        }, { arrayFormat: 'none', skipNull: true, encode: false })

        return this.client.get(`employee?${query}`)
    }
}
