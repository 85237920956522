export default [
    {
        path: '/kb/overview', name: 'KBOverview',
        component: () => import('../../views/kb/PageKbOverview'),
        meta: { middleware: { section: 'knowledgeBase' } },
    },
    {
        path: '/kb/search', name: 'KBSearch',
        component: () => import('../../views/kb/PageKbSearch'),
        props: (route) => ({ query: route.query.q }),
        meta: { middleware: { section: 'knowledgeBase' } },
    },
]
