import store from '@/store'
import { ERROR_STORE_NAME } from '@/store/modules/error'
import { RequestHandler } from '@argab/request-service'
import ApiCustomHandler from '@/api/service/custom_handler'

export default class extends RequestHandler {

    _data = {}
    _response = {}

    constructor (data) {
        super()

        this._data = data

        if (data.repo?._stubData[data.repoMethod]) {
            data.stubData = data.repo._stubData[data.repoMethod]
        }
    }

    /*
    * request checking method
    * method executes within a Promise.prototype.then()
    * @params: incoming response data
    * @return Boolean on Success
    * */
    isSuccess () {
    }

    /*
    * request checking method
    * method executes within a Promise.prototype.then()
    * @params: incoming response data
    * @return Boolean on Error
    * */
    isError () {
    }

    /*
    * method executes within a Promise.prototype.then()
    * @params: incoming response data
    * @return: void
    * */
    onSuccess () {
    }

    /*
    * method executes within a Promise.prototype.then()
    * @params: incoming response data
    * @return: void
    * */
    onError () {
    }

    /*
    * method executes within a Promise.prototype.catch()
    * @params: error
    * @return: void
    * */
    onCatch (errorData) {

        console.error(errorData)

        const customHandler = ApiCustomHandler.get(this._data.repoPath, this._data.repoMethod)

        if (customHandler?.onCatch instanceof Function) {
            customHandler.onCatch(errorData)
            return
        }

        const status = this._response.status ?? errorData?.response?.status

        if ([200].includes(status)) return

        this.retry(() => new Promise((resolve) => {
                store.commit(`${ERROR_STORE_NAME}/pushError`, {
                    errorData,
                    retryAction: this._data.useRetryAction !== false ? (() => resolve(true)) : false,
                    acceptAction: () => {
                        resolve(false)
                    },
                })
            }))
    }

    /*
    * method executes within a Promise.prototype.finally()
    * @params: request data
    * @return: void
    * */
    onFinally () {
    }

    /*
    * method executes before request sent
    * @params: request data
    * @return: void
    * */
    before () {
    }

    /*
    * method executes at the start of a Promise.prototype.then()
    * @params: response
    * @return: void
    * */
    after (response) {
        this._response = response
    }

    /*
    * method executes at the start of a Promise.prototype.catch()
    * @params: error
    * @return: void
    * */
    afterCatch () {
    }

    /*
    * method executes at the start of a Promise.prototype.finally()
    * @params: request data
    * @return: void
    * */
    afterFinally () {
    }

}
