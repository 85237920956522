import { collegialMapperFromResponse } from '../mappers/collegialMapper'

const initialState = () => ({
    collegials: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setCollegials (state, collegials) {
        state.collegials = {
            ...collegials,
            list: collegialMapperFromResponse(collegials.list),
        }
    },
    pushToCollegial (state, data) {
        state.collegials.page = data.page
        collegialMapperFromResponse(data.list).map(e => state.collegials.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('references/collegial').get(0).then((r) => {
            commit('setCollegials', r.data)
        })
    },
    search ({ commit }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        this.$repo('references/collegial').search(0, searchString).then((r) => {
            commit('setCollegials', r.data)
        })
    },
    async fetchAndPush ({ commit, getters, rootGetters }) {
        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/collegial').get(getters.page + 1).then((r) => {
            commit('pushToCollegial', r.data)
        })
    },
    searchAndPush ({ commit, getters, rootGetters }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return this.$rs.useLoader(bgLoader).repo('references/collegial').search(getters.page + 1, searchString).then((r) => {
            commit('pushToCollegial', r.data)
        })
    },
    save (_, data) {
        return this.$repo('references/collegial').save(data)
    },
}

const getters = {
    page: state => state.collegials.page,
}

export const STORE_NAME = 'collegials'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
