import { set } from 'vuex-intern'

const initialState = () => ({
    segmentation: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setSegmentation: set('segmentation'),
    pushToSegmentation (state, data) {
        state.segmentation.page = data.page
        data.list.map(e => state.segmentation.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('references/segmentation').get(0).then((r) => {
            commit('setSegmentation', r.data)
        })
    },
    async fetchAndPush ({ commit, getters, rootGetters }) {
        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/segmentation').get(getters.page + 1).then((r) => {
            commit('pushToSegmentation', r.data)
        })
    },
    search ({ commit }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        return this.$repo('references/segmentation').search(0, searchString).then((r) => {
            commit('setSegmentation', r.data)
        })
    },
    async searchAndPush ({ commit, getters, rootGetters }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/segmentation').search(getters.page + 1, searchString).then((r) => {
            commit('pushToSegmentation', r.data)
        })
    },
    save (_, mass) {
        return this.$repo('references/segmentation').save(mass)
    },
}

const getters = {
    page: state => state.segmentation.page,
}

export const STORE_NAME = 'segmentation'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
