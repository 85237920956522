import { findRequestChains, findRequestChainsByRequests, getParentRequests } from './helpers'

export const findChildrenExperts = (requests, rootExperts) => {
    const experts = []

    const stack = [...rootExperts]

    while (stack.length) {
        const { expert, rootRequest } = stack.shift()

        const childrenExperts = requests
            .filter((req) => req.author.id === expert.id && req.parentRequestId === rootRequest.id)
            .flatMap((req) => req.experts.map((e) => ({ expert: e, rootRequest: req })))

        experts.push({
            ...expert,
            label: expert.shortName,
            requests: [rootRequest],
            rootRequestId: rootRequest.id,
            children: findChildrenExperts(requests, childrenExperts),
            type: 'user',
            hasAnswersFromExpert: false,
            newReplies: [],
        })
    }

    return experts
}

export const buildParentAuthors = (requests, parentRequests) => {
    const parentAuthors = {}

    const chainsRequests = findRequestChains(requests)

    parentRequests.forEach((parentReq) => {
        const author = parentReq.author

        const chainRequests = chainsRequests.find((c) => c.authorId === author.id)?.chain || []

        if (parentAuthors[author.id]) {
            parentAuthors[author.id].requests.push(...chainRequests)
        } else {
            parentAuthors[author.id] = {
                ...author,
                label: author.shortName,
                requests: chainRequests,
                children: [],
                type: 'user',
            }
        }
    })

    return Object.values(parentAuthors)
}

export const buildListExperts = (requests, requestsForMe = []) => {
    const requestsGroups = {}

    requestsForMe.forEach((requestForMe) => {
        const { author } = requestForMe

        requestsGroups[requestForMe.id] = {
            requestId: requestForMe.id,
            requestSourceId: requestForMe.author.id,
            list: [],
            parentItems: buildParentAuthors(requests, getParentRequests(requests)),
        }

        const expertRequests = requests.filter((req) => req.parentRequestId === requestForMe.id)

        requestsGroups[requestForMe.id].list.push({
            ...author,
            label: author.shortName,
            requests: [requestForMe, ...expertRequests],
            rootRequestId: requestForMe.id,
            hasAnswersFromExpert: !!requestForMe.replies.filter((r) => r.reply).length,
            children: [],
            type: 'user',
            newReplies: [],
        })

        if (expertRequests.length) {
            const result = {}

            for (const req of expertRequests) {
                result[`user_${req.author.id}`] ||= {
                    ...req.author,
                    label: req.author.shortName,
                    requests: [req],
                    rootRequestId: requestForMe.id,
                    children: [],
                    type: "user",
                    newReplies: [],
                }

                const experts = findChildrenExperts(requests, req.experts.map((e) => ({ expert: e, rootRequest: req })))

                experts.forEach((e) => {
                    if (!result[`user_${e.id}`]) {
                        result[`user_${e.id}`] = e
                    } else {
                        result[`user_${e.id}`] = {
                            ...result[`user_${e.id}`],
                            requests: [...result[`user_${e.id}`].requests, ...e.requests],
                        }
                    }
                })
            }

            requestsGroups[requestForMe.id].list =
                requestsGroups[requestForMe.id].list.concat(Object.values(result))
        }
    })

    return Object.values(requestsGroups)
}

export const buildListExpertsApprover = (requests, myRequests) => {
    if (!myRequests.length) return []

    const approver = myRequests[0].author

    const approverChildrenExperts = getApproverChildrenExperts(requests, myRequests)

    return [{
        requestId: null,
        requestSourceId: approver.id,
        list: [{
            ...approver,
            label: approver.shortName,
            requests: myRequests,
            rootRequestId: null,
            children: [],
            type: 'user',
            hasAnswersFromExpert: false,
            newReplies: myRequests
                .flatMap((r) => r.replies)
                .filter((r) => r.reply && !r.reply.viewTime)
                .map((r) => r.reply.id),
        }].concat(approverChildrenExperts),
        parentItems: [],
    }]
}

const getApproverChildrenExperts = (requests, approverRequests) => {
    const approverExperts = Object.values(approverRequests.reduce((experts, req) => {
        req.experts.forEach((expert) => {
            if (experts[expert.id]) {
                experts[expert.id].requests.push(req)
            } else {
                experts[expert.id] = {
                    ...expert,
                    label: expert.shortName,
                    requests: [req],
                    rootRequestId: null,
                    children: [],
                    type: 'user',
                    hasAnswersFromExpert: false,
                    newReplies: [],
                }
            }
        })

        return experts
    }, {}))

    return approverExperts.map((e) => Object.assign(e, {
        children: getGroupChildrenExperts(requests, e),
    }))
}

const getGroupChildrenExperts = (requests, targetExpert) =>
    Object.values((targetExpert.requests || []).reduce((experts, parentRequest) => {
        const childrenRequests = requests.filter((r) =>
            r.parentRequestId === parentRequest.id &&
            targetExpert.id === r.author.id
        )

        childrenRequests.forEach((req) => {
            req.experts.forEach((expert) => {
                if (experts[expert.id]) {
                    experts[expert.id].requests.push(req)
                } else {
                    experts[expert.id] = {
                        ...expert,
                        label: expert.shortName,
                        requests: [req],
                        rootRequestId: parentRequest.id,
                        children: [],
                        type: 'user',
                        hasAnswersFromExpert: false,
                        newReplies: [],
                    }
                }
            })
        })

        return experts
    }, {})).map((e) => Object.assign(e, { children: getGroupChildrenExperts(requests, e) }))

export const buildUniqParentExperts = (requests, requestsForMe) => {
    if (!requestsForMe.length) return []

    return Object.values(requestsForMe
        .reduce((acc, req) => {
            if (!acc[req.author.id]) {
                acc[req.author.id] = {
                    ...req.author,
                    requests: [],
                    newReplies: [],
                    newRequests: [],
                }
            }

            acc[req.author.id].requests.push(req)

            const newReplies = findRequestChainsByRequests(requests, [req], 'delegated')?.[req]
                ?.flatMap(({ replies }) => replies)
                ?.filter((r) => r.reply && !r.reply.viewTime)
                ?.map((r) => r.reply.id) || []

            acc[req.author.id].newReplies.push(...newReplies)

            if (!req.viewTime) {
                acc[req.author.id].newRequests.push(req.id)
            }

            return acc
        }, {}))
}
