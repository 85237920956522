import Repo from '@/api/service/repo'

export default class extends Repo {
    get (id, passportVersion) {
        return this.client.get(`bp/passport?id=${id}` + (passportVersion ? `&r=${passportVersion}` : ''))
    }

    getForEdit (id) {
        return this.client.put(`bp/passport?id=${id}`)
    }

    createStage (data) {
        return this.client.post('bp/simple', data)
    }

    save (id, bp) {
        return this.client.post(`bp/passport?id=${id}`, bp)
    }

    savePrintParams (id, approvalType, printParams) {
        return this.client.put(`bp/swimlane/print-params/${approvalType}?id=${id}`, printParams)
    }

    autoSave (id, bp) {
        return this.client.post(`bp/passport?id=${id}&a=true`, bp)
    }

    validation (id, bp) {
        return this.client.post(`bp/swimlane/validation?id=${id}`, bp)
    }

    getPdf (id, statusCode) {
        return this.client.config({ timeout: 600000 }).get(`bp/swimlane/pdf/link?id=${id}&p=${statusCode}`)
    }

    postPdf (id) {
        return this.client.config({ timeout: 600000 }).post(`bp/swimlane/pdf/link?id=${id}`)
    }
}
