import { set } from 'vuex-intern'
import modules from './modules'

export const ADMIN_STORE_NAME = 'admin'

export const ADMIN_STATE = {
    namespaced: true,
    state: {
        searchStringGlobal: null,
        access: {},
        actions: [],
        bgLoader: false,
    },
    getters: {
        bgLoader: state => state.bgLoader,
    },
    mutations: {
        setAccess: set('access'),
        setActions: set('actions'),
        setSearchStringGlobal (state, data) {
            state.searchStringGlobal = data
        },
        setBgLoader (state, value = false) {
            state.bgLoader = Boolean(value) !== true
        },
    },
    actions: {
        getAccess ({ commit }) {
            return this.$repo('admin/overview').get().then((r) => {
                commit('setAccess', r.data)
            })
        },
        getActions ({ commit }) {
            return this.$repo('admin/overview').getActions().then((r) => {
                commit('setActions', r.data)
            })
        },
        saveRequiredFields (_, data) {
            return this.$repo('admin/approval').save(data)
        },
        saveBPOffers (_, data) {
            return this.$repo('bp/change_suggestion').save(data)
        },
    },
    modules,
}
