import { set } from 'vuex-intern'

const initialState = () => ({
    system: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setSystem: set('system'),
    pushToSystem (state, data) {
        state.system.page = data.page
        data.list.map(e => state.system.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('admin/system').get(0).then((r) => {
            commit('setSystem', r.data)
        })
    },
    search ({ commit }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        this.$repo('admin/system').search(0, searchString).then((r) => {
            commit('setSystem', r.data)
        })
    },
    async fetchAndPush ({ commit, getters, rootGetters }) {
        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('admin/system').get(getters.page + 1).then((r) => {
            commit('pushToSystem', r.data)
        })
    },
    searchAndPush ({ commit, getters, rootGetters }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return this.$rs.useLoader(bgLoader).repo('admin/system').search(getters.page + 1, searchString).then((r) => {
            commit('pushToSystem', r.data)
        })
    },
}

const getters = {
    page: state => state.system.page,
}

export const STORE_NAME = 'system'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
