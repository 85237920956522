console.log(`Version: ${process.env.PACKAGE_VERSION}`)

import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App'
import './plugins'
import './components'
import './helpers'

store.dispatch('getHeader').then(() => {
    new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount('#app')
})
