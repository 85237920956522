import { render, staticRenderFns } from "./UiCheckboxGroup.vue?vue&type=template&id=2357254a&scoped=true"
import script from "./UiCheckboxGroup.vue?vue&type=script&lang=js"
export * from "./UiCheckboxGroup.vue?vue&type=script&lang=js"
import style0 from "./UiCheckboxGroup.vue?vue&type=style&index=0&id=2357254a&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2357254a",
  null
  
)

export default component.exports