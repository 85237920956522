import Repo from '@/api/service/repo'

export default class extends Repo {
    get (id) {
        return this.client.get(`bp/cancellation?id=${id}&tz=${this.tz}`)
    }

    put (id) {
        return this.client.put(`bp/cancellation?id=${id}&tz=${this.tz}`)
    }
}
