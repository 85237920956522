<template>
    <div class="ui-radio-group" :class="{ 'ui-radio-group_column': column }">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'UiRadioGroup',
    model: {
        event: 'change',
    },
    props: {
        column: {
            type: Boolean,
            default: false,
        },
        name: String,
        trackBy: String,
        value: {
            default: null,
        },
    },
    provide () {
        return { radioGroup: this }
    },
    methods: {
        change (value) {
            this.$emit('change', value)
        },
    },
}
</script>

<style scoped lang="sass">
.ui-radio-group
    display: flex

    & /deep/ .ui-radio:not(:last-child)
        margin-right: 24px

.ui-radio-group_column
    flex-direction: column

    & /deep/ .ui-radio:not(:last-child)
        margin-right: initial
        margin-bottom: 5px
</style>