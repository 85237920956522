import Repo from '@/api/service/repo'

export default class extends Repo {
    getView (id, isFamiliarization) {
        const query = this.makeQuery({ id, f: isFamiliarization, tz: this.tz }, { skipNull: true, encode: false })

        return this.client.get(`bp/passport?${query}`)
    }

    getLock (id) {
        return this.client.put(`bp/passport?id=${id}&c=false`)
    }

    unlock (id) {
        return this.client.put(`bp/passport?id=${id}&c=true`)
    }

    save (id, data) {
        return this.client.post(`bp/passport?id=${id}`, data)
    }

    autoSave (id, data) {
        return this.client.post(`bp/passport?id=${id}&a=true`, data)
    }

    publicate (id, data) {
        return this.client.post(`bp/passport?id=${id}&p=true`, data)
    }

    rapidPublicate (id, s = false) {
        return this.client
            .config({ timeout: 180000 })
            .post(`bp/passport/publication?id=${id}&s=${s}&tz=${this.tz}`)
    }

    actualization (id) {
        return this.client.post(`bp/passport/actualization?id=${id}&tz=${this.tz}`)
    }

    createBp (data) {
        return this.client.post('bp/simple', data)
    }

    setCode (id, code) {
        return this.client.put(`bp/code?id=${id}&code=${code}&tz=${this.tz}`)
    }

    sharefamiliarize (id, data) {
        return this.client.post(`bp/passport/sharefamiliarize?id=${id}&tz=${this.tz}`, data)
    }

    confirmationApplicationEnsuring (id) {
        return this.client.post(`bp/passport/confirmation/applicationEnsuring?id=${id}&tz=${this.tz}`)
    }

    confirmationApplicationBp (id) {
        return this.client.post(`bp/passport/confirmation/applicationBp?id=${id}&tz=${this.tz}`)
    }

    confirmationFamiliarization (id) {
        return this.client.post(`bp/passport/confirmation/familiarization?id=${id}&tz=${this.tz}`)
    }

    trackingViewPublished (id) {
        return this.client.post(`bp/passport/tracking/view/published?id=${id}&tz=${this.tz}`)
    }

    getKpiUrl (id, edited) {
        return this.client.post(`bp/kpi?id=${id}&tz=${this.tz}&e=${edited}`)
    }

    /**
     * Обновление документов без создания новой ревизии/версии
     * @param {string|number} bpID - ID бизнес-процесса
     * @param {Object[]} docs - список документов
     * @param {string} [docs[].type=NORMAL] - тип документа NORMAL
     * @param {string} docs[].name - название документа
     * @param {string} docs[].url - url документа
     * @param {string} docs[].canEdit - возможность редактирования документа
     * @param {string} docs[].partOfPassport - является ли частью паспорта документа
     * @return Promise
     */
    updateDocs (bpID, docs) {
        return this.client.put(`bp/docs?id=${bpID}&tz=${this.tz}`, { docs })
    }
}
