import Repo from '@/api/service/repo'
import utils from '@/utils'

export default class extends Repo {
    upload (usage, fileName, file, replacedFileId) {
        const params = {
            usage,
            fileName,
            id: replacedFileId,
        }

        return this.client.stream().post(
            `storage?${utils.uriEncode(params)}`,
            file
        )
    }

    getInfo (url) {
        return new Promise((resolve, reject) => {
            this.client.head(url).then(r => {
                resolve(r.headers)
            }).catch((e) => {
                reject(e)
            })
        })
    }

    uploadSwimlaneImage (bpId, file, ext = 'png') {
        return this.client.stream().post(
            `storage?usage=SL_IMAGE&nid=${bpId}&fileName=swimlane.${ext}`,
            file
        )
    }
}
