import Vue from 'vue'
import Vuex from 'vuex'
import { set } from 'vuex-intern'
import { BP_STORE_NAME, BP_STATE } from '@/views/bp_overview/overview.store'
import { ACCOUNT_STORE_NAME, ACCOUNT_STATE } from './modules/account'
import { NOTIFICATION_STORE_NAME, NOTIFICATION_STATE } from '@/views/notifications/notification.store'
import { ADMIN_STORE_NAME, ADMIN_STATE } from './modules/admin'
import { ERROR_STORE_NAME, ERROR_STATE } from './modules/error'
import { BP_SEARCH_STORE_NAME, BP_SEARCH_STATE } from '@/views/bp_overview/search.store'
import { KB_STORE_NAME, KB_STATE } from '@/views/kb/kb.store'
import { UPSERT_MODAL_STORE_NAME, UPSERT_MODAL_STATE } from '@/views/kb/upsert_modal.store'
import { KB_SEARCH_STORE_NAME, KB_SEARCH_STATE } from '@/views/kb/search.store'
import { BP_CROSS_CHAIN_STORE_NAME, BP_CROSS_CHAIN_STATE } from '@/views/bp_cross_chain/bp_cross_chain.store'
import { ZEM_STORE_NAME, ZEM_STATE } from '@/views/bp_passport/components/zem_chat/zem.store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isHeaderLoaded: false,
    header: {},
  },
  mutations: {
    setHeader: set('header'),
    setHeaderLoaded: set('isHeaderLoaded'),
  },
  actions: {
    getHeader ({ commit }) {
      return new Promise((resolve) => {
        this.$repo('header').get().then((r) => {
          commit('setHeader', r.data)
          commit('setHeaderLoaded', true)
          commit(`${ACCOUNT_STORE_NAME}/setUser`, r.data.user)
          commit(`${ACCOUNT_STORE_NAME}/setHints`, r.data.hints)
          commit(`${ACCOUNT_STORE_NAME}/loadedUser`, true)

          return resolve(r.data)
        })
      })
    },
  },
  getters: {
    isHeaderLoaded: (state) => state.isHeaderLoaded,
    header: (state) => state.header || {},
    headerMenu: (state) => state.header?.menus || {},
    educationalMaterialsLink: (state) => state.header.educationalMaterialsLink,
    isShowDisclaimer: (state) => state.header.disclaimer || false,
    contactHotline: (state) => state.header.contacts?.find(x => x.code === 'HOTLINE') || [],
    contactTechLine: (state) => state.header.contacts?.find(x => x.code === 'TECH_SUPPORT') || [],
  },
  modules: {
    [ERROR_STORE_NAME]: ERROR_STATE,
    [BP_STORE_NAME]: BP_STATE,
    [ACCOUNT_STORE_NAME]: ACCOUNT_STATE,
    [ADMIN_STORE_NAME]: ADMIN_STATE,
    [BP_SEARCH_STORE_NAME]: BP_SEARCH_STATE,
    [KB_STORE_NAME]: KB_STATE,
    [UPSERT_MODAL_STORE_NAME]: UPSERT_MODAL_STATE,
    [KB_SEARCH_STORE_NAME]: KB_SEARCH_STATE,
    [NOTIFICATION_STORE_NAME]: NOTIFICATION_STATE,
    [BP_CROSS_CHAIN_STORE_NAME]: BP_CROSS_CHAIN_STATE,
    [ZEM_STORE_NAME]: ZEM_STATE,
  },
})
