import Repo from '@/api/service/repo'

export default class extends Repo {
    get (p = 0, n = 20) {
        return this.client.get(`segmentation?p=${p}&n=${n}`)
    }

    search (p, s, n = 20) {
        return this.client.get(`segmentation?s=${s}&p=${p}&n=${n}`)
    }

    save (data) {
        return this.client.post('segmentation', data)
    }
}
