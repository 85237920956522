import Repo from '@/api/service/repo'

export default class extends Repo {
    get () {
        return this.client.get(`admin/notification?tz=${this.tz}`)
    }
    post (data) {
        return this.client.post(`admin/notification?tz=${this.tz}`, data)
    }
    load (c) {
        return this.client.get(`admin/notification/text?c=${c}&tz=${this.tz}`)
    }
    save (c, data) {
        return this.client.post(`admin/notification/text?c=${c}&tz=${this.tz}`, data)
    }
}
