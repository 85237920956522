const mapTypes = {
    groups: 'GROUPS',
    employees: 'EMPLOYEE',
    positions: 'POSITION',
}

export const collegialMapperFromResponse = (list) =>
    list.map((item) => {
        const convertedItem = item

        if (convertedItem.chairmen) {
            const chairmen = convertedItem.chairmen
            convertedItem.chairmen = Object.keys(chairmen).reduce((acc, type) => (
                acc.concat(chairmen[type].map((ch) => ({
                    ...ch,
                    type: mapTypes[type],
                })
            ))), [])
        }

        return convertedItem
    })

