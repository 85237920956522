import { BP_STATE } from '../views/bp_overview/overview.store'

export default {
    bpOverviewRedirect: route => {
        const hasActiveBp = route.query.activeBP && route.query.activeBP !== '0'

        if (BP_STATE.state.bpViewList) {
            if (hasActiveBp) {
                return { name: 'BPList', query: { activeBP: route.query.activeBP } }
            }

            return { name: 'BPList', query: {} }
        }

        if (hasActiveBp) {
            return { name: 'BPSubTiles', params: { parentId: route.query.activeBP }, query: {} }
        }

        return { name: 'BPTiles', query: {} }
    },
}
