import dayjs from 'dayjs'

import {
    getDatesByCircles,
    getCircleByDates,
    getMyRequests,
} from '@/views/bp_passport/components/zem_chat/helpers/helpers'

import zemMapper from '../helpers/ZemMapper'

import BuilderRefId from '../helpers/builderRefId'

export default {
    setDatesByCircles ({ commit, state, getters }, circles) {
        commit('setActiveCircle', circles)
        commit('setDates', getDatesByCircles(circles, [...state.myRequests, ...getters.requestsForMe]))

        if (circles.includes(state.taskCircleNum)) {
            commit('setDates', { to: dayjs().endOf('d').toDate() })
        }
    },
    setCirclesByDates ({ commit, state, getters }, dates) {
        commit('setActiveCircle', getCircleByDates(dates, [...state.myRequests, ...getters.requestsForMe]))
        commit('setDates', dates)
    },
    async markUnreadRequests ({ commit, state }, { taskId, requestIds }) {
        await Promise.all(requestIds.map((id) => this.$rs.bg().repo('bp/chat').read(taskId, id)))

        const requests = state.requests.map(
            (req) => Object.assign(req, {
                viewTime: req.viewTime || (requestIds.includes(req.id) ? new Date() : null),
            })
        )

        commit('setRequests', requests)
    },
    async markUnreadReplies ({ commit, state }, { taskId, replyIds }) {
        await Promise.all(replyIds.map((replyId) => this.$rs.bg().repo('bp/chat').read(taskId, replyId)))

        const requests = state.myRequests.map((z) => ({
            ...z,
            replies: z.replies.map(({ expert, reply }) => ({
                expert,
                reply: reply ? Object.assign(reply, {
                    viewTime: reply.viewTime || (replyIds.includes(reply.id) ? new Date() : null),
                }) : null,
            })),
        }))

        commit('setMyRequests', requests)
    },
    fetchRequests ({ state, commit }, taskId) {
        return this.$repo('bp/zem').fetch(taskId).then((r) => {
            const myRequests = getMyRequests(r.data.zems)

            commit('setRequests', zemMapper(r.data.zems))

            commit('setMyRequests', zemMapper(myRequests))

            commit('setActiveCircle', [state.taskCircleNum])
        })
    },
    updateRequests ({ state, commit, getters }, taskId) {
        this.$rs.bg().repo('bp/zem').fetch(taskId).then((r) => {
            const myRequests = getMyRequests(r.data.zems)

            commit('setRequests', zemMapper(r.data.zems))

            commit('setMyRequests', zemMapper(myRequests))

            if (!state.activeCircles.length) {
                if (getters.circleNums.length) {
                    commit('setActiveCircle', [getters.circleNums[0]])
                } else {
                    commit('setActiveCircle', [state.taskCircleNum])
                }
            }
        })
    },
    fetchSolutions ({ commit }, { taskId, userId }) {
        this.$rs.bg().repo('bp/chat').getChats(taskId, { types: ['ALL'] }).then((r) => {
            const solutions = r.data.conversations
                .flatMap((c) => c.comments)
                .filter((c) => c.commentType === 'ISSUE' && c.author.id === userId)

            commit('setSolutions', solutions)
        })
    },

    // drafts
    fetchDrafts ({ commit }, taskId) {
        this.$rs.bg().repo('bp/chat').drafts(taskId).then((r) => {
            commit('setDrafts', r.data.drafts)
            commit('setIsLoadedDrafts')
        })
    },
    updateDraftContent ({ commit }, draft) {
        return this.$rs.bg().repo('bp/chat').updateDraftContent(draft.id, draft.content).then(() => {
            commit('updateDrafts', [draft])
        })
    },
    createDraft ({ state, commit }, draft) {
        const refId = new BuilderRefId()
            .setTaskId(state.taskId)
            .setRequestId(draft.requestId)
            .setSection(draft.section)
            .setSectionItem(draft.sectionItem)
            .setSelected(draft.isSelected)
            .setCopiedReplyId(draft.copiedReplyId)
            .setOrder(draft.order)
            .generate()

        return this.$rs.bg().repo('bp/chat').createDraft(state.taskId, refId, draft.content).then((r) => {
            const createdDraft = {
                ...draft,
                refId,
                id: r.data.id,
            }

            commit('addDraft', createdDraft)

            return createdDraft
        })
    },
    removeDraft ({ commit }, id) {
        return this.$rs.bg().repo('bp/chat').removeDraft(id).then(() => {
            commit('removeDraft', id)
        })
    },
    bulkUpdateDraftRefIDs ({ state, commit }, drafts) {
        const updatedDrafts = drafts.map((draft) => Object.assign(draft, {
            refId: new BuilderRefId()
                .setTaskId(state.taskId)
                .setRequestId(draft.requestId)
                .setSection(draft.section)
                .setSectionItem(draft.sectionItem)
                .setSelected(draft.isSelected)
                .setCopiedReplyId(draft.copiedReplyId)
                .setOrder(draft.order)
                .generate(),
        })).map((d) => ({ draftId: d.id, refId: d.refId }))

        return this.$rs.bg().repo('bp/chat').bulkUpdateDraftRefIDs(updatedDrafts).then(() => {
            commit('bulkUpdateDraftRefIDs', updatedDrafts)
        })
    },
    bulkRemoveDrafts ({ commit }, ids) {
        return this.$rs.bg().repo('bp/chat').bulkRemoveDrafts(ids).then(() => {
            commit('bulkRemoveDrafts', ids)
        })
    },
}
