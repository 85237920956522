import Repo from '@/api/service/repo'

export default class extends Repo {
    get (type) {
        return this.client.get(`contact?t=${type || ''}`)
    }
    post (data) {
        return this.client.post('contact', data)
    }
}
