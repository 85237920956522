import Repo from '@/api/service/repo'
import { DEFAULT_PAGE } from '@/api/constants/pagination'

export default class extends Repo {
    get (page, limit = DEFAULT_PAGE, isFavorite = null) {
        const query = this.makeQuery({
            p: page,
            n: limit,
            fav: isFavorite,
        }, { arrayFormat: 'none', skipNull: true, encode: false })

        return this.client.get(`organization?${query}`)
    }

    search (page, search, limit = DEFAULT_PAGE, isFavorite = null) {
        const query = this.makeQuery({
            p: page,
            s: search,
            n: limit,
            fav: isFavorite,
        }, { arrayFormat: 'none', skipNull: true, encode: false })

        return this.client.get(`organization?${query}`)
    }

    import (file) {
        return this.client.config({ timeout: 120000 }).stream().post(`organization/import?tz=${this.tz}`, file)
    }
}
