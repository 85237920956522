import Repo from '@/api/service/repo'

export default class extends Repo {
    get () {
        return this.client.get(`admin/bp/change/suggestion/?tz=${this.tz}`)
    }
    save (data) {
        return this.client.post('admin/bp/change/suggestion', data)
    }
}
