import Repo from '@/api/service/repo'

export default class extends Repo {
    getAttachedFiles (id, isNotPublished = true) {
        return this.client.get(`bp/eds?id=${id}&p=${isNotPublished}&tz=${this.tz}`)
    }

    attachFiles (id, docs, isNotPublished = true) {
        return this.client.post(`bp/eds?id=${id}&p=${isNotPublished}&tz=${this.tz}`, { docs })
    }
}
