<template>
    <section class="not-found">
        <h2 class="visually-hidden">
            Ошибка поиска
        </h2>
        <span class="not-found__text">
            <slot>Совпадений не найдено</slot>
        </span>
        <img
            class="not-found__image"
            src="~@/assets/images/not-found.svg"
            width="486"
            height="358"
            alt="Совпадений не найдено"
        >
    </section>
</template>

<script>
export default {
    name: 'UiNotFound',
}
</script>

<style lang="sass" scoped>
.not-found
    display: flex
    flex-direction: column
    align-items: center
    row-gap: 44px
    margin-right: auto
    margin-left: auto

.not-found__text
    font-weight: 700
    color: var(--dark-gray-color)

.not-found__image
    max-width: 486px
    max-height: 358px
</style>
