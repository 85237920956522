import Repo from '@/api/service/repo'

export default class extends Repo {
    get (lvl, page = 0, size = 100) {
        return this.client.get(`/agreement/route?1=${lvl}&p=${page}&n=${size}`)
    }

    getOptions (lvl, page = 0, size = 100) {
        return this.client.get(`/agreement/route/options?level=${lvl}&p=${page}&n=${size}`)
    }

    searchOptions (lvl, s, page = 0, size = 100) {
        return this.client.get(`/agreement/route/options?level=${lvl}&s=${s}&p=${page}&n=${size}`)
    }

    getFinalStage () {
        return this.client.get('/agreement/route/finalStage')
    }
    getPassportApprovalStage () {
        return this.client.get('/agreement/route/passportApprovalStage')
    }

    getById (id) {
        return this.client.get(`/agreement/route/${id}`)
    }

    post (data) {
        return this.client.post('/agreement/route', data)
    }

    put (id, data) {
        return this.client.put(`/agreement/route/${id}`, data)
    }

    delete (id) {
        return this.client.delete(`/agreement/route/${id}`)
    }

    log (id) {
        return this.client.get(`/agreement/log?id=${id}`)
    }

    approval (id) {
        return this.client.get(`/agreement/log/approval?id=${id}`)
    }

    getComments (id) {
        return this.client.get(`/agreement/log/comment?id=${id}`)
    }

    getRoles (a = true) {
        return this.client.get(`/agreement/role?a=${a}`)
    }
}
