import Repo from '@/api/service/repo'

export default class extends Repo {
    get (p) {
        if (!p) p = 0
        return this.client.get(`system?p=${p}`)
    }
    search (p, s) {
        return this.client.get(`system?p=${p}&s=${s}`)
    }
}
