import { set } from 'vuex-intern'

export const BP_STORE_NAME = 'bp'

export const BP_STATE = {
    namespaced: true,
    state: {
        bpViewList: false,
        activeBP: 0,
    },
    mutations: {
        setbpViewList: set('bpViewList'),
        setActiveBP: set('activeBP'),
    },
}
