import Repo from '@/api/service/repo'

export default class extends Repo {
    get (p, params = { o: undefined, d: undefined }) {
        const query = this.makeQuery({ p, m: 'ALL', o: params?.o, d: params?.d, tz: this.tz })
        return this.client.get(`deputy?${query}`)
    }

    getDeputy (m = 'EMPLOYEE', params = { o: undefined, d: undefined }) {
        const query = this.makeQuery({ m, o: params?.o, d: params?.d, tz: this.tz })
        return this.client.get(`deputy?${query}`)
    }

    /*
    * o - Критерий сортировки данных.
    *     s - по дате начала периода замещения
    *     e - по имени замещаемого работника
    *     d - по имени замещающего работника
    *     m - по имени работника, создавшего замещение
    * d - Направление сортировки данных. По умолчанию используется сортировка по убыванию
    * m - Режим поиска замещений:
    *     EMPLOYEE - активные замещения в которых текущий пользователь - замещаемый (владелец замещений)
    *     DEPUTY - активные замещения в которых текущий пользователь - заместитель (редактирование/удаление недоступно)
    *     ALL - все активные замещения по всем пользователям - для данного режима требуется право администрирования замещений
    * */
    search (p, { s, m: m = 'ALL', o, d }) {
        const query = this.makeQuery({ p, s, m, o, d, tz: this.tz })
        return this.client.get(`deputy?${query}`)
    }

    delete (id) {
        return this.client.delete(`deputy?id=${id}&tz=${this.tz}`)
    }

    save (param) {
        return this.client.post(`deputy?tz=${this.tz}`, param)
    }
}
