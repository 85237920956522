import Repo from '@/api/service/repo'

export default class extends Repo {
    getByParent (parentId) {
        return this.client.get(`bp?pid=${parentId}`)
    }

    getByGroup (groupId) {
        return this.client.get(`bp?gid=${groupId}`)
    }

    save (data) {
        return this.client.post('bp', data)
    }

    rename (id, name) {
        const query = this.makeQuery({ id, name }, { arrayFormat: 'none' })
        return this.client.put(`bp/simple?${query}`)
    }

    create (parentId, text) {
        return this.client.post('bp/simple', {
            parent: { id: parentId },
            name: text,
        })
    }
}
