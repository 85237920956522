import Vue from 'vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

Vue.use(Loading, {
    color: '#207DD3',
    container: null,
})

import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)

import UUID from 'vue-uuid'

Vue.use(UUID)

import vmodal from 'vue-js-modal'

Vue.use(vmodal)

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'

Vue.use(vueFilterPrettyBytes)

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
Vue.component('multiselect', Multiselect) // eslint-disable-line vue/multi-word-component-names

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

Vue.component('vue-json-pretty', VueJsonPretty)

import TextareaAutosize from 'vue-textarea-autosize'

Vue.use(TextareaAutosize)

import 'tippy.js/themes/light.css'
import VueTippy, { TippyComponent } from 'vue-tippy'

Vue.use(VueTippy, { theme: 'light', arrow: true })
Vue.component('Tippy', TippyComponent) // eslint-disable-line vue/multi-word-component-names
