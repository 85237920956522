import Repo from '@/api/service/repo'

export default class extends Repo {
    get (activeBp) {
        if (activeBp) {
            return this.client.get(`bp/tree?pid=${activeBp}&ag=true`)
        }
        return this.client.get('bp/list?ag=true')
    }

    getChildren (id) {
        return this.client.get(`bp/list?pid=${id}`)
    }

    move (data) {
        return this.client.post('bp/move', data)
    }

    getMoveBp (id) {
        return this.client.get(`bp/move?id=${id}`)
    }
}
