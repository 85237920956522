import { RequestRepository } from '@argab/request-service'

export default class extends RequestRepository {
    client
    makeQuery
    _stubData = {}

    tz = -(new Date().getTimezoneOffset())

    constructor () {
        super()
        this.makeQuery = require('query-string').stringify
    }

    setStubs (data) {
        data instanceof Object && Object.assign(this._stubData, data)
    }

}
