export default [
    {
        path: '/admin', name: 'AdminOverview',
        component: () => import('../../views/admin_overview/PageOverview'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/notifications', name: 'AdminNotifications',
        component: () => import('../../views/admin_notifications/PageNotifications'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/tech-support', name: 'AdminTechSupport',
        component: () => import('../../views/admin_tech_support/TechSupport'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/tech-support/editor', name: 'AdminTechSupportEditor',
        component: () => import('../../views/admin_tech_support/TechSupportEditor'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/role-matrix', name: 'AdminRoleMatrix',
        component: () => import('../../views/admin_role_matrix/RoleMatrix'),
        props: (route) => ({ lastChangedField: route.params.lastChangedField }),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/role-matrix/editor', name: 'AdminRoleMatrixEditor',
        component: () => import('../../views/admin_role_matrix/RoleMatrixEditor'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/bp-sections', name: 'AdminBpSections',
        component: () => import('../../views/admin_bp_sections/PageBpSections'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/bp-sections/editor', name: 'AdminBpSectionsEditor',
        component: () => import('../../views/admin_bp_sections/PageBpSectionsEditor'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/user-bps', name: 'BpUserGroups',
        component: () => import('../../views/admin_bp_user_groups/PageBpUserGroups'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/user-bps/editor', name: 'BpUserGroupsEditor',
        component: () => import('../../views/admin_bp_user_groups/PageBpUserGroupsEditor'),
        meta: { middleware: { section: 'admin' } },
    },

    // ADMIN / REFERENCES
    {
        path: '/admin/references', name: 'AdminReferences',
        component: () => import('../../views/admin_references/PageOverview'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/positions', name: 'AdminReferencesPositions',
        component: () => import('../../views/admin_references/PagePositions'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/employees', name: 'AdminReferencesEmployees',
        component: () => import('../../views/admin_references/PageEmployees'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/systems', name: 'AdminReferencesSystems',
        component: () => import('../../views/admin_references/PageSystems'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/subdivisions', name: 'AdminReferencesSubdivisions',
        component: () => import('../../views/admin_references/PageSubdivisions'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/orgs', name: 'AdminReferencesOrgs',
        component: () => import('../../views/admin_references/PageOrgs'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/collegials', name: 'AdminReferencesCollegials',
        component: () => import('../../views/admin_references/PageCollegials'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/collegials/editor', name: 'AdminReferencesCollegialsEditor',
        component: () => import('../../views/admin_references/PageCollegialsEditor'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/groups', name: 'AdminReferencesGroups',
        component: () => import('../../views/admin_references/PageGroups'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/groups/editor', name: 'AdminReferencesGroupsEditor',
        component: () => import('../../views/admin_references/PageGroupsEditor'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/risks', name: 'AdminReferencesRisks',
        component: () => import('../../views/admin_references/PageRisks'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/risks/editor', name: 'AdminReferencesRisksEditor',
        component: () => import('../../views/admin_references/PageRisksEditor'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/segment', name: 'AdminReferencesSegment',
        component: () => import('../../views/admin_references/PageSegmentations'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/segment/editor', name: 'AdminReferencesSegmentEditor',
        component: () => import('../../views/admin_references/PageSegmentationsEditor'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/types', name: 'AdminReferencesTypes',
        component: () => import('../../views/admin_references/PageTypes'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/types/editor', name: 'AdminReferencesTypesEditor',
        component: () => import('../../views/admin_references/PageTypesEditor'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/bp-responsibility/1/', name: 'BpResponsibility1',
        component: () => import('../../views/admin_references/PageBpResponsibility'),
        props: { type: 1 },
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/bp-responsibility/2/', name: 'BpResponsibility2',
        component: () => import('../../views/admin_references/PageBpResponsibility'),
        props: { type: 2 },
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/bp-responsibility/1/editor', name: 'BpResponsibilityEditor1',
        component: () => import('../../views/admin_references/PageBpResponsibilityEditor'),
        props: { type: 1 },
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/bp-responsibility/2/editor', name: 'BpResponsibilityEditor2',
        component: () => import('../../views/admin_references/PageBpResponsibilityEditor'),
        props: { type: 2 },
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/scopes', name: 'AdminReferencesScopes',
        component: () => import('../../views/admin_references/PageScopes'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/scopes/editor', name: 'AdminReferencesScopesEditor',
        component: () => import('../../views/admin_references/PageScopesEditor'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/responsible-groups', name: 'AdminResponsibleGroups',
        component: () => import('../../views/admin_responsible_groups/PageResponsibleGroups'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/references/responsible-groups/editor', name: 'AdminResponsibleGroupsEditor',
        component: () => import('../../views/admin_responsible_groups/PageResponsibleGroupsEdit'),
        meta: { middleware: { section: 'admin' } },
    },

    // ADMIN NMD
    {
        path: '/admin/nmd', name: 'AdminNmdEditor',
        component: () => import('../../views/admin_nmd_editor/NmdEditor'),
        meta: { middleware: { section: 'admin' } },
    },

    {
        path: '/admin/calendar', name: 'AdminWorkCalendar',
        component: () => import('../../views/admin_work_calendar/WorkCalendar'),
        meta: { middleware: { section: 'admin' } },
    },

    // ADMIN CONTEXT HINTS
    {
        path: '/admin/hints', name: 'AdminHints',
        component: () => import('../../views/admin_context_hints/PageOverview'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/hints/ui-enable', name: 'AdminHintsEnable',
        component: () => import('../../views/admin_context_hints/PageEnableHintsInUI'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/hints/bp-list', name: 'AdminHintsInBpList',
        component: () => import('../../views/admin_context_hints/PageHintsInBpList'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/hints/bp-list/editor', name: 'AdminHintsInBpListEditor',
        component: () => import('../../views/admin_context_hints/PageHintsInBpList'),
        meta: { editorMode: true, middleware: { section: 'admin' } },
    },
    {
        path: '/admin/hints/bp', name: 'AdminHintsInBp',
        component: () => import('../../views/admin_context_hints/PageHintsInBp'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/hints/bp-editor', name: 'AdminHintsInBpEditor',
        component: () => import('../../views/admin_context_hints/PageHintsInBp'),
        meta: { editorMode: true, middleware: { section: 'admin' } },
    },

    // ADMIN CHANGE MANAGEMENT
    {
        path: '/admin/change-management/role', name: 'ChangeManagementRole',
        component: () => import('../../views/admin_change_management/PageChangeManagementRole'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/change-management/role-editor', name: 'ChangeManagementRoleEditor',
        component: () => import('../../views/admin_change_management/PageChangeManagementRoleEditor'),
        meta: { middleware: { section: 'admin' } },
    },

    // APPROVAL ROUTE
    {
        path: '/admin/approval-route/', name: 'ApprovalRoute',
        component: () => import('../../views/approval_route/PageOverview'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/approval-route/bp/:lvl/edit/:routeId?', name: 'BpMatchingEditor',
        component: () => import('../../views/approval_route/PageBpMatchingEditor'),
        props: (route) => ({ lvl: +route.params.lvl, routeId: +route.params.routeId }),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/approval-route/bp/:lvl', name: 'BpMatching',
        component: () => import('../../views/approval_route/PageBpMatching'),
        props: (route) => ({ lvl: +route.params.lvl }),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/approval-route/required-fields', name: 'RequiredFields',
        component: () => import('../../views/approval_route/PageRequiredFields'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/approval-route/bp-offers', name: 'BpOffers',
        component: () => import('../../views/approval_route/PageBpOffers'),
        meta: { middleware: { section: 'admin' } },
    },

    // EMPLOYEE REPLACEMENTS
    {
        path: '/admin/employee-replacements', name: 'EmployeeReplacements',
        component: () => import('../../views/admin_employee_deputy/PageEmployees'),
        meta: { middleware: { section: 'admin' } },
    },

    // ADMIN DISCLAIMER
    {
        path: '/admin/disclaimer', name: 'AdminDisclaimer',
        component: () => import('../../views/admin_disclaimer/PageDisclaimer'),
        meta: { middleware: { section: 'admin' } },
    },
    {
        path: '/admin/disclaimer-editor', name: 'AdminDisclaimerEditor',
        component: () => import('../../views/admin_disclaimer/PageDisclaimerEditor'),
        meta: { middleware: { section: 'admin' } },
    },

    // ADMIN EXPERT OPINION REQUEST SETTINGS
    {
        path: '/admin/expert-opinion-request-settings', name: 'ExpertOpinionRequestSettings',
        component: () => import('../../views/admin_expert_opinion_request_settings/PageExpertOpinionRequestSettings'),
        meta: { middleware: { section: 'admin' } },
    },

    // SWIMLANE SETTINGS
    {
        path: '/admin/swimlane-settings', name: 'SwimlaneSettings',
        component: () => import('../../views/admin_swimlane/PageSwimlaneSettings'),
        meta: { middleware: { section: 'admin' } },
    },

    // ADMIN DB TOOLS
    {
        path: '/admin/data-tools', name: 'DataTools',
        component: () => import('../../views/admin_tools/DataTools'),
        meta: { middleware: { section: 'admin' } },
    },
]
