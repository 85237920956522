import { set } from 'vuex-intern'

const initialState = () => ({
    riskDegree: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setRiskDegree: set('riskDegree'),
    pushToRiskDegree (state, data) {
        state.riskDegree.page = data.page
        data.list.map(e => state.riskDegree.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('admin/riskDegree').get(0).then((r) => {
            commit('setRiskDegree', r.data)
        })
    },
    async fetchAndPush ({ commit, getters, rootGetters }) {
        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('admin/riskDegree').get(getters.page + 1).then((r) => {
            commit('pushToRiskDegree', r.data)
        })
    },
    search ({ commit }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        return this.$repo('admin/riskDegree').search(0, searchString).then((r) => {
            commit('setRiskDegree', r.data)
        })
    },
    async searchAndPush ({ commit, getters, rootGetters }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('admin/riskDegree').search(getters.page + 1, searchString).then((r) => {
            commit('pushToRiskDegree', r.data)
        })
    },
    save (_, mass) {
        return this.$repo('admin/riskDegree').save(mass)
    },
}

const getters = {
    page: state => state.riskDegree.page,
}

export const STORE_NAME = 'riskDegree'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
