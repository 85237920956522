import Vue from 'vue'

// global components
import UiChip from '@/ui/UiChip'
import UiChipGroup from '@/ui/UiChipGroup'
import UiBreadcrumbs from '@/ui/UiBreadcrumbs'
import UiNotFound from '@/ui/UiNotFound'
import UiTabsSwitcher from '@/ui/UiTabsSwitcher'
import UiMenu from '@/ui/UiMenu'

Vue.component('UiChip', UiChip)
Vue.component('UiChipGroup', UiChipGroup)
Vue.component('UiBreadcrumbs', UiBreadcrumbs)
Vue.component('UiNotFound', UiNotFound)
Vue.component('UiTabsSwitcher', UiTabsSwitcher)
Vue.component('UiMenu', UiMenu)

// global form controls components
import UiRadio from '@/ui/form-controls/UiRadio'
import UiRadioGroup from '@/ui/form-controls/UiRadioGroup'
import UiCheckbox from '@/ui/form-controls/UiCheckbox'
import UiCheckboxGroup from '@/ui/form-controls/UiCheckboxGroup'
import UiTextField from '@/ui/form-controls/UiTextField'
import UiTextConstructor from '@/ui/form-controls/UiTextConstructor'
import UiSwitch from '@/ui/form-controls/UiSwitch'
import UiNumberInput from '@/ui/form-controls/UiNumberInput'

Vue.component('UiRadio', UiRadio)
Vue.component('UiRadioGroup', UiRadioGroup)
Vue.component('UiCheckbox', UiCheckbox)
Vue.component('UiCheckboxGroup', UiCheckboxGroup)
Vue.component('UiTextField', UiTextField)
Vue.component('UiTextConstructor', UiTextConstructor)
Vue.component('UiSwitch', UiSwitch)
Vue.component('UiNumberInput', UiNumberInput)

// global dynamic components
Vue.component('ExpandArrow', () => import('./ui/ExpandArrow'))
Vue.component('SearchBar', () => import('./ui/SearchBar'))
Vue.component('DatePicker', () => import('./ui/DatePicker'))
Vue.component('CustomDatePicker', () => import('./ui/CustomDatePicker'))
Vue.component('ProgressBar', () => import('./ui/ProgressBar'))
Vue.component('CustomSelector', () => import('./ui/CustomSelector'))
Vue.component('ColorSelector', () => import('./ui/ColorSelector'))
Vue.component('ColoredBall', () => import('./ui/ColoredBall'))
Vue.component('FiletypeIcon', () => import('./ui/FiletypeIcon'))
Vue.component('CustomStatus', () => import('./ui/CustomStatus'))
Vue.component('UiTooltip', () => import('./ui/UiTooltip'))
Vue.component('HintTooltip', () => import('./ui/HintTooltip'))
Vue.component('FixedPageHeader', () => import('./ui/FixedPageHeader'))
