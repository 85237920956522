import utils from '@/utils'

export class RolesDispatcher {

    commit
    changes
    executor
    role

    constructor ({ commit, changes, executor, role }) {
        this.commit = commit
        this.changes = changes
        this.executor = executor
        this.role = role
    }

    getRolesList (source, withAdded = false) {

        source ||= []
        const roles = []
        const del = this.changes?.del.map(i => i.id)
        const list = source.filter(role => !del.includes(role.id))

        withAdded && this.changes?.add.forEach(r => roles.push(r))

        let role = null

        this.changes?.mod.forEach(r => {
            role = list.find(i => i.id === r.id)

            if (!role) return

            const del = r.executors?.del?.length ? r.executors.del.map(i => i.id + i.type) : null

            r.executors?.add?.length && (role.executors = [...role.executors, ...r.executors.add])

            del && (role.executors = role.executors.filter(i => !del.includes(i.id + i.type)))

            role.executors = utils.uniqBy(role.executors, ['id', 'type'])
        })

        return [...roles.filter(role => !del.includes(role.id)).reverse(), ...list]
    }

    getRole (mode) {
        return this.changes[mode].find(r => r.id === this.role.id)
    }

    delRole (mode) {
        const index = this.changes[mode].findIndex(i => i.id === this.role.id)
        index !== -1 && this.changes[mode].splice(index, 1)
    }

    addRole (mode) {
        this.changes[mode].push({
            id: this.role.id || -utils.randomId(),
            name: this.role.name,
            description: this.role.description,
            executors: {
                add: [],
                del: [],
            },
        })

        this.saveChanges()
    }

    modRole (mode) {
        const index = this.changes[mode].findIndex((r) => r.id === this.role.id)

        if (!~index) return

        this.changes[mode][index] = Object.assign(this.changes[mode][index], {
            name: this.role.name,
            description: this.role.description,
        })

        this.saveChanges()
    }

    getExecutor (source, mode) {
        return source.executors[mode]?.find(i => i.id === this.executor.id && this.executor.type === i.type)
    }

    pushExecutor (source, mode) {
        source.executors[mode].push(this.executor)
    }

    delExecutor (source, mode) {
        const index = source.executors[mode]?.findIndex(i => i.id === this.executor.id && this.executor.type === i.type)
        index !== -1 && source.executors[mode]?.splice(index, 1)
    }

    saveChanges () {
    }
}
