export default [
    {
        path: '/bp-cross-chain-map', name: 'BPCrossChainMap',
        component: () => import('../../views/bp_cross_chain/map/BPCrossChainMap'),
        meta: { middleware: { section: 'e2e' } },
    },
    {
        path: '/bp-cross-chain-graph/:id', name: 'BPCrossChainGraph',
        component: () => import('../../views/bp_cross_chain/graph/BPCrossChainGraph'),
        props: route => ({
            isNewGraph: false,
            id: +route.params.id,
        }),
        meta: { middleware: { section: 'e2e' } },
    },
    {
        path: '/bp-cross-chain-new-graph', name: 'BPCrossChainNewGraph',
        component: () => import('../../views/bp_cross_chain/graph/BPCrossChainGraph'),
        props: (route) => ({
            isNewGraph: route.params.id === -1 || !route.params.id,
            id: route.params.id || -1,
        }),
        meta: { middleware: { section: 'e2e' } },
    },
    {
        path: '/bp-cross-chain-passport/:id', name: 'BPCrossChainPassport',
        component: () => import('../../views/bp_cross_chain/passport/BPCrossChainPassport'),
        props: route => ({
            id: +route.params.id,
        }),
        meta: { middleware: { section: 'e2e' } },
    },
    {
        path: '/bp-cross-chain-passport/edit/:id', name: 'BPCrossChainPassportEdit',
        component: () => import('../../views/bp_cross_chain/passport/BPCrossChainPassport'),
        props: route => ({
            id: +route.params.id,
            isForceEditing: true,
        }),
        meta: { middleware: { section: 'e2e' } },
    },
    {
        path: '/bp-cross-chain-model-options', name: 'BPCrossChainModelOptionsNew',
        component: () => import('../../views/bp_cross_chain/BPCrossChainModelOptions'),
        meta: { middleware: { section: 'e2e' } },
    },
    {
        path: '/bp-cross-chain-model-options/:id', name: 'BPCrossChainModelOptions',
        component: () => import('../../views/bp_cross_chain/BPCrossChainModelOptions'),
        props: route => ({
            chainId: +route.params.id,
        }),
        meta: { middleware: { section: 'e2e' } },
    },
    {
        path: '/bp-cross-chain-bp-select/:mode/:id?', name: 'BPCrossChainBPSelector',
        component: () => import('../../views/bp_cross_chain/BPCrossChainBPSelector'),
        props: route => ({
            mode: route.params.mode,
            id: route.params.id ? Number(route.params.id) : null,
            chainId: route.params.chainId ? Number(route.params.chainId) : null,
            bpFilter: route.params.bpFilter,
        }),
        meta: { middleware: { section: 'e2e' } },
    },
    {
        path: '/bp-cross-chain-bp-select/:mode', name: 'BPCrossChainBPSelectorNew',
        component: () => import('../../views/bp_cross_chain/BPCrossChainBPSelector'),
        props: route => ({
            mode: route.params.mode,
            id: -1,
        }),
        meta: { middleware: { section: 'e2e' } },
    },
    // {
    // 	path: '/bp-cross-chains-edit',
    // 	name: 'BPCrossChainPassportEdit',
    // 	component: () => import('../../views/bp_cross_chain/passport/BPCrossChainPassportEdit'),
    //  meta: { middleware: { section: 'e2e' } },
    // },
]
