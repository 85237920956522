import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition)
            return savedPosition

        return { x: 0, y: 0 }
    },
})

router.beforeEach((to, from, next) => {
    if (
        to.meta?.middleware?.section
        && store.getters.isHeaderLoaded
        && !store.getters.headerMenu[to.meta?.middleware?.section]) {
        return next({ name: 'Forbidden' })
    }

    next()
})

export default router
