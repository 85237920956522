<template>
    <label
        class="ui-checkbox"
        :class="{
            'ui-checkbox_disabled': disabled,
            'ui-checkbox_rtl': isRtl,
            'ui-checkbox_small': small,
            'ui-checkbox_inactive': inactive,
        }"
    >
        <input
            class="ui-checkbox__input visually-hidden"
            type="checkbox"
            :checked="isChecked"
            :name="inputName"
            :disabled="disabled || allDisabled"
            v-bind="$attrs"
            @change="change"
        >

        <span class="ui-checkbox__box"></span>

        <div v-if="$slots.default" class="ui-checkbox__text">
            <slot />
        </div>
    </label>
</template>

<script>
export default {
    name: 'UiCheckbox',
    model: {
        event: 'change',
    },
    props: {
        isRtl: Boolean,
        name: String,
        checked: {
            type: Boolean,
            default: null,
        },
        value: {
            default: null,
        },
        disabled: Boolean,
        small: Boolean,
        inactive: Boolean,
    },
    inject: {
        checkboxGroup: {
            default: null,
        },
    },
    computed: {
        inputName () {
            return this.checkboxGroup?.name || this.name
        },
        allDisabled () {
            return this.checkboxGroup?.disabled
        },
        trackBy () {
            return this.checkboxGroup?.trackBy
        },
        isChecked () {
            if (this.checked !== null) {
                return this.checked
            }

            if (this.checkboxGroup) {
                return this.trackBy
                    ? this.checkboxGroup.value?.find(v => v[this.trackBy] === this.value[this.trackBy])
                    : this.checkboxGroup.value?.find(v => v === this.value)
            }

            return this.value
        },
    },
    methods: {
        change (event) {
            if (this.checkboxGroup) {
                this.checkboxGroup.change(event.target.checked
                    ? this.checkboxGroup.value.concat(this.value)
                    : (this.trackBy
                        ? this.checkboxGroup.value?.filter(v => v[this.trackBy] !== this.value[this.trackBy])
                        : this.checkboxGroup.value?.filter(v => v !== this.value))
                )
            }

            this.$emit('change', event.target.checked)
        },
    },
}
</script>

<style lang="sass" scoped>
.ui-checkbox
    display: inline-flex
    text-align: left
    cursor: pointer
    min-height: 20px
    position: relative

.ui-checkbox__text
    font-weight: 400
    color: var(--dark-gray-color)

    .ui-checkbox_small &
        font-size: 13px
        line-height: 20px

.ui-checkbox__box
    --checkbox-size: 16px
    --checkbox-color: var(--gray-85-color)
    margin-top: 1px
    margin-right: 16px
    flex-shrink: 0
    width: var(--checkbox-size)
    height: var(--checkbox-size)
    cursor: inherit
    background: transparent
    display: inline-flex
    border-radius: 3px
    border: 1px solid var(--checkbox-color)
    box-sizing: border-box
    transition: .15s ease-in-out
    position: relative

    &::before
        --width: 10px
        --height: 8px
        content: ''
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8' fill='none'%3E%3Cpath d='M1 3.5L4 6.5L9 1.5' stroke='white' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E")
        position: absolute
        top: calc((var(--checkbox-size) - var(--height)) / 2 - 1px)
        left: calc((var(--checkbox-size) - var(--width)) / 2 - 1px)
        width: 0
        height: var(--height)
        overflow: hidden
        transition: .4s ease-in-out

    &:after
        --offset: 14px
        content: ''
        width: calc(var(--checkbox-size) + var(--offset))
        height: calc(var(--checkbox-size) + var(--offset))
        position: absolute
        top: calc(var(--offset) / -2 - 1px)
        left: calc(var(--offset) / -2 - 1px)
        border-radius: 50%

.ui-checkbox__input:focus ~ .ui-checkbox__box
    --checkbox-color: var(--blue-color)

.ui-checkbox__input:checked ~ .ui-checkbox__box
    --checkbox-color: var(--blue-color)
    background: var(--checkbox-color)

    &::before
        width: var(--width)

.ui-checkbox_disabled
    cursor: not-allowed !important

    .ui-checkbox__box
        --checkbox-color: var(--dark-gray-60-color) !important
        border-color: var(--checkbox-color)

    .ui-checkbox__text
        color: var(--dark-gray-60-color) !important

.ui-radio_inactive
    .ui-radio__box
        --border-color: var(--dark-gray-60-color) !important

    .ui-radio__text
        color: var(--gray-71-color)

.ui-checkbox_rtl
    flex-direction: row-reverse

    .ui-checkbox__box
        margin-right: 0
        margin-left: 16px
</style>
