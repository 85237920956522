import services from '@/router/services'

export default [
    {
        path: '/bp/swimlane/:id', name: 'Swimlane',
        component: () => import('../../views/bp_swimlane/SwimlaneGraph'),
        props: (route) => ({
            id: +route.params.id,
            approvalVersion: route.params.approvalVersion,
        }),
        meta: { middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/swimlane/editor/:id', name: 'SwimlaneEditor',
        component: () => import('../../views/bp_swimlane/SwimlaneGraph'),
        props: (route) => ({
            id: +route.params.id,
            editorMode: true,
            newType: route.query.newType,
        }),
        meta: { middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/overview', name: 'BPOverview',
        redirect: services.bpOverviewRedirect,
        meta: { view: 'tiles', middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/overview/list', name: 'BPList',
        component: () => import('../../views/bp_overview/PageList'),
        props: (route) => ({ activeBP: +route.query.activeBP }),
        meta: { view: 'list', middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/overview/editor', name: 'BPListEditor',
        component: () => import('../../views/bp_overview/PageList'),
        props: (route) => ({ activeBP: +route.query.activeBP }),
        meta: { editorMode: true, middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/overview/tiles', name: 'BPTiles',
        component: () => import('../../views/bp_overview/PageTiles'),
        meta: { view: 'tiles', middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/overview/tiles/:parentId', name: 'BPSubTiles',
        component: () => import('../../views/bp_overview/PageSubTiles'),
        props: (route) => ({ parentId: +route.params.parentId }),
        meta: { view: 'tiles', middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/passport/:id', name: 'BPPassport',
        component: () => import('../../views/bp_passport/Passport'),
        props: (route) => ({ id: +route.params.id }),
        meta: { middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/passport/:id/approval/:taskId/:type', name: 'BPPassportMatching',
        component: () => import('../../views/bp_passport/Passport'),
        props: (route) => ({ id: +route.params.id, taskId: +route.params.taskId, type: route.params.type }),
        meta: { middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/passport/edit/:id', name: 'BPPassportEdit',
        component: () => import('../../views/bp_passport/PassportEditor'),
        props: (route) => ({ id: +route.params.id }),
        meta: { middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/passport/edit/:id/approval/:taskId/:type', name: 'BPPassportEditMatching',
        component: () => import('../../views/bp_passport/PassportEditor'),
        props: (route) => ({ id: +route.params.id, taskId: +route.params.taskId, type: route.params.type }),
        meta: { middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/search', name: 'BPSearch',
        component: () => import('../../views/bp_overview/PageBpSearch'),
        props: (route) => ({ query: route.query.q }),
        meta: { middleware: { section: 'bpList' } },
    },
    {
        path: '/bp/search/editor', name: 'BPSearchEditor',
        component: () => import('../../views/bp_overview/PageBpSearch'),
        props: (route) => ({ query: route.query.q }),
        meta: { editorMode: true, middleware: { section: 'bpList' } },
    },
]
