import Repo from '@/api/service/repo'

export default class extends Repo {
    buildModelOptions (data) {
        return this.client.post('e2e/build', data)
    }

    createChain (name, params, model, bpChain) {
        const data = { name, params }

        model?.length && (data.model = model)
        bpChain?.length && (data.bpChain = bpChain)

        return this.client.post('e2e/passport', data)
    }

    saveModel (id, data) {
        return this.client.post(`e2e/passport?id=${id}`, data)
    }

    changeStatus (id, status) {
        return this.client.put(`e2e/passport/status?id=${id}&status=${status}`)
    }

    getMap (params) {
        let query = ''
        if (params instanceof Object) {
            query = this.makeQuery(params, { arrayFormat: 'none' })
        }
        return this.client.get(`e2e/map?${query}`)
    }

    getNextChildren (ids) {
        return this.client.get(`e2e/links/multiple?id=${ids}`)
    }

    getParallelCandidate (picked, left) {
        return this.client.get(`e2e/parallel/candidate?picked=${picked}&left=${left}`)
    }

    getPassport (id) {
        return this.client.get(`e2e/passport?id=${id}`)
    }

    deleteChain (id) {
        return this.client.delete(`e2e/passport?id=${id}`)
    }

    getFilterParams () {
        return this.client.get(`e2e/search?tz=${this.tz}`)
    }
}
