import Repo from '@/api/service/repo'

export default class extends Repo {

    search (p, s) {
        const query = this.makeQuery({
            s: s,
            p: p,
        }, { arrayFormat: 'none' })
        return this.client.get(`kb/search?${query}`)
    }
}
