import Repo from '@/api/service/repo'

export default class extends Repo {
    get () {
        return this.client.get('admin/role')
    }
    save (roles) {
        return this.client.post('admin/role', roles)
    }

    /*
    * Получение списка системных ролей АСУ КПМ
    * */
    asuRoles (s) {
        return this.client.get(`role?tz=${this.tz}` + (s !== undefined ? `&s=${encodeURI(s)}` : ''))
    }
}
