import { set } from 'vuex-intern'

const initialState = () => ({
    bpTypes: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setBpTypes: set('bpTypes'),
    pushToBpTypes (state, data) {
        state.bpTypes.page = data.page
        data.list.map(e => state.bpTypes.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('references/dictionary').getTypes(0, 30).then((r) => {
            commit('setBpTypes', r.data)
        })
    },
    search ({ commit }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        this.$repo('references/dictionary').searchTypes(0, searchString, 30).then((r) => {
            commit('setBpTypes', r.data)
        })
    },
    async fetchAndPush ({ commit, getters, rootGetters }) {
        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/dictionary').getTypes(getters.page + 1, 30).then((r) => {
            commit('pushToBpTypes', r.data)
        })
    },
    async searchAndPush ({ commit, getters, rootGetters }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return await this.$rs.useLoader(bgLoader).repo('references/dictionary').searchTypes(getters.page + 1, searchString, 30).then((r) => {
            commit('pushToBpTypes', r.data)
        })
    },
    save (_, mass) {
        return this.$repo('references/dictionary').saveTypes(mass)
    },
}

const getters = {
    page: state => state.bpTypes.page,
}

export const STORE_NAME = 'bpTypes'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
