import Repo from '@/api/service/repo'

export default class extends Repo {
    deletionValidation (id) {
        return this.client.get(`bp/deletion/validation?id=${id}`)
    }
    delete (id) {
        return this.client.delete(`bp?id=${id}`)
    }
}
