import Repo from '@/api/service/repo'

export default class extends Repo {
    get (p) {
        return this.client.get(`system?p=${p}`)
    }
    search (p, s) {
        return this.client.get(`system?p=${p}&s=${s}`)
    }
    save (data) {
        return this.client.stream().post('system', data)
    }
}
