<template>
    <div />
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'TheDisclaimer',
    data: () => ({
        isClosed: false,
    }),
    computed: {
        ...mapGetters(['isHeaderLoaded', 'isShowDisclaimer']),
    },
    methods: {
        runDisclaimerChecker () {
            return new Promise((resolve) => {
                this.$utils.promise(
                    () => this.isHeaderLoaded,
                    { interval: 300 }
                ).finally(async () => {
                    if (this.isShowDisclaimer && !this.isClosed) {
                        await this.showDisclaimer()
                    }

                    resolve()
                })
            })
        },
        showDisclaimer () {
            return new Promise((resolve) => {
                this.$repo('admin/disclaimer').get().then((r) => {
                    this.$confirm({
                        title: r.data.title,
                        text: `<div class="scrolable-text">${r.data.text}</div>`,
                        bind: { width: '880px', class: 'notifier-disclaimer' },
                        buttons: [(c) => ({
                            text: 'Принять',
                            bind: { class: 'button' },
                            on: {
                                click: () => {
                                    this.$repo('admin/disclaimer').approve().then(() => {
                                        this.isClosed = true
                                        c.close()
                                        resolve()
                                    })
                                },
                            },
                        })],
                    })
                })
            })
        },
    },
}
</script>

<style lang="sass">
.notifier.notifier-disclaimer
    p
        margin-top: 0
        margin-bottom: 12px

    .--title
        text-align: left

    .--text > .scrolable-text
        margin-top: -12px
        overflow-y: auto
        max-height: 40vh
        text-align: left

    .--buttons
        padding: 0 52px

        > .button
            margin: 0

</style>
