import utils from '@/utils'

import { buildListSections } from '../helpers/sectionsBuilder'
import { buildListExperts, buildListExpertsApprover, buildUniqParentExperts } from '../helpers/expertsBuilder'

import { User } from '@/entities/user'

const user = new User()

export default {
    // circleNums: (state) => [...new Set([
    //     ...state.requests.map((zem) => zem.circleNum),
    // ].sort((a, b) => (b - a)))],

    isActiveOnlyLastCircle: (state) => state.activeCircles.length === 1 && state.activeCircles[0] === state.taskCircleNum,

    requestsInActiveCircles: (state) => state.requests
        .filter((zem) => state.activeCircles.includes(zem.circleNum)),

    requestsForMe: (state) => state.requests
        .filter((req) => req.cascadeZemCategory === 'ROOT_ASKED'),

    requestsForMeInActiveCircles: (state, getters) => getters.requestsInActiveCircles
        .filter((req) => req.cascadeZemCategory === 'ROOT_ASKED'),

    myRequestsInActiveCircles: (state) => state.myRequests
        .filter((req) => state.activeCircles.includes(req.circleNum)),

    authorsSentRequestsForMeInActiveCircle: (state, getters) => buildUniqParentExperts(
        getters.requestsInActiveCircles,
        getters.requestsForMeInActiveCircles
    ),

    requestGroupsByExperts: (state, getters) => state.isCurrentUserExpert && getters.requestsForMeInActiveCircles.length
        ? buildListExperts(getters.requestsInActiveCircles, getters.requestsForMeInActiveCircles)
        : buildListExpertsApprover(getters.requestsInActiveCircles, getters.myRequestsInActiveCircles),

    requestGroupsBySections: (state, getters) => buildListSections(getters.requestsInActiveCircles),

    circleNums: (state, getters) => utils.uniq(
        (state.isCurrentUserExpert
            ? getters.requestsForMe
            : state.myRequests
        ).map((req) => req.circleNum)
    ).sort((a, b) => (b - a)),

    solutionsInActiveCircle: (state) => state.solutions
        .filter((issue) => state.activeCircles.includes(issue.circleNum)),

    myAnswersInActiveCircle: (state, getters) => getters.requestsForMeInActiveCircles
        .reduce((acc, req) => {
            const replies = req.replies
                .filter((r) => r.reply && (r.reply.author.id === user.id || r.reply.substituted.id === user.id))
                .map((r) => r.reply)

            acc[req.id] ||= replies

            return acc
        }, {}),

    countOfUnansweredRequests: (state, getters) => getters.requestsForMeInActiveCircles.reduce((acc, req) => {
        if (getters.myAnswersInActiveCircle[req.id].length) {
            return acc
        }

        return acc + 1
    }, 0),
}
