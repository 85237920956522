<template>
    <modal
        class="modal"
        name="error_window"
        width="50%"
        height="auto"
        scrollable
        click-to-close
        @before-open="beforeOpen"
        @closed="onClosed"
    >
        <div class="modal__wrapper">
            <svg
                class="modal__icon"
                width="85"
                height="75"
                aria-hidden="true"
                focusable="false"
            >
                <use href="@/assets/images/sprites.svg#triangle-error"></use>
            </svg>
            <template v-if="responseData && !responseError">
                <h2 class="modal__title">
                    Во время выполнения запроса возникли ошибки:
                </h2>
                <vue-json-pretty
                    :data="responseData"
                    :show-line="false"
                    :show-double-quotes="false"
                    :style="{ 'text-align': 'left' }"
                />
            </template>
            <template v-else>
                <p class="modal__description --error-message">
                    {{ responseError }}
                </p>
            </template>

            <!--
            РАСКОММЕНТИРОВАТЬ ЕСЛИ ПОТРЕБУЕТСЯ ВЫЗОВ ПОВТОРНОГО ЗАПРОСА:

            <button
                v-if="error.retry !== false"
                class="modal__button button"
                type="button"
                @click="retryBtn"
            >
                Попробовать снова
            </button>
            -->
            <button
                class="modal__button button"
                type="button"
                @click="continueBtn"
            >
                OK
            </button>
        </div>
    </modal>
</template>

<script>

const RESPONSE_STATUS_SKIP = Object.freeze([401])

export default {
    props: {
        errors: Array,
    },
    data () {
        return {
            lockActions: false,
        }
    },
    computed: {
        error () {
            return this.errors?.length ? this.errors[this.errors.length - 1] : {}
        },
        response () {
            return this.error.data?.response
        },
        responseData () {
            return this.response?.data
        },
        responseError () {
            return this.responseData?.message ?? this.responseData?.error
        },
    },
    methods: {
        beforeOpen (e) {
            if ((!this.responseData && !this.responseError) || RESPONSE_STATUS_SKIP.includes(this.response?.status)) {
                e.cancel()
            }
        },
        continueBtn () {
            if (this.lockActions) {
                return
            }
            this.lockActions = true

            this.$emit('continue')
            this.$modal.hide('error_window')
        },
        retryBtn () {
            if (this.lockActions) {
                return
            }
            this.lockActions = true

            this.$emit('retry')
            this.$modal.hide('error_window')
        },
        onClosed () {
            this.lockActions = false
            this.$emit('closed')
        },
    },
}
</script>

<style lang="sass" scoped>
.modal__wrapper
    display: flex
    flex-direction: column
    align-items: center

    padding-top: 40px
    padding-right: 52px
    padding-bottom: 40px
    padding-left: 52px

    background-color: var(--white-color)

.modal__icon
    margin-bottom: 27px

.modal__title
    margin-top: 0
    margin-bottom: 18px

    font-size: 24px
    font-weight: 800
    text-align: center

.modal__description
    margin-top: 0
    margin-bottom: 10px

    font-weight: 700
    font-size: 13px
    color: var(--dark-gray-color)

.modal__button
    margin-top: 20px
    width: 212px
    margin-bottom: 15px
    align-self: center

    &:last-child
        margin-bottom: 0

.--error-message
    font-size: 20px
    color: #000
    text-align: center

</style>
