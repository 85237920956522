import Repo from '@/api/service/repo'
import { DEFAULT_PAGE } from '@/api/constants/pagination'


export default class extends Repo {
    get (page = 0, limit = DEFAULT_PAGE, isFavorite = null) {
        const query = this.makeQuery({
            p: page,
            n: limit,
            tz: this.tz,
            fav: isFavorite,
        }, { arrayFormat: 'none', skipNull: true, encode: false })

        return this.client.get(`execgroup?${query}`)
    }

    getById (id, p = 0) {
        return this.client.get(`execgroup/${id}?tz=${this.tz}&p=${p}`)
    }

    search (page = 0, search, limit = DEFAULT_PAGE, isFavorite = null) {
        const query = this.makeQuery({
            p: page,
            n: limit,
            s: search,
            tz: this.tz,
            fav: isFavorite,
        }, { arrayFormat: 'none', skipNull: true, encode: false })

        return this.client.get(`execgroup?${query}`)
    }

    searchBPOwners (p = 0, s, n = 20) {
        return this.client.get(`execgroup?tz=${this.tz}&p=${p}&n=${n}&s=${s}&o=true`)
    }

    saveNew (data) {
        return this.client.post(`execgroup?tz=${this.tz}`, data)
    }

    save (id, data) {
        return this.client.put(`execgroup/${id}?tz=${this.tz}`, data)
    }

    delete (id) {
        return this.client.delete(`execgroup/${id}?tz=${this.tz}`)
    }
}
