import state from './zem.state'
import mutations from './zem.mutations'
import actions from './zem.actions'
import getters from './zem.getters'

export const ZEM_STORE_NAME = 'zem_store'

export const ZEM_STATE = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
