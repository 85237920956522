import Repo from '@/api/service/repo'

export default class extends Repo {
    fetch (taskId) {
        return this.client.get(`bp/approval/conversation/zems?t=${taskId}&tz=${this.tz}`)
    }

    create (zem) {
        const {
            taskId,
            section,
            sectionItem = '',
            content,
            experts,
            plannedDueDate = null,
            parentZemCommentId = null,
        } = zem

        return this.client.post(`bp/approval/comment/zem?tz=${this.tz}`, {
            taskId,
            section,
            sectionItem,
            content,
            experts,
            plannedDueDate,
            parentZemCommentId,
        })
    }
}
