import adminRoutes from './routes/admin'
import bpRoutes from './routes/bp'
import bpCrossChainRoutes from './routes/bp-cross-chain'
import accountRoutes from './routes/account'
import kbRoutes from './routes/kb'

const withBaseLayoutRoutes = [
    {
        path: '/reports', name: 'Reports',
        component: () => import('../views/reports/PageReports'),
        meta: { middleware: { section: 'reports' } },
    },
    {
        path: '/notifications', name: 'Notifications',
        component: () => import('../views/notifications/TasksAndNotifications'),
        meta: { middleware: { section: 'tasksAndNotifications' } },
    },
    {
        path: '/tasks', name: 'Tasks',
        component: () => import('../views/notifications/TasksAndNotifications'),
        props:{ tasksActive: true },
        meta: { middleware: { section: 'tasksAndNotifications' } },
    },

    ...bpRoutes,
    ...bpCrossChainRoutes,
    ...accountRoutes,
    ...kbRoutes,
    ...adminRoutes,
]

export default [
    { path: '/', redirect: { name: 'BPOverview' } },
    {
        path: '/forbidden', name: 'Forbidden',
        component: () => import('../views/error_pages/PageForbidden'),
    },
    {
        path: '/api/*',
        beforeEnter (route) {
            location.href = `${process.env.VUE_APP_SERVER}${route.fullPath}`
        },
    },
    {
        path: '', name: 'BaseLayout',
        component: () => import('../views/shared/BaseLayout'),
        children: withBaseLayoutRoutes,
    },
    {
        path: '/swimlane-print/:id', name: 'SwimlanePrint',
        component: () => import('../views/bp_swimlane/SwimlanePrint'),
        props: (route) => ({ id: +route.params.id }),
        meta: { noCheckBrowser: true },
    },
    {
        path: '/swimlane-preview/:id', name: 'SwimlanePreview',
        component: () => import('../views/bp_swimlane/SwimlanePreview'),
        props: (route) => ({ id: +route.params.id }),
        meta: { noCheckBrowser: true },
    },
    {
        path: '/bp-cross-chain-graph-print/:id', name: 'BPCrossChainGraphPrint',
        component: () => import('@/views/bp_cross_chain/graph/BPCrossChainGraphPrint'),
        props: (route) => ({ id: +route.params.id }),
        meta: { noCheckBrowser: true },
    },

    // должен располагаться в самом конце списка
    { path: '*', name: 'PageNotFound', component: () => import('../views/error_pages/PageNotFound') },
]
