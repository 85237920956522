import Repo from '@/api/service/repo'

export default class extends Repo {

    get () {
        return this.client.get('admin/approval/field')
    }
    save (data) {
        return this.client.post(`admin/approval/field?tz=${this.tz}`, data)
    }

    /*
    * @param: e boolean Флаг для передачи участников ролевой модели согласования.
    * Если true (по умолчанию) - то ролевые модели согласования будут переданы вместе с полем executors, иначе без него.
    * Default value : true
    */
    roles (e = true, a = true) {
        return this.client.get(`/agreement/role/grouped?tz=${this.tz}&e=${e}&a=${a}`)
    }

    rolesSave (data) {
        return this.client.post('/agreement/role', data)
    }

    roleExecutors (roleId) {
        return this.client.get(`/agreement/role/${roleId}/executors?tz=${this.tz}`)
    }
}
