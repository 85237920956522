import Repo from '@/api/service/repo'

export default class extends Repo {

    get (id) {
        return this.client.get(`bp/${id}/suggestion?tz=${this.tz}`)
    }

    post (id, data) {
        return this.client.post(`bp/${id}/suggestion?tz=${this.tz}`, data)
    }

    closeSuggestion (BpId, suggestionId) {
        return this.client.post(`bp/${BpId}/suggestion/${suggestionId}/resolution?tz=${this.tz}`)
    }

}
