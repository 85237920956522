import Repo from '@/api/service/repo'

export default class extends Repo {
    fetch () {
        return this.client.get('/admin/swimlane')
    }

    save (settings) {
        return this.client.post('/admin/swimlane', settings)
    }
}
