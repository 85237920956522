export const sectionLabels = {
    PASSPORT: 'Весь паспорт',
    MAIN: 'Основные характеристики',
    SIPOC: 'Диаграмма SIPOC',
    RESPONSIBILITY_MATRIX: 'Матрица распределения ответственности',
    FUNCTION_MATRIX: 'Участники и функции',
    SWIMLANE: 'Диаграмма Swim Lane',
    RISKS: 'Риски и контрольные процедуры',
    KPI: 'Ключевые показатели эффективности',
    DOCS: 'Документы и материалы',
    SYSTEMS: 'Информация об автоматизации',
    DESCRIPTION: 'Дополнительные сведения',
}

export default [
    { code: 'PASSPORT', item: 'HEADER', name: sectionLabels.PASSPORT },
    { code: 'MAIN', item: 'HEADER', name: sectionLabels.MAIN },
    { code: 'SIPOC', item: 'HEADER', name: sectionLabels.SIPOC },
    { code: 'RESPONSIBILITY_MATRIX', item: 'HEADER', name: sectionLabels.RESPONSIBILITY_MATRIX },
    { code: 'FUNCTION_MATRIX', item: 'HEADER', name: sectionLabels.FUNCTION_MATRIX },
    { code: 'SWIMLANE', item: 'UNIT_HEADER', name: sectionLabels.SWIMLANE },
    { code: 'RISKS', item: 'HEADER', name: sectionLabels.RISKS },
    { code: 'KPI', item: 'HEADER', name: sectionLabels.KPI },
    { code: 'DOCS', item: 'HEADER', name: sectionLabels.DOCS },
    { code: 'SYSTEMS', item: 'HEADER', name: sectionLabels.SYSTEMS },
    { code: 'DESCRIPTION', item: 'HEADER', name: sectionLabels.DESCRIPTION },
]
