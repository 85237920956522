import Repo from '@/api/service/repo'

export default class extends Repo {
    get () {
        return this.client.get('admin/field')
    }
    post (data) {
        return this.client.post('admin/field', data)
    }
}
