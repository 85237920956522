import utils from '@/utils'

export const ZemFromApi = (request) => ({
    ...request,
    author: {
        ...request.author,
        shortName: utils.shortName(request.author.name),
    },
    experts: request.experts.map((e) => ({ ...e, shortName: utils.shortName(e.name) })),
    replies: formatReplies(request)
        .sort(compareRepliesByCreatedTime),
})

const compareRepliesByCreatedTime = (r1, r2) => {
    if (r1?.reply?.createTime && r2?.reply?.createTime) {
        return Math.sign(new Date(r1.reply.createTime).getTime() - new Date(r2.reply.createTime).getTime())
    }

    return r2?.reply?.createTime ? 1 : 0
}

const formatReplies = (request) => {
    const { replies, experts, id: requestId } = request

    return experts.reduce((acc, expert) => {
        const repliesFromExpert = replies.filter(r => r.author.id === expert.id || r.substituted?.id === expert.id)

        if (repliesFromExpert.length) {
            acc = acc.concat(repliesFromExpert.map((reply) => ({
                expert: Object.assign(expert, {
                    shortName: utils.shortName(expert.name),
                }),
                reply: Object.assign(reply, { requestId }),
            })))
        } else {
             acc.push({
                expert: Object.assign(expert, {
                    shortName: utils.shortName(expert.name),
                }),
                reply: null,
            })
        }

        return acc
    }, [])
}

const compareZemDates = (z1, z2) => new Date(z1.createTime).getTime() - new Date(z2.createTime).getTime()

export default (requests) => requests
    .map(ZemFromApi)
    .sort(compareZemDates)
