const on401 = (response, router) => {
    if (response.headers && response.headers['x-redirect-to']) {
        window.location.reload()
    } else {
        router.replace({ name: 'Forbidden' })
    }
}

const on403 = (router) => {
    router.replace({ name: 'Forbidden' })
}

const clearErrors = state => {
    state.errors = []
    state.connectionLost = false
}

export const ERROR_STORE_NAME = 'error'

export const ERROR_STATE = {
    namespaced: true,
    state: {
        errors: [],
        connectionLost: false,
    },
    mutations: {
        pushError (state, { errorData, retryAction, acceptAction }) {
            state.errors.push({
                data: errorData,
                retry: retryAction,
                accept: acceptAction,
            })
            if (errorData.response) {
                switch (errorData.response.status) {
                    case 401: {
                        clearErrors(state)
                        on401(errorData.response, this.$router)
                        break
                    }
                    case 403: {
                        this.state.errorMessage = errorData.response.data.message
                        clearErrors(state)
                        on403(this.$router)
                        break
                    }
                    default: {
                        this.$modal.show('error_window')
                    }
                }
            } else {
                state.connectionLost = true
            }
        },
        acceptAll (state) {
            for (const error of state.errors) {
                error.accept()
            }
            clearErrors(state)
        },
        retryAll (state) {
            const retryQueue = state.errors
            clearErrors(state)
            for (const error of retryQueue) {
                error.retry()
            }
        },
    },
}
