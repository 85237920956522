import { set } from 'vuex-intern'

const initialState = () => ({
    data: {},
    page: 0,
})

export const KB_SEARCH_STORE_NAME = 'kb_search'

export const KB_SEARCH_STATE = {
    namespaced: true,
    state: initialState(),
    mutations: {
        setData: set('data'),
        resetState (state) {
            Object.assign(state, initialState())
        },
        incrementPage (state) {
            state.page += 1
        },
        pageToZero (state) {
            state.page = 0
        },
        pushToData (state, data) {
            state.page = data.page || 0
            data.list.map(e => state.data.list.push(e))
        },
    },
    actions: {
        search ({ commit }, searchString) {
            commit('pageToZero')

            this.$repo('kb/search').search(0, searchString).then((r) => {
                commit('setData', r.data)
            })
        },
        searchAndPush ({ commit, state }, searchString) {
            const p = state.page || 0

            this.$repo('kb/search').search(p, searchString).then((r) => {
                commit('pushToData', r.data)
                commit('incrementPage')
            })
        },
    },
}
