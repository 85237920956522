<template>
    <label
        class="ui-radio"
        :class="{
            'ui-radio_disabled': disabled,
            'ui-radio_rtl': isRtl,
            'ui-radio_inactive': inactive
        }"
    >
        <input
            class="ui-radio__input visually-hidden"
            type="radio"
            :checked="isChecked"
            :name="inputName"
            :disabled="disabled || allDisabled"
            v-bind="$attrs"
            @change="change"
        >

        <span class="ui-radio__box"></span>

        <div v-if="$slots.default" class="ui-radio__text">
            <slot />
        </div>
    </label>
</template>

<script>
export default {
    name: 'UiRadio',
    model: {
        event: 'change',
    },
    props: {
        isRtl: Boolean,
        name: String,
        value: {
            default: null,
        },
        disabled: Boolean,
        inactive: Boolean,
        checked: {
            type: Boolean,
            default: null,
        },
    },
    inject: {
        radioGroup: {
            default: null,
        },
    },
    computed: {
        inputName () {
            return this.radioGroup?.name || this.name
        },
        allDisabled () {
            return this.radioGroup?.disabled
        },
        trackBy () {
            return this.radioGroup?.trackBy
        },
        isChecked () {
            if (this.checked !== null) {
                return this.checked
            }

            if (this.radioGroup) {
                if (typeof this.radioGroup.value === 'object') {
                    return this.trackBy
                        ? this.radioGroup.value?.[this.trackBy] === this.value?.[this.trackBy]
                        : JSON.stringify(this.radioGroup.value) === JSON.stringify(this.value)
                }

                return this.radioGroup.value === this.value
            }

            return this.value
        },
    },
    methods: {
        change (event) {
            if (this.radioGroup) {
                this.radioGroup.change(this.value)
            }

            this.$emit('change', event.target.checked)
        },
    },
}
</script>

<style lang="sass" scoped>
.ui-radio
    display: inline-flex
    text-align: left
    cursor: pointer

.ui-radio__text
    font-weight: 400
    color: var(--dark-gray-color)

.ui-radio__box
    --radio-size: 16px
    --border-size: 1px
    --border-color: var(--dark-gray-60-color)
    position: relative
    margin-right: 16px
    flex-shrink: 0
    width: var(--radio-size)
    height: var(--radio-size)
    cursor: inherit
    display: inline-flex
    border-radius: 50%
    border: var(--border-size) solid var(--border-color)
    box-sizing: border-box
    transition: border .15s ease-in-out

    &:after
        box-sizing: border-box
        --offset: 14px
        content: ''
        width: calc(var(--radio-size) + var(--offset))
        height: calc(var(--radio-size) + var(--offset))
        position: absolute
        top: calc(var(--offset) / -2 - var(--border-size))
        left: calc(var(--offset) / -2 - var(--border-size))
        border-radius: 50%

.ui-radio__input:focus ~ .ui-radio__box
    --border-color: var(--blue-color)

.ui-radio__input:checked ~ .ui-radio__box
    --border-size: 4px
    --border-color: var(--blue-color)

.ui-radio_disabled
    cursor: not-allowed !important
    user-select: none

    .ui-radio__box
        --border-color: var(--dark-gray-60-color) !important

.ui-radio_inactive
    .ui-radio__box
        --border-color: var(--dark-gray-60-color) !important

    .ui-radio__text
        color: var(--gray-71-color)


.ui-radio_rtl
    flex-direction: row-reverse

    .ui-radio__box
        margin-right: 0
        margin-left: 16px
</style>
