import Repo from '@/api/service/repo'

export default class extends Repo {
    get (p) {
        return this.client.get(`admin/user-bps?p=${p}`)
    }

    save (mass) {
        return this.client.post(`admin/user-bps?tz=${this.tz}`, mass)
    }

    search (p, searchStringGlobal) {
        return this.client.get(`admin/user-bps?p=${p}&s=${ encodeURIComponent(searchStringGlobal) }`)
    }
}
