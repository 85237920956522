import { RolesDispatcher } from '@/views/admin_change_management/services/roles_dispatcher'

RolesDispatcher.prototype.saveChanges = function () {
    this.commit('setChangeManagementRolesChange', this.changes)
}

const initialState = () => ({
    changeManagementRoles: {
        roles: {
            autoComputedRoles: [],
            editableRoles: [],
        },
        change: {
            add: [],
            del: [],
            mod: [],
        },
    },
})

const state = initialState()

const mutations = {
    setChangeManagementRoles (state, data) {
        state.changeManagementRoles.roles = data
    },
    setChangeManagementRolesChange (state, data) {
        state.changeManagementRoles.change = data
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const getters = {
    addedRoles (state) {
        return state.changeManagementRoles?.change.add || []
    },
    autoComputedRoles (state) {
        const changes = state.changeManagementRoles?.change
        const list = state.changeManagementRoles?.roles.autoComputedRoles

        return new RolesDispatcher({ changes }).getRolesList(list)
    },
    editableRoles (state) {
        const changes = state.changeManagementRoles?.change
        const list = state.changeManagementRoles?.roles.editableRoles

        return new RolesDispatcher({ changes }).getRolesList(list, true)
    },
    allRoles (state) {
        const changes = state.changeManagementRoles?.change
        const { autoComputedRoles, editableRoles } = state.changeManagementRoles?.roles

        return new RolesDispatcher({ changes }).getRolesList([...autoComputedRoles, ...editableRoles], true)
    },
}

const actions = {
    getChangeManagementRoles ({ commit }, hasAutoComputedRoles) {
        return this.$repo('admin/approval').roles(true, hasAutoComputedRoles).then((r) => {
            commit('setChangeManagementRoles', r.data)
        })
    },
    getChangeManagementRolesSelector (_, s) {
        return this.$repo('admin/role').asuRoles(s).then((r) => r.data)
    },
    clear ({ commit, state }) {
        const changes = state.changeManagementRoles?.change
        changes?.del && (changes.del = changes.del.filter(i => i.id && i.id > 0))
        commit('setChangeManagementRolesChange', changes)
    },
    change ({ commit, state }, { mode, executor, role }) {

        if (!role || !['add', 'del', 'mod'].includes(mode)) return

        const changes = state.changeManagementRoles.change
        const dispatcher = new RolesDispatcher({ commit, changes, executor, role })

        if (!executor && mode === 'del') {
            state.changeManagementRoles.change.del.push({ id: role.id })
            dispatcher.delRole('add')
            dispatcher.delRole('mod')
            dispatcher.saveChanges()
            return
        }

        if (role.id && !dispatcher.getRole('add')) {
            dispatcher.getRole('mod')
                ? dispatcher.modRole('mod')
                : dispatcher.addRole('mod')
        } else {
            dispatcher.getRole('add')
                ? dispatcher.modRole('add')
                : dispatcher.addRole('add')
        }

        if (!executor) {
            dispatcher.saveChanges()
            return
        }

        const source = dispatcher.getRole('add') || dispatcher.getRole('mod')

        switch (mode) {
            case 'add':
                if (source) {
                    dispatcher.getExecutor(source, 'add') || dispatcher.pushExecutor(source, 'add')
                    dispatcher.delExecutor(source, 'del')
                }
                break
            case 'del':
                if (source) {
                    dispatcher.getExecutor(source, 'del') || dispatcher.pushExecutor(source, 'del')
                    dispatcher.delExecutor(source, 'add')
                }
        }

        dispatcher.saveChanges()
    },
    save ({ state }) {
        return this.$repo('admin/approval').rolesSave(state.changeManagementRoles.change)
    },
}

export const STORE_NAME = 'changeManagementRoles'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
