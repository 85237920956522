import passportSections from '../passportSections'

export const buildListSections = (requests) => {
    const sections = requests.reduce((groups, request) => {
        groups[request.section] ||= {
            id: request.section,
            label: passportSections.find((s) => s.code === request.section).name,
            type: 'section',
            rootRequestId: null,
            requests: [],
            newReplies: [],
        }

        request.replies
            .filter((item) => item.reply?.section && !groups[item.reply.section])
            .forEach((item) => {
                groups[item.reply.section] ||= {
                    id: item.reply.section,
                    label: passportSections.find((s) => s.code === item.reply.section).name,
                    type: 'section',
                    rootRequestId: null,
                    requests: [],
                    newReplies: [],
                }
            })

        groups[request.section].requests.push({ ...request, replies: [] })

        request.replies.forEach((item) => {
            item.reply && groups[item.reply.section].requests.push({
                ...item.reply,
                plannedDueDate: request.plannedDueDate,
            })
        })

        return groups
    }, {})

    return [{
        requestSourceId: 'section',
        requestId: null,
        list: Object.values(sections),
        parentItems: [],
    }]
}
