<template>
    <modal
        class="modal"
        name="browser_version"
        height="auto"
        width="35%"
    >
        <div class="modal__content">
            <h2 class="title">
                Неподдерживаемая версия браузера
            </h2>
            <p class="modal__text">
                Установленная версия браузера системой не поддерживается и возможно <br>
                некорректное отображение информации.
            </p>
            <span class="modal__text">
                Поддерживаемые версии:
            </span>
            <ul class="modal__list reset-list">
                <li class="modal__text modal__text_align_left">
                    Microsoft Edge версии 92 и выше.
                </li>
                <li class="modal__text modal__text_align_left">
                    Google Chrome версии 84 и выше.
                </li>
            </ul>
            <button
                class="button button_size_long"
                type="button"
                @click="$modal.hide('browser_version')"
            >
                Закрыть
            </button>
        </div>
    </modal>
</template>

<script>
import Bowser from 'bowser'

const INTERVAL_CHECK_BROWSER_VERSION = 300

export default {
    mounted () {
      const int = setInterval(() => {
        if(this.$route && this.$route.name){
          this.checkBrowsVers()
          clearInterval(int)
        }
      }, INTERVAL_CHECK_BROWSER_VERSION)
    },
    methods: {
        checkBrowsVers () {
            const browser = Bowser.getParser(window.navigator.userAgent)
            const isValidBrowser = browser.satisfies({
                chrome: '>=84',
                edge: '>=92',
            })
            if (!isValidBrowser && !this.$route.meta.noCheckBrowser) this.$modal.show('browser_version')
        },
    },
}
</script>

<style lang="sass" scoped>
  .modal__content
    display: flex
    flex-direction: column
    align-items: center
    flex-wrap: wrap
    row-gap: 15px
    padding: 39px 52px

  .modal__text
    margin-top: 0
    margin-bottom: 0
    font-weight: 700
    text-align: center
    color: var(--dark-gray-color)

  .modal__text_align_left
    text-align: left

  .modal__list
    width: 100%
    padding-left: 40px
    margin-bottom: 10px
    list-style-type: disc
    box-sizing: border-box
</style>
