import { set, setPath } from 'vuex-intern'

export const ACCOUNT_STORE_NAME = 'account'

export const ACCOUNT_STATE = {
    namespaced: true,
    state: {
        user: {},
        deputyList: [],
        daysOff: [],
        bpInfo: {},
        settings: {},
        isUserLoaded: false,
        isAccountLoaded: false,
        isLoading: false,
        hints: null,
    },
    mutations: {
        setUser: set('user'),
        setBpInfo: set('bpInfo'),
        setSettings: set('settings'),
        loadedUser: state => state.isUserLoaded = true,
        loadedAccount (state) {
            state.isUserLoaded = true
            state.isAccountLoaded = true
        },
        setNotify: setPath(['settings', 'emailNotification']),
        setHints: set('hints'),
        setDeputyList: set('deputyList'),
        setDaysOff: set('daysOff'),
    },
    actions: {
        fetchAccount ({ state, commit }) {
            if (state.isLoading) {
                return this.$utils.waitFor(() => state.isLoading === false)
            }
            state.isLoading = true
            return this.$repo('user/personal_account').get().then((r) => {
                commit('setUser', r.data.user)
                commit('setBpInfo', r.data.bpInfo)
                commit('setSettings', r.data.settings)
                commit('loadedAccount')
            }).finally(() => state.isLoading = false)
        },
        changeNotification ({ state, commit }, isNotify) {
            if (state.settings.emailNotification === isNotify) {
                return
            }
            commit('setNotify', isNotify)
            this.$repo('user/settings').post(state.settings)
        },
        getDeputy ({ commit }) {
            return this.$repo('admin/deputy').getDeputy().then((r) => {
                commit('setDeputyList', r.data.list)
            })
        },
        getDaysOff ({ commit }){
            this.$repo('admin/calendar').getDayOff().then((r) => {
                commit('setDaysOff', r.data)
            })
        },
    },
    getters: {
        userId: (state) => state.user?.id || null,
        isUserLoaded: (state) => state.isUserLoaded,
        isAccountLoaded: (state) => state.isAccountLoaded,
    },
}
