import Repo from '@/api/service/repo'

export default class extends Repo {
    get (type, p) {
        return this.client.get(`dictionary?type=BP_RESPONSIBILITY_${type}&p=${p}`)
    }
    search (type, p, s) {
        return this.client.get(`dictionary?type=BP_RESPONSIBILITY_${type}&p=${p}&s=${s}`)
    }
    save (type, data) {
        return this.client.post(`dictionary?type=BP_RESPONSIBILITY_${type}`, data)
    }
}
