import { set } from 'vuex-intern'

const initialState = () => ({
    userGroups: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setUserGroups: set('userGroups'),
    setBpsToUser (state, { userId, bps }) {
        state.userGroups.list = state.userGroups.list.map((user) => (user.id === userId
            ? { ...user, bps }
            : user
        ))
    },
    pushToUserGroups (state, data) {
        state.userGroups.page = data.page
        data.list.map(e => state.userGroups.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('admin/user_groups').get(0).then((r) => {
            commit('setUserGroups', r.data)
        })
    },
    fetchAndPush ({ commit, getters }) {
        return this.$repo('admin/user_groups').get(getters.page + 1).then((r) => {
            commit('pushToUserGroups', r.data)
        })
    },
    search ({ commit }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        return new Promise(resolve => {
            this.$repo('admin/user_groups').search(0, searchString).then((r) => {
                commit('setUserGroups', r.data)
                resolve(false)
            })
        })
    },
    searchAndPush ({ commit, getters }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        this.$repo('admin/user_groups').search(getters.page + 1, searchString).then((r) => {
            commit('pushToUserGroups', r.data)
        })
    },
    save (_, data) {
        return this.$repo('admin/user_groups').save(data)
    },
}

const getters = {
    page: state => state.userGroups.page,
}

export const STORE_NAME = 'userGroups'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
