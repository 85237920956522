import { ACCOUNT_STATE, ACCOUNT_STORE_NAME } from '@/store/modules/account'
import store from '@/store'

export class User {
    constructor () {}

    set (key, value) {
        return store.commit(`${ACCOUNT_STORE_NAME}/${key}`, value)
    }

    get state () {
        return ACCOUNT_STATE.state
    }

    get id () {
        return ACCOUNT_STATE.state.user.id || null
    }

    get userName () {
        return ACCOUNT_STATE.state.user.name || ''
    }

    get isUser () {
        return this._hasRole(r => /пользователь/i.test(r.name))
    }

    get isSimpleUser () {
        return this.isUser && this.state.user.roles.length === 1
    }

    get isExpert () {
        return this._hasRole(r => /эксперт/i.test(r.name))
    }

    get isBusinessAdmin () {
        return this._hasRole(r => /бизнес администратор/i.test(r.name))
    }

    get isMethodologist () {
        return this._hasRole(r => /методолог/i.test(r.name))
    }

    get isTechAdmin () {
        return this._hasRole(r => /технический администратор/i.test(r.name))
    }

    get roles () {
        return this.state.user.roles?.length ? this.state.user.roles.slice() : []
    }

    get isLoaded () {
        return Boolean(this.state.user.id && this.state.isUserLoaded)
    }

    _hasRole (pattern) {
        return Boolean(this.roles.find(pattern))
    }

}
