<template>
    <div>
        <div v-show="!connectionLost">
            <slot></slot>
        </div>

        <ConnectionLost v-show="connectionLost" @retry="retryAll" />

        <ErrorModal
            :style="{ 'z-index': 1000 }"
            :errors="errors"
            @continue="acceptAll"
            @retry="isRetry = true"
            @closed="errorClosed"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { ERROR_STORE_NAME } from '@/store/modules/error'
import ConnectionLost from '../error_pages/ConnectionLost'
import ErrorModal from '../error_pages/ErrorModal'

const { mapState, mapMutations } = createNamespacedHelpers(ERROR_STORE_NAME)

export default {
    data () {
        return {
            isRetry: false,
        }
    },
    components: { ConnectionLost, ErrorModal },
    computed: {
        ...mapState({
            errors: state => state.errors,
            connectionLost: state => state.connectionLost,
        }),
    },
    methods: {
        ...mapMutations(['clearForbidden', 'acceptAll', 'retryAll']),
        errorClosed () {
            if (this.isRetry) {
                this.isRetry = false
                this.retryAll()
            }
        },
    },
}
</script>
