import Repo from '@/api/service/repo'

export default class extends Repo {
    get () {
      console.log('getCalendarFiles')
        // return this.client.get(`calendar/nmd-template-group?tz=${tz}`)
    }

    getNonRegularDates (params = { from: '1970-01-01', to: '2100-12-31' }) {
        const { year, month, from, to } = params
        let query = this.makeQuery({ f: from, t: to }, { skipNull: true })

        if (month || year) {
            query = this.makeQuery({ m: month, y: year }, { skipNull: true })
        }

        return this.client.get(`/calendar/nonregular?${query}&tz=${this.tz}`)
    }

    getDayOff (params = { from: '1970-01-01', to: '2100-12-31' }) {
        const { year, month, from, to } = params
        let query = this.makeQuery({ f: from, t: to }, { skipNull: true })

        if (month || year) {
            query = this.makeQuery({ m: month, y: year }, { skipNull: true })
        }

        return this.client.get(`/calendar/dayoff?${query}&tz=${this.tz}`)
    }

    save (data) {
        return this.client.stream().post(`/calendar?tz=${this.tz}`, data)
    }
}
