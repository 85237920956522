import { set } from 'vuex-intern'

const initialState = () => ({
    roles: {},
})

const state = initialState()

const mutations = {
    setRoles: set('roles'),
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return new Promise((resolve) => {
            this.$repo('admin/role').get().then((r) => {
                commit('setRoles', r.data)
                resolve(r.data)
            })
        })
    },
    save (_, matrixRoles) {
        return new Promise((resolve) => {
            this.$repo('admin/role').save(JSON.stringify(matrixRoles)).then(() => {
                resolve(true)
            })
        })
    },
}

const getters = {

}

export const STORE_NAME = 'roles'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
