import Repo from '@/api/service/repo'

export default class extends Repo {
    get () {
        return this.client.get('admin/access')
    }

    getActions () {
        return this.client.get('report/bpChangeAudit/actions')
    }
}
