import Vue from 'vue'
import Vuex from 'vuex'

import router from './router'
import utils from './utils'
import filters from './filters'
// import getRepo from './api/repo_factory'
import RequestService from './api/service/request'

const repo = function (repo) {
    return this.$rs.repo(repo)
}

// --- VUE ---
Vue.prototype.$filters = filters
Vue.prototype.$utils = utils
// Vue.prototype.$getRepo = getRepo

Vue.prototype.$rs = RequestService
Vue.prototype.$repo = repo

Vue.prototype.$confirm = function (name, data) {
    this.$modal.show(typeof name === 'string' ? name : 'notifier', data || name)
}

Vue.prototype.$resetData = function () {
    // ! именно function (для использования this компонента)
    Object.assign(this.$data, this.$options.data())
}

// --- VUEX ---
Vuex.Store.prototype.$utils = utils
Vuex.Store.prototype.$router = router
Vuex.Store.prototype.$modal = Vue.prototype.$modal
// Vuex.Store.prototype.$getRepo = getRepo
Vuex.Store.prototype.$rs = RequestService
Vuex.Store.prototype.$repo = repo
