<template>
    <div />
</template>
<script>
import { User } from '@/entities/user'

const INTERVAL_AUTH_CHECKER = 1000

export default {
    name: 'UserAuthCheck',
    data: () => ({
        user: new User(),
    }),
    methods: {
        runUserAuthChecker () {
            this.$utils.promise(
                () => this.user.isLoaded,
                { interval: 300 }
            )
            .finally(() => {
                this.user.state.user.email || setTimeout(this.showConfirmModal, INTERVAL_AUTH_CHECKER)
            })
        },
        showConfirmModal () {
            this.$confirm({
                type: 'danger',
                title: 'Внимание!',
                text: 'В системе АСУ КПМ отсутствует ваш email. Обратитесь к техническому администратору',
                buttons: 'Продолжить',
                bind: { width: '700px' },
            })
        },
    },
}
</script>
