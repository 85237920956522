
export default class ApiCustomHandler {

    static _stack = [{
        repo: null,
        method: null,
        onCatch: null,
    }]

    static push ({ repo, method, onCatch }) {
        const handler = ApiCustomHandler.get(repo, method)
        if (handler) {
            Object.assign(handler, { repo, method, onCatch })
            return
        }
        ApiCustomHandler._stack.push({ repo, method, onCatch })
    }

    static get (repo, method) {
        return ApiCustomHandler._stack.find(e => e.repo === repo && e.method === method)
    }
}
