import Repo from '@/api/service/repo'

export default class extends Repo {
    get (lvl, publicated) {
        return this.client.get(`bp/tree?at=true&lvl=${lvl}` + (publicated ? '&p=true' : ''))
    }
    /*
    * @param array es - начальные бп
    * @param array ee - конечные бп
    * @param boolean p - Фильтр по статусу публикации БП
    *   Если указан вместе с фильтром lvl - то будут возвращены:
    *   БП указанного уровня имеющие указанный статус публикации
    *   Родительские БП тех БП которые подходят по фильтру публикации (даже если сами родительские БП не проходят по фильтру публикации)
    *   Родительские БП, которые не имеют ни одного дочернего БП подходящего по фильтру публикации возвращены не будут
    * */
    crossChainFilterMid ({ si, so, es, ee, p, lvl }) {
        return this.client.get(`bp/tree?${this.makeQuery({ si, so, es, ee, p, lvl })}`)
    }
}
