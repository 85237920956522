<template>
    <div
        class="app"
        :class="{ 'is-hidden' : isHidden }"
        @mousemove="userEventHandler"
        @mousedown="userEventHandler"
        @keydown="userEventHandler"
        @scroll="userEventHandler"
    >
        <ErrorHandler>
            <router-view :key="$route.fullPath" />
        </ErrorHandler>

        <MsgPageInDevModal />
        <BrowserVersions />
        <UserAuthCheck ref="userAuthCheck" />
        <TheDisclaimer ref="disclaimer" />
    </div>
</template>

<script>
import ErrorHandler from './views/shared/ErrorHandler'
import MsgPageInDevModal from './views/shared/MsgPageInDevModal'
import BrowserVersions from './views/shared/BrowserVersions'
import UserAuthCheck from './views/shared/UserAuthCheck'
import TheDisclaimer from '@/views/shared/TheDisclaimer'

const PING_DELAY = 15 * 60 * 1000 // 15min

export default {
    components: {
        UserAuthCheck,
        ErrorHandler,
        MsgPageInDevModal,
        BrowserVersions,
        TheDisclaimer,
    },
    data () {
        return {
            pingHandler: null,
            isHidden: true,
        }
    },
    mounted () {
        this.$refs.disclaimer.runDisclaimerChecker().then(() => {
            this.$refs.userAuthCheck.runUserAuthChecker()
        })

        setTimeout(() => {
            this.isHidden = false
        }, 200)
    },
    methods: {
        userEventHandler () {
            clearTimeout(this.pingHandler)
            this.pingHandler = setTimeout(() => {
                this.$rs.bg().repo('ping').get()
            }, PING_DELAY)
        },
    },
}
</script>

<style lang="sass">
@import "@/assets/sass/base/variables"
@import "@/assets/sass/base/fonts"
@import "@/assets/sass/base/base"
@import "@/assets/sass/base/a11y"
@import "@/assets/sass/base/reset-list"

@import "@/assets/sass/components/buttons"
@import "@/assets/sass/components/tippy-themes"
@import "@/assets/sass/components/title"
@import "@/assets/sass/components/main-search"
@import "@/assets/sass/components/accordion"
@import "@/assets/sass/components/main-header"
@import "@/assets/sass/components/fixed-table"
@import "@/assets/sass/components/fixed-header"
@import "@/assets/sass/components/bubble"
@import "@/assets/sass/components/content-from-ql-editor"

@import "@/assets/sass"
@import "@/assets/sass/style_to_del"
@import "@/assets/sass/typography"

.app
    opacity: 0
    transition: opacity .2s ease-in-out

    &:not(.is-hidden)
        opacity: 1
</style>
