import Repo from '@/api/service/repo'

export default class extends Repo {
    post (data) {
        return this.client.post(`hint?tz=${this.tz}`, data)
    }

    getHintEdit () {
        return this.client.get(`hint/edit?tz=${this.tz}`)
    }

    upload (usage, filename, file) {
        return this.client.config({ timeout: 60000 }).stream().post(
            `storage?usage=${usage}&fileName=${filename}`,
            file
        )
    }

    saveHintEdit (data) {
        return this.client.post(`hint/edit?tz=${this.tz}`, data)
    }

    getHintView () {
        return this.client.get(`hint/view?tz=${this.tz}`)
    }

    saveHintView (data) {
        return this.client.post(`hint/view?tz=${this.tz}`, data)
    }
}
