import Repo from '@/api/service/repo'

export default class extends Repo {
    get (page = 0, isFavorite = null) {
        const query = this.makeQuery({
            p: page,
            fav: isFavorite,
        }, { arrayFormat: 'none', skipNull: true, encode: false })

        return this.client.get(`subdivision?${query}`)
    }

    search (page, search, isFavorite = null) {
        const query = this.makeQuery({
            p: page,
            s: search,
            fav: isFavorite,
        }, { arrayFormat: 'none', skipNull: true, encode: false })

        return this.client.get(`subdivision?${query}`)
    }
}
