import { set } from 'vuex-intern'

const initialState = () => ({
    organization: {
        page: 0,
    },
})

const state = initialState()

const mutations = {
    setOrganization: set('organization'),
    pushToOrganization (state, data) {
        state.organization.page = data.page
        data.list.map(e => state.organization.list.push(e))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('references/organization').get(0, 30).then((r) => {
            commit('setOrganization', r.data)
        })
    },
    search ({ commit }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        this.$repo('references/organization').search(0, searchString, 30).then((r) => {
            commit('setOrganization', r.data)
        })
    },
    fetchAndPush ({ commit, getters, rootGetters }) {
        const bgLoader = rootGetters['admin/bgLoader']

        return this.$rs.useLoader(bgLoader).repo('references/organization').get(getters.page + 1, 30).then((r) => {
            commit('pushToOrganization', r.data)
        })
    },
    searchAndPush ({ commit, getters, rootGetters }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return this.$rs.useLoader(bgLoader).repo('references/organization').search(getters.page + 1, searchString, 30).then((r) => {

            commit('pushToOrganization', r.data)
        })
    },
}

const getters = {
    page: state => state.organization.page,
}

export const STORE_NAME = 'organization'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
