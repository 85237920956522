import dayjs from 'dayjs'

export const getDatesByCircles = (circles, requests) => {
    const filteredByCircle = requests.filter((request) => circles.includes(request.circleNum))
    const dates = filteredByCircle.map((request) => new Date(request.createTime).getTime())

    if (!dates.length) {
        return {
            from: dayjs().startOf('d').toDate(),
            to: dayjs().endOf('d').toDate(),
        }
    }

    return {
        from: dayjs(Math.min(...dates)).startOf('d').toDate(),
        to: dayjs(Math.max(...dates)).endOf('d').toDate(),
    }
}

export const getCircleByDates = (dates, requests) => {
    const from = new Date(dates.from).getTime()
    const to = new Date(dates.to).getTime()

    return [
        ...new Set(
            requests
                .filter((request) => {
                    const date = new Date(request.createTime).getTime()

                    return date >= from && date <= to
                })
                .map((request) => request.circleNum)),
    ].sort((a, b) => (b - a))
}

export const findRequestChains = (list) => {
    const chains = []

    const buildChain = (request) => {
        const chain = []
        const stack = [request]

        while (stack.length) {
            const currentRequest = stack.shift()
            const children = list.filter((r) => r.parentRequestId === currentRequest.id)

            chain.push(currentRequest)
            stack.push(...children)
        }

        chains.push({ authorId: request.author.id, chain })
    }

    list.forEach((request) => {
        buildChain(request)
    })

    return chains
}

export const findRequestChainsByRequests = (requests, rootRequests, type = 'full') => {
    const chains = {}

    const buildChain = (request) => {
        const childChain = []
        const parentChain = []

        let stack = []

        if (type === 'full' || type === 'delegate') {
            stack = [request]

            while (stack.length) {
                const currentRequest = stack.shift()
                const children = requests.filter((r) => r.parentRequestId === currentRequest.id)

                childChain.push(currentRequest)
                stack.push(...children)
            }
        }

        if (type === 'full' || type === 'parent') {
            stack = [request]

            while (stack.length) {
                const currentRequest = stack.shift()
                const parents = requests.filter((r) => r.id === currentRequest.parentRequestId)

                parentChain.unshift(currentRequest)
                stack.push(...parents)
            }

            if (childChain.length) {
                childChain.shift()
            }
        }

        chains[request.id] ||= parentChain.concat(childChain)
    }

    rootRequests.forEach(buildChain)

    return chains
}

export const getMyRequests = (list = []) => list
    .filter((req) => req.cascadeZemCategory === 'ROOT_AUTHORED' && !req.parentRequestId)

export const getParentRequests = (list = []) => list
    .filter((req) => req.cascadeZemCategory === 'PARENT')

