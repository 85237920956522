import Repo from '@/api/service/repo'

export default class extends Repo {
    get () {
        return this.client.get(`admin/nmd-template-group?tz=${this.tz}`)
    }

    check (id) {
        return this.client.config({ timeout: 60000 }).get(`/bp/nmd-project?id=${id}&tz=${this.tz}`)
    }

    save (groupId, data) {
        return this.client.post(`admin/nmd-template-group/${groupId}/template?tz=${this.tz}`, data)
    }
}
