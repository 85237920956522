const initialState = () => ({
    groupsBp: {
        list: [],
        page: 0,
    },
})

const state = initialState()

/*
    TODO @AlekseyShukilovich:
         Как только бек исправит отправку данных как в документации - поле order,
         можно будет убрать данную операцию - order: i + 1
*/
const mutations = {
    setGroupsBp (state, data) {
        state.groupsBp = data
        state.groupsBp.list = state.groupsBp.list.map((item, i) => ({ ...item, order: i + 1 }))
    },
    pushToGroupsBp (state, data) {
        state.groupsBp.page = data.page
        data.list.map((item) => state.groupsBp.list.push({ ...item, order: state.groupsBp.list.length + 1 }))
    },
    reset (state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
}

const actions = {
    fetch ({ commit }) {
        return this.$repo('references/dictionary').getGroups(0, 30).then((r) => {
            commit('setGroupsBp', r.data)
        })
    },
    search ({ commit }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        this.$repo('references/dictionary').searchGroups(0, searchString, 30).then((r) => {
            commit('setGroupsBp', r.data)
        })
    },
    fetchAndPush ({ commit, getters, rootGetters }) {
        const bgLoader = rootGetters['admin/bgLoader']

        return this.$rs.useLoader(bgLoader).repo('references/dictionary').getGroups(getters.page + 1, 30).then((r) => {
            commit('pushToGroupsBp', r.data)
        })
    },
    searchAndPush ({ commit, getters, rootGetters }, searchString) {
        commit('admin/setSearchStringGlobal', searchString, { root: true })

        const bgLoader = rootGetters['admin/bgLoader']

        return this.$rs.useLoader(bgLoader).repo('references/dictionary').searchGroups(getters.page + 1, searchString, 30).then((r) => {
            commit('pushToGroupsBp', r.data)
        })
    },
    save (_, mass) {
        return this.$repo('references/dictionary').saveGroups(mass)
    },
}

const getters = {
    page: state => state.groupsBp.page,
}

export const STORE_NAME = 'groupsBp'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
